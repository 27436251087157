import { createStore } from 'vuex';
import axios from 'axios';

axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

axios.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const refreshToken = localStorage.getItem('refreshToken');
            if(refreshToken) {
                const newAccessToken = await refreshAccessToken(refreshToken);
                localStorage.setItem('accessToken', newAccessToken);
                axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
                return axios(originalRequest);
            }
        }
        return Promise.reject(error);
    }
);

async function refreshAccessToken(refreshToken) {
    const response = await axios.post('/api/members/v1/refresh-token', refreshToken);
    const { jwtToken } = response.data.data;
    await localStorage.setItem('accessToken', jwtToken.accessToken);
    await localStorage.setItem('refreshToken', jwtToken.refreshToken);
    return jwtToken.accessToken;
}

const getExpiration = (accessToken) => {
    const expiration = getClaimValue(accessToken, 'exp')
    if (!expiration) {
        console.log('exp를 찾을 수 없습니다.');
        return 0;
    }
    return expiration || 0;
}

const getClaimValue = (accessToken, key) => {
    const base64Url = accessToken.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const payload = JSON.parse(atob(base64));

    return payload[key];
}
export default createStore({
    state: {
        accessToken: localStorage.getItem('accessToken') || null,
        refreshToken: localStorage.getItem('refreshToken') || null,
        darkMode: false,
        newUser: false,
        userInfo: {},
        statusCheck: {},
        featured: [],
        recent: [],
        like: [],
        bookmark: [],
        myImages: [],
        isLoading: false,
        userName: 'GUEST',
        userId: 'unknown',
        role: 'GUEST',
        detailImage: null,
        canvases: 0,
        freeCanvases: 0,
        paidCanvases: 0,
        tabletUseCnt: 0,
        tabletEnd: 0,
        profileImage: '',
        thumbnailImage: '',
        slideShows: [],
        showMyInfo: false,
        showSupportInfo: false,
        isStyleSelected: false,
        inStore: false,
        inCreator: false,
        reload: 0,
        searchKeyword: '',
        currentTab: 0,
        selectedOption: 0,
        fromCreator: false,
        anyInProgress: false,
        isLeftPaneVisible: false,
        prevScroll: 0,
        showCreator: false,
        focusedImage: null,
        applyPrompt: null,
        toastMessage: null
    },
    getters: {
        isAuthenticated(state) {
            if(!state.refreshToken) return false;

            const now = Math.floor(Date.now() / 1000);
            const exp = getExpiration(state.refreshToken);
            return exp > now;
        },
        darkMode(state) {
            return state.darkMode;
        },
        newUser(state) {
            return state.newUser;
        },
        userInfo(state) {
            return state.userInfo;
        },
        userId(state) {
            return state.userId;
        },
        userName(state) {
            return state.userName;
        },
        role(state) {
            return state.role;
        },
        featured(state) {
            return state.featured;
        },
        recent(state) {
            return state.recent;
        },
        like(state) {
            return state.like;
        },
        bookmark(state) {
            return state.bookmark;
        },
        myImages(state) {
            return state.myImages;
        },
        statusCheck(state) {
            return state.statusCheck;
        },
        detailImage(state) {
            return state.detailImage;
        },
        canvases(state) {
            return state.freeCanvases > 0 ? state.freeCanvases : state.paidCanvases;
        },
        freeCanvases(state) {
            return state.freeCanvases;
        },
        paidCanvases(state) {
            return state.paidCanvases;
        },
        tabletUseCnt(state) {
            return state.tabletUseCnt;
        },
        tabletEnd(state) {
            return state.tabletEnd;
        },
        profileImage(state) {
            return state.profileImage;
        },
        thumbnailImage(state) {
            return state.thumbnailImage;
        },
        slideShows(state) {
            return state.slideShows;
        },
        showMyInfo(state) {
            return state.showMyInfo;
        },
        showSupportInfo(state) {
            return state.showSupportInfo;
        },
        isStyleSelected(state) {
            return state.isStyleSelected;
        },
        inStore(state) {
            return state.inStore;
        },
        inCreator(state) {
            return state.inCreator;
        },
        searchKeyword(state) {
            return state.searchKeyword;
        },
        reload(state) {
            return state.reload;
        },
        currentTab(state) {
            return state.currentTab;
        },
        fromCreator(state) {
            return state.fromCreator;
        },
        anyInProgress(state) {
            return state.anyInProgress;
        },
        isLeftPaneVisible(state) {
            return state.isLeftPaneVisible;
        },
        prevScroll(state) {
            return state.prevScroll;
        },
        showCreator(state) {
            return state.showCreator;
        },
        focusedImage(state) {
            return state.focusedImage;
        },
        applyPrompt(state) {
            return state.applyPrompt;
        },
        selectedOption(state) {
            return state.selectedOption;
        },
    },
    mutations: {
        SET_LOADING(state, status) {
            state.isLoading = status;
        },
        toggleDarkMode(state) {
            state.darkMode = !state.darkMode;
        },
        setToastMessage(state, message) {
            state.toastMessage = message;
        },
        clearToastMessage(state) {
            state.toastMessage = null;
        },
        setToken(state, {data}) {
            state.accessToken = data.jwtToken.accessToken;
            state.refreshToken = data.jwtToken.refreshToken;

            state.exp = getExpiration(state.accessToken);

            state.userId = data.userInfo.id;
            state.role = data.userInfo.role;
            state.freeCanvases = data.userInfo.freeCanvases*1;
            state.paidCanvases = data.userInfo.paidCanvases*1;
            state.tabletUseCnt = data.userInfo.tabletUseCnt*1;
            state.tabletEnd = data.userInfo.tabletEnd;
            state.userName = data.userInfo.nickname;
            state.profileImage = data.userInfo.profileImage;
            state.thumbnailImage = data.userInfo.thumbnailImage;
            localStorage.setItem('accessToken', state.accessToken);
            localStorage.setItem('refreshToken', state.refreshToken);
            state.newUser = false;
        },
        clearToken(state) {
            state.accessToken = null;
            state.refreshToken = null;
            state.exp = 0;
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
        },
        setUserInfo(state, {data}) {
            state.newUser = data.newUser;
            state.userInfo = data.userInfo;
            if(!data.newUser) {
                state.accessToken = data.jwtToken.accessToken;
                state.refreshToken = data.jwtToken.refreshToken;
                state.exp = getExpiration(data.jwtToken.accessToken);
                localStorage.setItem('accessToken', data.jwtToken.accessToken);
                localStorage.setItem('refreshToken', data.jwtToken.refreshToken);
                state.newUser = false;
            }
        },
        creation(state, {data}) {
            if(data.id === -1) {
                if (data.canvases === -1) {
                    // TODO
                    state.canvases = 0;
                    // state.tabletUseCnt += 1;
                    return "no-canvas";
                } else if (data.canvases === -99) {
                    return 'ban';
                }
            }
            else {
                state.canvases = data.canvases;
                return "ok";
            }
        },
        setFeatured(state, {reset, data}) {
            if(reset) {
                state.featured = data;
            } else {
                state.featured = [...(state.featured), ...data]
            }
        },
        afterLike(state, {id, unLike}) {
            state.featured.forEach((item)=>{
                if(item.id === id) {
                    item.liked = unLike ? false : true;
                    item.likeCount += (unLike ? -1 : 1);
                }
            });
            state.recent.forEach((item)=>{
                if(item.id === id) {
                    item.liked = unLike ? false : true;
                    item.likeCount += (unLike ? -1 : 1);
                }
            });
            state.like.forEach((item)=>{
                if(item.id === id) {
                    item.liked = unLike ? false : true;
                    item.likeCount += (unLike ? -1 : 1);
                }
            });
        },
        afterBookmark(state, {id, unBookmark}) {
            state.featured.forEach((item)=>{
                if(item.id === id) {
                    item.bookmarked = unBookmark ? false : true;
                    item.bookmarkCount += (unBookmark ? -1 : 1);
                }
            });
            state.recent.forEach((item)=>{
                if(item.id === id) {
                    item.bookmarked = unBookmark ? false : true;
                    item.bookmarkCount += (unBookmark ? -1 : 1);
                }
            });
            state.like.forEach((item)=>{
                if(item.id === id) {
                    item.bookmarked = unBookmark ? false : true;
                    item.bookmarkCount += (unBookmark ? -1 : 1);
                }
            });
        },
        setRecent(state, {reset, data}) {
            if(reset) {
                state.recent = data;
            } else {
                state.recent = [...(state.recent), ...data]
            }
        },
        setLike(state, {reset, data}) {
            if(reset) {
                state.like = data;
            } else {
                state.like = [...(state.like), ...data]
            }
        },
        setBookmark(state, {reset, data}) {
            if(reset) {
                state.bookmark = data;
            } else {
                state.bookmark = [...(state.bookmark), ...data]
            }
        },
        setMyImages(state, {reset, data}) {
            data.forEach(d=>{
                if(!d.url) d.url = 'processing.webp';
            });
            if(reset) {
                state.myImages = data;
            } else {
                state.myImages = [...(state.myImages), ...data]
            }
        },
        setStatusCheck(state, {data}) {
            state.statusCheck = data;
        },
        setDetailImage(state, {image}) {
            state.detailImage = image;
        },
        setCanvases(state, { result, amount }) {
            state.canvases += amount*1;
            if(result.length === 10) {
                state.tabletEnd = result;
                state.tabletUseCnt = 0;
            }
        },
        increaseCanvases(state) {
            state.canvases += 1;
        },
        setSlideShows(state, { data }) {
            if(data.length > 0) {
                state.slideShows = data;
            }
        },
        setShowMyInfo(state, { flag }) {
            state.showMyInfo = flag;
        },
        setNickName(state, { userName }) {
            state.userName = userName;
        },
        setShowSupportInfo(state, { flag }) {
            state.showSupportInfo = flag;
        },
        setIsStyleSelected(state, { flag }) {
            state.isStyleSelected = flag;
        },
        setInStore(state, { flag }) {
            state.inStore = flag;
        },
        setInCreator(state, { flag }) {
            state.inCreator = flag;
        },
        setSearchKeyword(state, { keyword }) {
            state.searchKeyword = keyword;
        },
        setProfileImage(state, { data }) {
            state.profileImage = data;
        },
        setReload(state, { cnt }) {
            state.reload = cnt;
        },
        increaseReload(state) {
            state.reload += 1;
        },
        setCurrentTab(state, { tab }) {
            state.currentTab = tab;
        },
        setFromCreator(state, { flag }) {
            state.fromCreator = flag;
        },
        setAnyInProgress(state, { flag }) {
            state.anyInProgress = flag;
        },
        setIsLeftPaneVisible(state, { flag }) {
            state.isLeftPaneVisible = flag;
        },
        setPrevScroll(state, { scroll }) {
            state.prevScroll = scroll;
        },
        setFocusedImage(state, { image }) {
            state.focusedImage = image;
        },
        setApplyPrompt(state, { image }) {
            state.applyPrompt = image;
        },
        toggleShowCreator(state) {
            state.showCreator = !state.showCreator;
        },
        setSelectedOption(state, { option }) {
            state.selectedOption = option;
        },
    },
    actions: {
        async fetchData({ commit }, payload) {
            try {
                // commit('SET_LOADING', true);
                const accessToken = localStorage.getItem('accessToken');
                const response = await axios.get(payload.url, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
                commit('SOME_MUTATION', response.data); // 예시, 실제로는 상태 업데이트를 위한 mutation 호출
            } catch (error) {
                console.error(error);
                // 여기서 토큰 만료 검사 및 재요청 로직을 추가할 수 있습니다.
            } finally {
                // commit('SET_LOADING', false);
            }
        },
        showToast({ commit }, message) {
            commit('setToastMessage', message);
            setTimeout(() => {
                commit('clearToastMessage');
            }, message.duration * 1000);
        },
        toggleDarkMode({ commit }) {
            commit('toggleDarkMode');
        },
        startLoading({ commit }) {
            commit('SET_LOADING', true);
        },
        stopLoading({ commit }) {
            commit('SET_LOADING', false);
        },
        async signUp({ commit }, payload) {
            try {
                const response = await axios.post('/api/members/v1/sign-up', payload);
                commit('setToken', response.data);
            } catch (error) {
                throw Error('회원가입이 실패했습니다.');
            }
        },
        async reStoreCanvas({ commit }) {
            try {
                commit('increaseCanvases');
            } catch (error) {
                throw Error('reStoreCanvas 작업이 실패했습니다.');
            }
        },
        async oauthLogin({ commit }, payload) {
            try {
                commit('SET_LOADING', true);
                const response = await axios.post('/api/members/v1/oauth2/login/kakao', payload);
                const { data } = response.data;
                if(data.newUser) {
                    commit('setUserInfo', response.data);
                } else {
                    commit('setToken', response.data);
                }
            } catch (error) {
                throw Error('로그인이 실패했습니다.');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async fetchUserInfo({ commit }, payload) {
            try {
                commit('SET_LOADING', true);
                const response = await axios.post('/api/members/v1/userinfo', payload);
                const { data } = response.data;
                return data;
            } catch (error) {
                throw Error('로그인이 실패했습니다.');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async refreshAccessToken({ state }) {
            const refreshToken = state.refreshToken;
            if (!refreshToken) {
                return false;
            }

            const now = Math.floor(Date.now() / 1000);
            const exp = getExpiration(refreshToken);
            if (exp <= now) {
                return false;
            }

            try {
                await refreshAccessToken(refreshToken);
                return true;
            } catch (error) {
                console.error('Token refresh failed:', error);
                return false;
            }
        },
        async paymentConfirm({ commit }, payload) {
            try {
                commit('SET_LOADING', true);
                const response = await axios.post('/api/members/v1/payment', payload);

                const { data } = response.data;
                if(data && data.result === 'OK') {
                    commit('setCanvases', data);
                } else if( data && data.result === 'WAITING_FOR_DEPOSIT') {
                    /** do nothing .. */
                } else { /** do nothing .. */}
                return data;
            } catch (error) {
                throw Error('로그인이 실패했습니다.');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async logout({ commit }) {
            commit('clearToken');
        },
        async setToken({ commit }, payload) {
            commit('setToken', payload);
        },
        async creationStatus({ commit }) {
            try {
                commit('SET_LOADING', true);
                const response = await axios.get('/api/images/v1/status');
                return response.data;
            } catch (error) {
                throw Error('이미지 생성 요청이 실패했습니다.');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async creation({ commit }, payload) {
            try {
                commit('SET_LOADING', true);
                // const response = await axios.post('/api/images/v1/creation', payload);
                const response = await axios.post('/api/images/v2/imagine', payload);
                commit('creation', response.data);
                if(response.data.data.id < 0) {
                    return {id: response.data.data.id, msg: response.data.data.canvases === -99 ? 'banned' : 'no-canvas'};
                }
                commit('setStatusCheck', {data: {status: 'init', progress: 0}})
                return response.data.data;
            } catch (error) {
                throw Error('이미지 생성 요청이 실패했습니다.');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async upscale({ commit }, payload) {
            try {
                commit('SET_LOADING', true);
                // const response = await axios.post('/api/images/v1/creation', payload);
                const response = await axios.post('/api/images/v2/upscale', payload);
                return response.data.data;
            } catch (error) {
                throw Error('Upscale 요청이 실패했습니다.');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async postImage({ commit }, payload) {
            try {
                commit('SET_LOADING', true);
                const response = await axios.post('/api/images/v1/post', payload);
                return response;
            } catch (error) {
                throw Error('이미지 생성 요청이 실패했습니다.');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async fetchImages({ commit }, payload) {
            try {
                commit('SET_LOADING', true);
                const response = payload.findType === 'LIKE' ? await axios.get('/api/images/v1/like-list', {params: { lastImageId: payload.offset, ...payload}})
                    : await axios.get('/api/images/v1/list', {params: payload});

                if(payload.findType === 'FEATURED') {
                    commit('setFeatured', {reset: payload.init, ...(response.data)});
                } else if(payload.findType === 'RECENT') {
                    commit('setRecent', {reset: payload.init, ...(response.data)});
                } else if(payload.findType === 'LIKE') {
                    commit('setLike', {reset: payload.init, ...(response.data)});
                } else if(payload.findType === 'BOOKMARK') {
                    commit('setBookmark', {reset: payload.init, ...(response.data)});
                }
            } catch (error) {
                throw Error('이미지 조회 요청이 실패했습니다.');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async clearImages({ commit }) {
            commit('setFeatured', {reset: true, data: []});
            commit('setRecent', {reset: true, data: []});
            commit('setLike', {reset: true, data: []});
            commit('setBookmark', {reset: true, data: []});
            commit('setMyImages', {reset: true, data: []});
        },
        async fetchMyImages({ commit }, payload) {
            try {
                commit('SET_LOADING', true);
                const response = await axios.get('/api/images/v2/my-list', {params: payload});
                // const response = await axios.get('/api/images/v1/my-list', {params: payload});
                commit('setMyImages', {reset: payload.init, ...(response.data)});
            } catch (error) {
                throw Error('이미지 조회 요청이 실패했습니다.');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async statusCheckImage({ commit }, payload) {
            try {
                // commit('SET_LOADING', true);
                const response = await axios.get(`/api/images/v1/status-check/${payload.id}`, null);
                commit('setStatusCheck', response.data);
            } catch (error) {
                throw Error('이미지 상태 확인이 실패했습니다.');
            } finally {
                // commit('SET_LOADING', false);
            }
        },
        async clearStatusCheck({ commit }) {
            try {
                commit('SET_LOADING', true);
                commit('setStatusCheck', {data: null});
            } catch (error) {
                throw Error('이미지 상태 확인 초기화가 실패했습니다.');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async setDetailImage({ commit }, payload) {
            try {
                commit('SET_LOADING', true);
                // const response = await axios.get(`/api/images/v1/status-check/${payload.id}`, null);
                commit('setDetailImage', payload);
            } catch (error) {
                throw Error('이미지 셋팅 실패.');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async fetchDetailImage({ commit }, payload) {
            try {
                commit('SET_LOADING', true);
                const response = await axios.get(`/api/images/v1/${payload.id}`, null);
                commit('setDetailImage', {image: response.data.data});
            } catch (error) {
                throw Error('이미지 셋팅 실패.');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async deleteImage({ commit }, payload) {
            try {
                commit('SET_LOADING', true);
                const response = await axios.delete(`/api/images/v1/${payload.id}`, null);
                return response;
            } catch (error) {
                throw Error('이미지 셋팅 실패.');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async setFeatured({ commit }, payload) {
            try {
                commit('SET_LOADING', true);
                const response = await axios.put(`/api/images/v1/featured/${payload.id}?flag=${payload.flag}`, null);
                return response;
            } catch (error) {
                throw Error('이미지 포스트 Featured 셋팅 실패.');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async deletePost({ commit }, payload) {
            try {
                commit('SET_LOADING', true);
                const response = await axios.delete(`/api/images/v1/posts/${payload.id}`, null);
                return response;
            } catch (error) {
                throw Error('이미지 포스트 삭제 실패.');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async deleteMyPost({ commit }, payload) {
            try {
                commit('SET_LOADING', true);
                const response = await axios.delete(`/api/images/v1/my-posts/${payload.id}`, null);
                return response;
            } catch (error) {
                throw Error('이미지 포스트 삭제 실패.');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async likeImage({ commit }, payload) {
            try {
                commit('SET_LOADING', true);
                const response = await axios.post(`/api/images/v1/like`, payload);
                commit('afterLike', payload);

                return response;
            } catch (error) {
                throw Error('이미지 좋아요 실패.');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async bookmarkImage({ commit }, payload) {
            try {
                commit('SET_LOADING', true);
                const response = await axios.post(`/api/images/v1/bookmark`, payload);
                commit('afterBookmark', payload);

                return response;
            } catch (error) {
                throw Error('이미지 북마크 실패.');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async dummyLikeImage({ commit }, payload) {
            try {
                commit('SET_LOADING', true);
                const response = await axios.post(`/api/images/v1/dummy-like`, payload);
                commit('afterLike', payload);

                return response;
            } catch (error) {
                throw Error('이미지 좋아요 실패.');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async uploadImage({ commit }, payload) {
            try {
                commit('SET_LOADING', true);
                const response = await axios.post(`/api/images/v1/upload`, payload, { headers: {'Content-Type': 'multipart/form-data'}});
                return response.data;
            } catch (error) {
                throw Error('이미지 업로드 실패.');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async uploadImages({ commit }, payload) {
            try {
                commit('SET_LOADING', true);
                const response = await axios.post(`/api/images/v1/upload/files`, payload, { headers: {'Content-Type': 'multipart/form-data'}});
                return response.data;
            } catch (error) {
                throw Error('이미지 업로드 실패.');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async uploadProfileImage({ commit }, payload) {
            try {
                commit('SET_LOADING', true);
                const response = await axios.post(`/api/images/v1/upload-profile`, payload, { headers: {'Content-Type': 'multipart/form-data'}});
                commit('setProfileImage', response.data);
                return response.data;
            } catch (error) {
                throw Error('프로필 이미지 업로드 실패.');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async fetchSlideShows({ commit }, payload) {
            try {
                commit('SET_LOADING', true);
                const response = await axios.get(`/api/images/v1/slideshows`, payload);
                commit('setSlideShows', response.data);
                return response.data;
            } catch (error) {
                throw Error('이미지 슬라이드 목록 조회 실패.');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async setShowMyInfo({ commit }, payload) {
            try {
                commit('SET_LOADING', true);
                commit('setShowMyInfo', payload);
            } catch (error) {
                throw Error('setShowMyInfo 실패');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async changeNickName({ commit }, payload) {
            try {
                commit('SET_LOADING', true);
                const response = await axios.put(`/api/members/v1/nickname`, payload);
                if(response.data.data === 'OK') commit('setNickName', {userName: payload.nickName});
                return response.data.data;
            } catch (error) {
                throw Error('changeNickName 실패');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async setShowSupportInfo({ commit }, payload) {
            try {
                commit('SET_LOADING', true);
                commit('setShowSupportInfo', payload);
            } catch (error) {
                throw Error('setShowSupportInfo 실패');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async setFromCreator({ commit }, payload) {
            try {
                commit('SET_LOADING', true);
                commit('setFromCreator', payload);
            } catch (error) {
                throw Error('setFromCreator 실패');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async setAnyInProgress({ commit }, payload) {
            try {
                commit('SET_LOADING', true);
                commit('setAnyInProgress', payload);
            } catch (error) {
                throw Error('setAnyInProgress 실패');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async setIsLeftPaneVisible({ commit }, payload) {
            try {
                commit('SET_LOADING', true);
                commit('setIsLeftPaneVisible', payload);
            } catch (error) {
                throw Error('setIsLeftPaneVisible 실패');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async setIsStyleSelected({ commit }, payload) {
            try {
                commit('SET_LOADING', true);
                commit('setIsStyleSelected', payload);
            } catch (error) {
                throw Error('setIsStyleSelected 실패');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async setInStore({ commit }, payload) {
            try {
                commit('SET_LOADING', true);
                commit('setInStore', payload);
            } catch (error) {
                throw Error('setInStore 실패');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async setInCreator({ commit }, payload) {
            try {
                commit('SET_LOADING', true);
                commit('setInCreator', payload);
            } catch (error) {
                throw Error('setInCreator 실패');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async downloadImage({ commit }, {id}) {
            try {
                commit('SET_LOADING', true);
                const imageUrl = `/api/images/v1/download?id=${id}`;
                const response = await axios.get(imageUrl, {responseType: 'blob'});

                return response.data;
            } catch (error) {
                console.error('이미지 다운로드 중 오류 발생:', error);
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async setSearchKeyword({ commit }, payload) {
            try {
                commit('SET_LOADING', true);
                commit('setSearchKeyword', payload);
            } catch (error) {
                console.error('set SearchKeyword 중 오류 발생:', error);
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async setReload({ commit }, payload) {
            try {
                commit('SET_LOADING', true);
                commit('setReload', payload);
            } catch (error) {
                throw Error('setReload 실패');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async setCurrentTab({ commit }, payload) {
            try {
                commit('SET_LOADING', true);
                commit('setCurrentTab', payload);
            } catch (error) {
                throw Error('setCurrentTab 실패');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async setPrevScroll({ commit }, payload) {
            try {
                commit('SET_LOADING', true);
                commit('setPrevScroll', payload);
            } catch (error) {
                throw Error('setPrevScroll 실패');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async toggleShowCreator({ commit }) {
            try {
                commit('SET_LOADING', true);
                commit('toggleShowCreator');
            } catch (error) {
                throw Error('toggleShowCreator 실패');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async setFocusedImage({ commit }, payload) {
            try {
                commit('SET_LOADING', true);
                commit('setFocusedImage', payload);
            } catch (error) {
                throw Error('setFocusedImage 실패');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async setApplyPrompt({ commit }, payload) {
            try {
                commit('SET_LOADING', true);
                commit('setApplyPrompt', payload);
            } catch (error) {
                throw Error('setApplyPrompt 실패');
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async setSelectedOption({ commit }, payload) {
            try {
                commit('SET_LOADING', true);
                commit('setSelectedOption', payload);
            } catch (error) {
                throw Error('setSelectedOption 실패');
            } finally {
                commit('SET_LOADING', false);
            }
        },
    },
});
