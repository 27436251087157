<script>
export default {
    name: "RegisterDialog",
    data(){
        return {
            dialog: false, // 일반적으로 상태 관리 또는 이벤트를 통해 제어합니다.
            agreeAll: false,
            email: '',
            id: '',
            nickname: '',
            recommendation: 'dorosi',
            birth: '',
            gender: 'male',
            profileImage: '',
            thumbnailImage: '',
            genders: ['남성', '여성', '기타'],
            emailRules: [
                value => !!value || '필수 입력입니다.',
                v => !!v || '이메일을 입력해주세요.',
                v => /.+@.+\..+/.test(v) || '유효한 이메일 형식이 아닙니다.'
            ],
            rules: {
                required: value => !!value || '필수 입력입니다.',
                max100: v => (v || '').length <= 100 || '최대 100자까지 입력 가능합니다.',
                max200: v => (v || '').length <= 200 || '최대 200자까지 입력 가능합니다.'
            },
            valid: false,
            personalInfoAgreement: '<span>sdfjsdflsjdfsd</span>\nsdfjsdflsjdfsd\nsdfjsdflsjdfsd\nsdfjsdflsjdfsd\nsdfjsdflsjdfsd\nsdfjsdflsjdfsd\nsdfjsdflsjdfsd\nsdfjsdflsjdfsd\nsdfjsdflsjdfsd\nsdfjsdflsjdfsd\nsdfjsdflsjdfsd\nsdfjsdflsjdfsd\n',
            serviceAgreement: '<span>긴 텍스트 123123123123</span><br/><p>hihihi</p><br/><p>hihihi</p><br/><p>hihihi</p><br/><p>hihihi</p><br/><p>hihihi</p><br/><p>hihihi</p><br/><p>hihihi</p><br/><p>hihihi</p><br/><p>hihihi</p><br/><p>hihihi</p><br/><p>hihihi</p><br/><p>hihihi</p><br/><p>hihihi</p><br/><p>hihihi</p><br/><p>hihihi</p>',
            panel: null, // 현재 활성화된 패널
            agreeTerms: false,
            agreePrivacy: false,
            subPanel: [],
            items: [
                {id: 1, title: '모두 동의합니다.', checked: false},
            ],
            birthdate: '',
            menu: false,
            recommendationPromotion: 5,
            recommenderBenefit: 5,
        }
    },
    computed: {
        dialogWidth() {
            return window.innerWidth < 960 ? '100%' : '442px';
        },
        minWidth() {
            return window.innerWidth < 960 ? '95%' : '50%';
        },
        formattedBirthdate: {
            get() {
                // 입력 값이 8자리 숫자일 때만 포맷팅 적용
                if (this.birth && this.birth.length === 8) {
                    return `${this.birth.slice(0, 4)}-${this.birth.slice(4, 6)}-${this.birth.slice(6)}`;
                }
                return this.birth;
            },
            set(newValue) {
                // 사용자 입력을 숫자만 남기고 모든 비숫자 문자 제거
                const numericValue = newValue.replace(/\D+/g, '');
                this.birth = numericValue.length > 8 ? numericValue.substr(0, 8) : numericValue;
            }
        }
    },
    watch: {
        items: {
            handler(newItems) {
                const allChecked = newItems.every((item) => item.checked);
                if (allChecked !== this.agreeAll) {
                    this.agreeAll = allChecked;
                }
            },
            deep: true,
        },
        agreeAll(newValue) {
            if (!newValue) {
                this.subPanel = [];
            }
        },
    },
    methods: {
        open(userInfo) {
            const {id, nickname, profileImage, thumbnailImage} = userInfo;
            this.id = id;
            this.nickname = nickname;
            this.email = id;
            this.profileImage = profileImage;
            this.thumbnailImage = thumbnailImage;
            this.recommendation = 'dorosi';
            this.formattedBirthdate = '';
            this.agreeAll = false;
            this.items[0].checked = false;
            this.dialog = true;
        },
        formatBirthdate() {
            // 입력된 값을 확인하고 필요에 따라 setState를 통해 업데이트할 수 있음
            // 여기서는 computed property에 로직이 포함되어 있으므로 별도의 조치가 필요 없음
        },
        close() {
            this.dialog = false;
        },
        async submit() {
            const validated = await this.$refs.form.validate();
            if (validated) {
                this.loading = true;
                try {
                    const {id, email, nickname, gender, birth, profileImage, thumbnailImage, recommendation} = this;
                    await this.$store.dispatch('signUp', {
                        userInfo: {
                            email,
                            nickname,
                            gender,
                            birth,
                            id,
                            profileImage,
                            thumbnailImage
                        },
                        recommendation
                    });
                    this.dialog = false;
                    this.$emit('registerCompleted');
                } catch (error) {
                    alert(error);
                } finally {
                    this.loading = false;
                }
            }
        },
        test() {

        },
        async submitForm() {
            if (await this.$refs.form.validate()) {
                // 폼 제출 로직
            }
        },
        toggleAll(value) {
            this.items.forEach((item) => {
                item.checked = value;
            });
        },
        checkAllAgreed() {
            this.agreeAll = this.agreeTerms && this.agreePrivacy;
        },
        register() {
            if (this.agreeAll) {
                alert("회원가입이 완료되었습니다.");
            } else {
                alert("모든 약관에 동의해주세요.");
            }
        }
    }
}
</script>
<template>
    <v-dialog style="z-index:10101;border-radius: 50px;" v-model="dialog" :width="dialogWidth">
        <v-card style="border-radius: 20px;background-color:#2C2F37;" min-width="250">
            <v-card-title class="mt-2" style="width:100%;color:#8A8C94;font-weight: bold;">
<!--                <v-icon>mdi-card-account-details</v-icon>-->
                <span class="ml-1">회원가입</span></v-card-title>
            <!--            <v-img :src="profileImage"/>-->
            <v-card-text>
                <v-form ref="form" v-model="valid">
                    <v-row class="align-center mt-0 pt-0 mb-0" style="height: 20px;">
                        <v-col cols="3" style="min-width: 180px;" class="my-0 py-0 font-weight-bold">
<!--                            <v-icon>mdi-id-card</v-icon>-->
                            아이디(이메일)
                        </v-col>
                    </v-row>
                    <v-row class="align-center mt-0 pt-0" style="height: 35px;">
                        <v-col style="min-width: 200px;" class="my-0 pb-0 mt-0">
                            <v-text-field readonly density="compact" v-model="id" :rules="emailRules" label="아이디(이메일)"
                                          required
                                          outlined></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="align-center mt-12 mb-0" style="height: 20px;">
                        <v-col cols="3" style="min-width: 180px;" class="my-0 py-0 font-weight-bold">
<!--                            <v-icon>mdi-rename</v-icon>-->
                            닉네임
                        </v-col>
                    </v-row>
                    <v-row class="align-center mt-0 pt-0" style="height: 35px;">
                        <v-col style="min-width: 200px;" class="my-0 pb-0 mt-0">
                            <v-text-field :rules="[rules.required]" density="compact" v-model="nickname" label="닉네임"
                                          required
                                          outlined></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="align-center mt-12 mb-0" style="height: 20px;">
                        <v-col cols="3" style="min-width: 180px;" class="my-0 py-0 font-weight-bold">
<!--                            <v-icon>mdi-cake-variant</v-icon>-->
                            생년월일
                        </v-col>
                    </v-row>
                    <v-row class="align-center mt-0 pt-0" style="height: 35px;">
                        <v-col style="min-width: 200px;" class="my-0 pb-0 mt-0">
                            <!--                            <v-text-field density="compact" v-model="birth" label="생년월일" required outlined></v-text-field>-->
                            <v-text-field
                                :rules="[rules.required]"
                                density="compact"
                                v-model="formattedBirthdate"
                                label="생년월일(8자리)"
                                persistent-hint
                                @input="formatBirthdate"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="align-center mt-13 mb-0" style="height: 20px;">
                        <v-col cols="3" style="min-width: 180px;" class="my-0 py-0 font-weight-bold">
<!--                            <v-icon>mdi-gender-male-female</v-icon>-->
                            성별
                        </v-col>
                    </v-row>
                    <v-row class="align-center mt-0 pt-0" style="height: 35px;">
                        <v-col style="min-width: 200px;" class="my-3 pb-0 mt-0">
                            <v-btn-toggle v-model="gender" style="height: 40px;" mandatory rounded borderless>
                                <v-btn value="male" color="blue darken-2" class="gender-btn">남</v-btn>
                                <v-btn value="female" color="blue darken-2" class="gender-btn">여</v-btn>
                                <v-btn value="none" color="grey darken-2" class="gender-btn">비공개</v-btn>
                            </v-btn-toggle>
                        </v-col>
                    </v-row>
                    <v-row class="align-center mt-13 mb-0" style="height: 20px;">
                        <v-col cols="3" style="min-width: 180px;" class="my-0 py-0 font-weight-bold">
<!--                            <v-icon>mdi-account-heart</v-icon>-->
                            추천인
                        </v-col>
                    </v-row>
                    <v-row class="align-center mt-0 pt-0" style="height: 35px;">
                        <v-col style="min-width: 200px;" class="my-0 pb-0 mt-0">
                            <v-text-field density="compact" v-model="recommendation"
                                          :label="`추천인(닉네임) 입력 시 캔버스 ${recommendationPromotion}장을 드려요!`" outlined></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-text class="mt-10">
<!--                <v-icon>mdi-file-sign</v-icon>-->
                <v-row class="align-center mb-0" style="height: 20px;">
                    <v-col cols="3" style="min-width: 180px;" class="my-0 py-0 font-weight-bold">
                        <!--                            <v-icon>mdi-account-heart</v-icon>-->
                        서비스 이용 약관
                    </v-col>
                </v-row>
                <v-expansion-panels v-model="panel" multiple class="mt-3">
                    <v-expansion-panel v-for="item in items" :key="item.id">
                        <v-expansion-panel-title style="height: 50px;">
                            <!--                                        <template v-slot:actions>-->
                            <v-checkbox
                                density="compact"
                                v-model="item.checked"
                                class="mb-2"
                                style="height: 25px;color:white;"
                                :label="item.title"
                                @click.stop
                            ></v-checkbox>
                            <!--                                        </template>-->
                        </v-expansion-panel-title>
                        <v-expansion-panel-text style="max-height: 200px;overflow:auto;">
                            <div class="article">
                                <h2>제1조 (목적)</h2>
                                <p>이 약관은 주토피아에서 제공하는 AI 생성 관련 서비스를 이용함에 있어 주토피아과 이용자의 권리․의무 및 책임사항을 규정함을 목적으로 합니다.</p>
                                <p>※「PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 이 약관을 준용합니다.」</p>
                            </div>

                            <div class="article">
                                <h2>제2조 (정의)</h2>
                                <p>① “주토피아”란 AI 이미지생성 등을 이용자에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을
                                    말합니다.</p>
                                <p>② “이용자”란 “주토피아”에 접속하여 이 약관에 따라 “주토피아”이 제공하는 서비스를 받는 회원 및 비회원을 말합니다.</p>
                                <p>③ ‘회원’이라 함은 “주토피아”에 회원등록을 한 자로서, 계속적으로 “주토피아”이 제공하는 서비스를 이용할 수 있는 자를 말합니다.</p>
                                <p>④ ‘비회원’이라 함은 회원에 가입하지 않고 “주토피아”이 제공하는 서비스를 이용하는 자를 말합니다.</p>
                            </div>

                            <div class="article">
                                <h2>제3조 (약관 등의 명시와 설명 및 개정)</h2>
                                <p>① “주토피아”는 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함),
                                    전화번호․전자우편주소, 사업자등록번호, 통신판매업 신고번호 등을 이용자가 쉽게 알 수 있도록 “주토피아”의 초기 서비스화면(하단)에
                                    게시합니다.</p>
                                <p>② “주토피아”는 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회,환불조건 등과 같은 중요한 내용을 이용자가 이해할 수 있도록
                                    별도의 연결화면 또는 팝업화면 등을 제공하여 이용자의 확인을 구하여야 합니다.</p>
                                <p>③ “주토피아”는 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래 기본법」, 「전자금융거래법」,
                                    「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수
                                    있습니다.</p>
                                <p>④ “주토피아”가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 주토피아의 초기화면에 그 적용일자 7일 이전부터 적용일자
                                    전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우
                                    “주토피아”은 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.</p>
                                <p>⑤ “주토피아”가 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정 전의
                                    약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지기간 내에
                                    “주토피아”에 송신하여 “주토피아”의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.</p>
                                <p>⑥ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률,
                                    공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호지침 및 관계법령 또는 상관례에 따릅니다.</p>
                            </div>

                            <div class="article">
                                <h2>제4조(서비스의 제공 및 변경)</h2>
                                <p>① “주토피아”은 다음과 같은 업무를 수행합니다.</p>
                                <ul>
                                    <li>1. AI모델을 통한 이미지 생성 및 공유</li>
                                    <li>2. 서비스 사용을 위한 재화 구매</li>
                                    <li>3. 기타 “주토피아”이 정하는 업무</li>
                                </ul>
                                <p>② “주토피아”가 제공하기로 이용자와 계약을 체결한 구매계약이 잘못 되어 변경할 경우에는 그 사유를 이용자에게 즉시 통지합니다.</p>
                                <p>③ 전항의 경우 “주토피아”는 이로 인하여 이용자가 입은 손해를 배상합니다. 다만, “주토피아”가 고의 또는 과실이 없음을 입증하는 경우에는 그러하지
                                    아니합니다.</p>
                            </div>

                            <div class="article">
                                <h2>제5조(서비스의 중단)</h2>
                                <p>① “주토피아"는 컴퓨터 등 정보통신설비의 보수점검․교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수
                                    있습니다.</p>
                                <p>② “주토피아”는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단, “주토피아”이
                                    고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.</p>
                                <p>③ 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 “주토피아”는 제8조에 정한 방법으로 이용자에게
                                    통지하고 당초 “주토피아”에서 제시한 조건에 따라 소비자에게 보상합니다.</p>
                            </div>

                            <div class="article">
                                <h2>제6조(회원가입)</h2>
                                <p>① 이용자는 “주토피아”가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.</p>
                                <p>② “주토피아”는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.</p>
                                <ul>
                                    <li>1. 가입신청자가 이 약관 제7조 제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 제7조 제3항에 의한 회원자격 상실 후 3년이
                                        경과한 자로서 “주토피아”의 회원재가입 승낙을 얻은 경우에는 예외로 한다.
                                    </li>
                                    <li>2. 등록 내용에 허위, 기재누락, 오기가 있는 경우</li>
                                    <li>3. 기타 회원으로 등록하는 것이 “주토피아”의 기술상 현저히 지장이 있다고 판단되는 경우</li>
                                </ul>
                                <p>③ 회원가입계약의 성립 시기는 “주토피아”의 승낙이 회원에게 도달한 시점으로 합니다.</p>
                            </div>

                            <div class="article">
                                <h2>제7조(회원 자격 상실 등)</h2>
                                <p>① 회원이 다음 각 호의 사유에 해당하는 경우, “주토피아”는 회원자격을 제한 및 정지시킬 수 있습니다.</p>
                                <ul>
                                    <li>1. 다른 사람의 “주토피아” 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우</li>
                                    <li>2. “주토피아”을 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우</li>
                                </ul>
                                <p>③ “주토피아”가 회원 자격을 제한․정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 “주토피아”는
                                    회원자격을 상실시킬 수 있습니다.</p>
                                <p>④ “주토피아”가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을
                                    정하여 소명할 기회를 부여합니다.</p>
                            </div>

                            <div class="article">
                                <h2>제8조(회원에 대한 통지)</h2>
                                <p>① “주토피아”가 회원에 대한 통지를 하는 경우, 회원이 “주토피아”와 미리 약정하여 지정한 전자우편 주소로 할 수 있습니다.</p>
                                <p>② “주토피아”는 불특정다수 회원에 대한 통지의 경우 1주일 이상 “주토피아” 게시판에 게시함으로서 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의
                                    거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다.</p>
                            </div>

                            <div class="article">
                                <h2>제9조(구매신청)</h2>
                                <p>① “주토피아”이용자는 “주토피아”상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, “주토피아”는 이용자가 구매신청을 함에 있어서 다음의 각
                                    내용을 알기 쉽게 제공하여야 합니다.</p>
                                <ul>
                                    <li>1. AI 이미지 생성</li>
                                    <li>2. 결제방법의 선택 및 결제</li>
                                </ul>
                            </div>

                            <div class="article">
                                <h2>제10조 (계약의 성립)</h2>
                                <p>① “주토피아”는 제9조와 같은 구매신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다.</p>
                                <ul>
                                    <li>1. 이미지 생성 시 악의적/반복적으로 금칙어 및 필터 키위드를 사용한 경우</li>
                                    <li>2. "주토피아"에 부정적인 영향이 있을 것으로 판단되는 경우</li>
                                </ul>
                                <p>② “주토피아”의 승낙이 제12조제1항의 수신확인통지형태로 이용자에게 도달한 시점에 계약이 성립한 것으로 봅니다.</p>
                                <p>③ “주토피아”의 승낙의 의사표시에는 이용자의 구매 신청에 대한 확인 및 판매가능 여부, 구매신청의 정정 취소 등에 관한 정보 등을 포함하여야
                                    합니다.</p>
                            </div>

                            <div class="article">
                                <h2>제11조(지급방법)</h2>
                                <p>“주토피아”에서 구매한 재화에 대한 대금지급방법은 다음 각 호의 방법 중 가용한 방법으로 할 수 있습니다.</p>
                                <ul>
                                    <li>1. 가상계좌, 직접 이체 등의 계좌이체</li>
                                    <li>2. 선불카드, 직불카드, 신용카드 등의 각종 카드 결제</li>
                                    <li>3. 토스페이, 카카오페이 등의 간편 결제</li>
                                    <li>4. 기타 전자적 지급 방법에 의한 대금 지급 등</li>
                                </ul>
                            </div>

                            <div class="article">
                                <h2>제12조(수신확인통지․구매신청 변경 및 취소)</h2>
                                <p>① “주토피아”는 이용자의 구매신청이 있는 경우 이용자에게 수신확인통지를 합니다.</p>
                                <p>② 수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 구매신청 변경 및 취소를 요청할 수 있으며, 이후 변경할 수 없습니다. 다만, 이미
                                    대금을 지불한 경우에는 제15조의 청약철회 등에 관한 규정에 따릅니다.</p>
                            </div>

                            <div class="article">
                                <h2>제13조(결과물 생성)</h2>
                                <p>① “주토피아”는 AI모델을 통한 이미지 등 결과물 생성 및 전달할 수 있도록 기타의 필요한 조치를 취합니다. 이때 “주토피아”는 이용자가 AI 이미지 생성
                                    결과의 공급 절차 및 진행 사항을 확인할 수 있도록 적절한 조치를 합니다.</p>
                                <p>② “주토피아”는 이용자가 구매한 재화에 대해 사용 방식, 사용수량 등을 명시합니다.</p>
                            </div>

                            <div class="article">
                                <h2>제14조(환불)</h2>
                                <p>“주토피아”에서 구매한 제품의 이용기간과 환불가능기간은 결제시점으로부터 1년 이내로 제한됩니다.</p>
                            </div>

                            <div class="article">
                                <h2>제15조(청약철회 등)</h2>
                                <p>① “주토피아”와 AI 이미지 생성 구매에 관한 계약을 체결한 이용자는 계약내용에 관한 서면을 받기 이전에만 청약의 철회를 할 수 있습니다. 다만,
                                    청약철회에 관하여 「전자상거래 등에서의 소비자보호에 관한 법률」에 달리 정함이 있는 경우에는 동 법 규정에 따릅니다.</p>
                                <p>② 이용자는 AI모델특성상 생성 및 제공 받은 다음에는 반품 및 교환을 할 수 없습니다.</p>
                                <p>③ 구매신청에 “주토피아”가 사전에 청약철회 등이 제한되는 사실을 소비자가 쉽게 알 수 있는 곳에 명기하거나 시용상품을 제공하는 등의 조치를 하지
                                    않았다면 이용자의 청약철회 등이 제한되지 않습니다.</p>
                            </div>

                            <div class="article">
                                <h2>제16조(청약철회 등의 효과)</h2>
                                <p>“주토피아”는 AI모델을 통한 이미지 등 생성에 실패한 경우 이용자에게 3영업일 이내에 환불합니다.</p>
                            </div>

                            <div class="article">
                                <h2>제17조(개인정보보호)</h2>
                                <p>① “주토피아”는 이용자의 개인정보 수집시 서비스제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다.</p>
                                <p>② “주토피아”는 회원가입시 구매계약이행에 필요한 정보를 미리 수집하지 않습니다. 다만, 관련 법령상 의무이행을 위하여 구매계약 이전에 본인확인이 필요한
                                    경우로서 최소한의 특정 개인정보를 수집하는 경우에는 그러하지 아니합니다.</p>
                                <p>③ “주토피아”는 이용자의 개인정보를 수집·이용하는 때에는 당해 이용자에게 그 목적을 고지하고 동의를 받습니다.</p>
                                <p>④ “주토피아”는 수집된 개인정보를 목적외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용·제공단계에서
                                    당해 이용자에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.</p>
                                <p>⑤ “주토피아”가 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(성별, 생년월일), 정보의
                                    수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은자, 제공목적 및 제공할 정보의 내용) 등 「정보통신망 이용촉진 및 정보보호 등에 관한
                                    법률」 제22조제2항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.</p>
                                <p>⑥ 이용자는 언제든지 “주토피아”이 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 “주토피아”는 이에 대해 지체 없이 필요한
                                    조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 “주토피아”는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.</p>
                                <p>⑦ “주토피아”는 개인정보 보호를 위하여 이용자의 개인정보를 취급하는 자를 최소한으로 제한하여야 하며 신용카드, 은행계좌 등을 포함한 이용자의 개인정보의
                                    분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.</p>
                                <p>⑧ “주토피아” 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이
                                    파기합니다.</p>
                                <p>⑨ “주토피아”는 개인정보의 수집·이용·제공에 관한 동의 란을 미리 선택한 것으로 설정해두지 않습니다. 또한 개인정보의 수집·이용·제공에 관한 이용자의
                                    동의거절시 제한되는 서비스를 구체적으로 명시하고, 필수수집항목이 아닌 개인정보의 수집·이용·제공에 관한 이용자의 동의 거절을 이유로 회원가입 등 서비스
                                    제공을 제한하거나 거절하지 않습니다.</p>
                            </div>

                            <div class="article">
                                <h2>제18조(“주토피아”의 의무)</h2>
                                <p>① “주토피아"는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화․용역을
                                    제공하는데 최선을 다하여야 합니다.</p>
                                <p>② “주토피아”는 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.</p>
                                <p>③ “주토피아”이 상품이나 용역에 대하여 「표시․광고의 공정화에 관한 법률」 제3조 소정의 부당한 표시․광고행위를 함으로써 이용자가 손해를 입은 때에는
                                    이를 배상할 책임을 집니다.</p>
                            </div>

                            <div class="article">
                                <h2>제19조(회원의 ID에 대한 의무)</h2>
                                <p>① 기본적인 ID에 관한 관리책임은 회원에게 있습니다.</p>
                                <p>② 회원은 자신의 ID를 제3자에게 이용하게 해서는 안됩니다.</p>
                                <p>③ 회원이 자신의 ID를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 “주토피아”에 통보하고 “주토피아”의 안내가 있는
                                    경우에는 그에 따라야 합니다.</p>
                            </div>

                            <div class="article">
                                <h2>제20조(이용자의 의무)</h2>
                                <p>이용자는 다음 행위를 하여서는 안 됩니다.</p>
                                <ul>
                                    <li>타인의 사진 무단 도용</li>
                                    <li>“주토피아”에 게시된 정보의 변경</li>
                                    <li>“주토피아”가 정한 금지어를 반복적이고 악의적으로 이미지 생성 시 사용 시도</li>
                                    <li>“주토피아” 기타 제3자의 저작권 등 지적재산권에 대한 침해</li>
                                    <li>“주토피아” 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</li>
                                    <li>외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 공개 또는 게시하는 행위</li>
                                    <li>기타 사이트에 악의적인 위협을 가하는 행위</li>
                                </ul>
                            </div>

                            <div class="article">
                                <h2>제21조(재판권 및 준거법)</h2>
                                <p>① “주토피아”는 서비스와 관련하여 분쟁이 발생한 경우 관할 법원은 회사 소재지 관할법원으로 정하며, 준거법은 대한민국의 법령을 적용합니다.</p>
                                <p>② “주토피아”과 이용자 간에 제기된 전자상거래 소송에도 대한민국의 법령을 적용합니다.</p>
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card-text>
            <v-col style="display: flex;justify-content: center;" class="py-0 my-3">
                <v-btn class="confirm-btn" color="#6D49FF" text @click="submit" :disabled="!agreeAll || !valid">회원가입</v-btn>
            </v-col>
        </v-card>
    </v-dialog>
</template>


<style scoped>
.v-expansion-panel-header {
    font-weight: bold;
}

.v-expansion-panel-content {
    color: #000;
}

.gradient-background {
    background: linear-gradient(to right, #072b4f, #2196F3);
}

.agreement {
    border: solid 1px #7db6e3;
    border-radius: 10px;
}

.terms-textarea .v-textarea__control {
    border: solid 2px #2196F3;
    border-radius: 10px;
}

@media (max-width: 599px) {
    .v-dialog {
        margin: 0 10px; /* 모바일에서 좌우 패딩 조정 */
    }
}


.v-expansion-panel-title {
    color: #000;
}

.v-expansion-panel-text {
    font-size: 0.9rem;
}

.v-text-field__slot {
    display: flex;
    align-items: center;
}

.v-radio-group .v-item-group {
    flex-direction: row;
}

.v-menu__content {
    max-height: 400px !important;
}

.gender-btn {
    transition: transform 0.3s ease-in-out;
    min-width: 70px; /* 버튼 최소 너비 설정 */
}

/* 선택된 버튼의 스타일 */
.v-btn--active {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    transform: scale(1.1); /* 선택 시 버튼 확대 */
}

/* 버튼 그룹의 스타일 */
.v-btn-toggle {
    border: none;
    border-radius: 25px; /* 둥근 모서리 */
    background-color: #f5f5f5; /* 배경색 */
}

body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 20px;
}

h1 {
    color: #333;
}

.article {
    margin: 20px 0;
}

h2 {
    color: #666;
}

p, li {
    line-height: 1.6;
}

ul {
    margin: 10px 20px;
}

.confirm-btn {
    background-color: #6D49FF!important;
    width: 95%;
    font-weight: bold;
}
</style>
