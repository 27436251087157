<template>
    <transition name="fade">
        <div v-if="toastMessage"
             class="toast-container"
        >
            <v-card
                :color="'rgba(ff,ff,ff,0.5)'"
                class="toast-card px-5"
            >
                <v-card-title style="font-weight:bold; font-size: 16px;">
                    <v-icon style="background-color: #0CD669; border-radius: 50%;" color="white">{{ toastMessage.icon || 'mdi-check' }}</v-icon>
                    {{ toastMessage.msg }}
                </v-card-title>
            </v-card>
        </div>
    </transition>
</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState(['toastMessage'])
    }
};
</script>

<style scoped>
.toast-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    pointer-events: none;
}

.toast-card {
    width: 50%;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 50px;
    max-width: 470px;
    background-color: #23252C;
    pointer-events: auto;
    border-radius: 20px;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 1s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}
</style>
