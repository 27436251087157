<template>
    <v-app :style="{backgroundColor: inStore ? 'white' : ''}">
        <top-banner v-if="!inStore && !(isMiddleScreen === true && isLeftPaneVisible)" :is-left-pane-visible="isLeftPaneVisible" :is-middle-screen="isMiddleScreen"/>
        <v-btn v-if="isMiddleScreen === true && !isLeftPaneVisible" style="position: fixed; bottom: 20px; left: 20px; background-color: rgba(109, 73, 255, 0.95); color: white; border-radius: 25px; width: 110px; height: 40px; z-index: 1000; text-transform: none; font-weight: bold" @click="$store.dispatch('setIsLeftPaneVisible', {flag: true})">
            <v-icon size="small" left>mdi-arrow-right</v-icon>
            Create
        </v-btn>
        <v-btn v-else-if="isMiddleScreen === true" style="position: fixed; bottom: 20px; left: 20px; background-color: rgba(109, 73, 255, 0.95); color: white; border-radius: 25px; width: 110px; height: 40px; z-index: 1000; text-transform: none; font-weight: bold" @click="$store.dispatch('setIsLeftPaneVisible', {flag: false})">
            <v-icon size="small" left>mdi-arrow-left</v-icon>
            Close
        </v-btn>
<!--        <span v-if="toastMessage" style="z-index:99999;color: whitesmoke;">{{ toastMessage.msg }}</span>-->
        <v-container fluid class="pa-0 mt-5" :style="{backgroundColor: inStore ? 'white' : '#171717', top: (isMiddleScreen === true && isLeftPaneVisible) ? '0' : (isMiddleScreen === true ? '96px' : '36px')}">
            <v-row no-gutters class="main-row pa-0" :style="{top: (isMiddleScreen === true && isLeftPaneVisible) ? '0' : (isMiddleScreen === true ? '96px' : '36px')}">
                <template v-if="isLargeScreen">
                    <div class="left-container" :style="{top: (isMiddleScreen === true && isLeftPaneVisible) ? '0' : '60px'}" :class="{ hidden: !isLeftPaneVisible }">
                        <v-col class="left-pane pa-0">
                            <div class="content">
                                <image-creator-light style="background-color: black"/>
                            </div>
                        </v-col>
                        <img :src="isLeftPaneVisible ? 'close_left.png' : 'open_left.png'"
                             @click="toggleLeftPane"
                             :style="{cursor: 'pointer',right: isLeftPaneVisible ? '2px' : '-40px', width: isLeftPaneVisible ? '40px' : '40px'}"
                             class="toggle-button"
                             v-if="!inStore"/>
<!--                            <span v-if="!isLeftPaneVisible">CREATE</span>-->
                    </div>
                    <div class="right-container" :class="{ expanded: !isLeftPaneVisible }">
                        <v-col
                            class="right-pane pa-0"
                        >
                            <router-view :is-fixed-banner="isFixedBanner" :is-mobile="isMobile" v-show="reload <= 10" :is-left-pane-visible="isLeftPaneVisible"></router-view>
                        </v-col>
                    </div>
                </template>
                <v-col
                    v-else
                    class="pa-0"
                    :class="{ expanded: !isLeftPaneVisible || isFixedBanner }"
                >
                    <router-view :is-middle-screen="isMiddleScreen" :is-fixed-banner="isFixedBanner" :is-mobile="isMobile" v-show="reload <= 10" :is-left-pane-visible="isLeftPaneVisible"></router-view>
                </v-col>
            </v-row>
        </v-container>
        <progress-bar/>
        <toast-view />
        <alert-dialog ref="alert"/>
    </v-app>
</template>
<script>
import TopBanner from "@/components/layout/TopBanner.vue";
import {mapGetters, mapState} from "vuex";
import ProgressBar from "@/components/common/ProgressBar.vue";
import AlertDialog from "@/dialogs/AlertDialog.vue";
import ImageCreatorLight from "@/views/ImageCreatorLight.vue";
import ToastView from "@/components/common/ToastView.vue";

export default {
    watch: {
        reload(v) {
            if (v > 10) {
                this.$store.dispatch("startLoading");
                this.$store.dispatch("setReload", { reload: 0 });
                window.location.reload();
            }
        },
        // statusCheck(v) {
        //     if(v && !['COMPLETED','FAILED'].includes(v.status)) this.$store.dispatch('setIsLeftPaneVisible', {flag: false});
        // }
    },
    components: { TopBanner, ToastView, ProgressBar, AlertDialog, ImageCreatorLight },
    async beforeMount() {
        const accessToken = localStorage.getItem("accessToken");
        const refreshToken = localStorage.getItem("refreshToken");

        const currentTab = localStorage.getItem("currentTab");
        await this.$store.dispatch("setCurrentTab", { tab: currentTab ? currentTab * 1 : 0 });

        if (accessToken && refreshToken) {
            const now = Math.floor(Date.now() / 1000);
            const refreshExp = this.getExpiration(refreshToken);
            const accessExp = this.getExpiration(accessToken);
            if (refreshExp > now) {
                if (accessExp <= now) await this.$store.dispatch("refreshAccessToken");
                const userInfo = await this.$store.dispatch("fetchUserInfo");
                await this.$store.dispatch("setToken", {
                    data: {
                        jwtToken: {
                            accessToken: localStorage.getItem("accessToken"),
                            refreshToken: localStorage.getItem("refreshToken"),
                        },
                        userInfo,
                    },
                });
                if (userInfo.freeCanvas > 0) {
                    this.$refs.alert.open("오늘의 무료 캔버스가 지급 됐어요. 😆");
                }
            }
        } else {
            const checked = localStorage.getItem('checkedWelcomePopup')

            if(!checked || !JSON.parse(checked)){
                localStorage.setItem('checkedWelcomePopup', 'true');
                this.$refs.alert.open('<span style="width:100%;display:flex;justify-content: center;">지금 회원가입 하시면</span><span style="width:100%;display:flex;justify-content: center;">무료 캔버스 5장을 드려요!</span>', null,true);
            }

            if (currentTab * 1 === 2) {
                localStorage.setItem("currentTab", "0");
                await this.$store.dispatch("setCurrentTab", { tab: 0 });
            }
        }

        this.onResize();
        window.addEventListener("resize", this.onResize);
    },
    async mounted() {
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.onResize);
    },
    computed: {
        ...mapGetters(["inCreator", "inStore", 'isLeftPaneVisible', "reload", "showCreator", "statusCheck"]),
        ...mapState(["toastMessage"]),
        // hideBanner() {
        //     return this.$route.name === "ImageCreator";
        // },
    },
    data() {
        return {
            // isLeftPaneVisible: true,
            isFixedBanner: false,
            isMobile: false,
            isLargeScreen: true,
            isMiddleScreen: null,
        };
    },
    methods: {
        onResize() {
            if (window.innerWidth < 1390) this.isFixedBanner = true;
            else this.isFixedBanner = false;

            if (window.innerWidth < 780) this.isMobile = true;
            else this.isMobile = false;

            this.isMiddleScreen = window.innerWidth < 1020;

        },
        toggleLeftPane() {
            // this.isLeftPaneVisible = !this.isLeftPaneVisible;
            this.$store.dispatch('setIsLeftPaneVisible', {flag: !this.isLeftPaneVisible});
        },
        getExpiration(accessToken) {
            const expiration = this.getClaimValue(accessToken, "exp");
            if (!expiration) {
                console.log("exp를 찾을 수 없습니다.");
                return 0;
            }
            return expiration || 0;
        },
        getClaimValue(accessToken, key) {
            const base64Url = accessToken.split(".")[1];
            const base64 = base64Url.replace("-", "+").replace("_", "/");
            const payload = JSON.parse(atob(base64));

            return payload[key];
        },
        async toggleShowCreator() {
            await this.$store.dispatch("toggleShowCreator");
        },
    },
};
</script>
<style>
p {
    font-size: 12px;
}
html {
    overflow-y: hidden !important;
    user-select: none!important;
}
.toggle-button {
    position: absolute;
    top: 20px; /* 약간 하단으로 이동 */
    right: -17px;
    z-index: 2100;
    width: 40px;
    height: 40px;
    min-width: 24px;
    min-height: 24px;
    padding: 0;
    transition: right 0.3s ease-in-out;
}

.main-row {
    display: flex;
    height: calc(100vh - 60px); /* 상단 배너 높이를 뺀 값으로 설정 */
    width: 100%;
    overflow: hidden;
    position: relative;
    top: 36px; /* 상단 배너 높이만큼 아래로 이동 */
}

.left-container {
    display: flex;
    align-items: flex-start;
    position: fixed; /* 상단 배너 아래에 위치하도록 설정 */
    top: 60px; /* 상단 배너 높이 */
    left: 0;
    height: calc(100vh - 60px); /* 상단 배너 높이를 뺀 값으로 설정 */
    width: 430px;
    transition: transform 0.3s ease-in-out;
    z-index: 2000;
}

.left-container.hidden {
    transform: translateX(-100%); /* 패널을 접을 때 숨기기 위한 마진 추가 */
}

.left-pane {
    background-color: black;
    position: relative;
    height: 100%;
    width: 100%;
}

.right-container {
    flex-grow: 1;
    transition: left 0.3s ease-in-out;
    position: absolute;
    top: 0;
    left: 430px;
    right: 0;
    height: 100%;
}

@media (max-width: 780px) {
    .left-pane {
        width: 100%; /* 화면 너비가 600px 이하일 때 너비를 100%로 설정 */
    }
    .left-container {
        display: flex;
        align-items: flex-start;
        position: fixed; /* 상단 배너 아래에 위치하도록 설정 */
        top: 60px; /* 상단 배너 높이 */
        left: 0;
        height: calc(100vh - 60px); /* 상단 배너 높이를 뺀 값으로 설정 */
        width: 100%;
        transition: transform 0.3s ease-in-out;
        z-index: 2000;
    }
    .right-container {
        flex-grow: 1;
        transition: left 0.3s ease-in-out;
        position: absolute;
        top: 0;
        left: 780px;
        right: 0;
        height: 100%;
    }
}

.right-container.expanded {
    left: 0; /* 접혔을 때 오른쪽으로 약간 숨겨짐 */
}

.right-pane {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.main-content {
    width: 100%;
    max-width: 1200px;
    margin: auto;
}

.content {
    height: 100%;
    background-color: black;
    overflow-y: auto;
}

.toast-container {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
    display: flex;
    justify-content: center;
    pointer-events: none;
}

.v-snackbar {
    pointer-events: auto;
}
</style>
