<script>
export default {
    name: "ImageWrapper",
    props: {
        src: String,
        alt: String,
        imageStyle: String,
    },
    data() {
        return {
            imageLoaded: false,
            cnt: 0,
            redrawKey: 0,
        };
    },
    methods: {
        loaded() {
            this.imageLoaded = true
        },
        onError() {
            this.checkImageStatus(this.src);
        },
        checkImageStatus(url) {
            fetch(url)
                .then((response) => {
                    if (response.status !== 200 && this.cnt < 2) {
                        this.cnt += 1;
                        setTimeout(this.checkImageStatus, 1000);
                    } else {
                        this.redrawKey += 1;
                    }
                })
                .catch((error) => {
                    console.error('이미지 로드에 실패했습니다:', error);
                    setTimeout(this.checkImageStatus, 1000);
                });
        },
    }

}
</script>

<template>
    <div style="display: flex; align-items: center;">
        <img v-show="imageLoaded" :key="redrawKey" @load="loaded" :src="src" :alt="alt" :style="imageStyle">
        <div v-show="!imageLoaded"
             style="display: flex; width: 100%; height: 100px; justify-content: center; align-items: center;">
            <div class="spinner"></div>
        </div>
    </div>
</template>

<style scoped>
.highlighted img {
    transform: scale(1.1);
    transition: transform 0.5s ease;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}
</style>
