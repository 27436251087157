<script>
import {mapGetters} from "vuex";
import ProgressingImageBox from "@/components/image/ProgressingImageBox.vue";

export default {
    name: "ReadyImageBox",
    props: {isMobile: Boolean, processing: Boolean, upscaling: Boolean},
    components: {ProgressingImageBox},
    computed: {
        ...mapGetters(['slideShows']),
        currentImage() {
            const { slideShows, currentIndex } = this;
            if(currentIndex % slideShows.length < 0) return {};

            return slideShows[currentIndex % slideShows.length];
        },
    },
    data(){
        return {
            currentIndex: 0,
            interval: null,
        }
    },
    async mounted() {
        const { slideShows } = this;
        if(!slideShows || slideShows.length < 1) {
            await this.$store.dispatch('fetchSlideShows');
        }
        this.startSlider();
    },
    beforeUnmount() {
        this.stopSlider();
    },
    methods:{
        startSlider() {
            this.interval = setInterval(() => {
                this.currentIndex = (this.currentIndex + 1) % this.slideShows.length;
            }, 5000);
        },
        stopSlider() {
            clearInterval(this.interval);
        },
    }
}
</script>

<template>
    <v-container class="py-0 my-0">
        <v-carousel :style="{height: isMobile ? '400px' : '70vh'}" :show-arrows="false" v-model="currentIndex" disabled continuous hide-delimiters>
            <v-carousel-item v-for="(image, index) in slideShows" :key="index">
                <v-img style="height: 100%;vertical-align: middle;" :src="image.url"></v-img>
            </v-carousel-item>
        </v-carousel>
        <div style="display: flex;justify-content: center;align-items: center;">
            <template v-if="processing">
                <progressing-image-box></progressing-image-box>
<!--                <span style="color:red;font-weight: bold;" v-if="upscaling">업스케일 중...</span>-->
            </template>
            <v-btn v-else class="mt-2" style="font-weight:bold;border-radius:10px;color: whitesmoke" color="#484848FF" @click="$emit('applyPrompt', currentImage)"><v-icon color="whitesmoke">mdi-creation</v-icon>리믹스</v-btn>
        </div>
    </v-container>
</template>

<style scoped>
.progress-bar-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.progress-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 400px; /* 프로그레스 바 너비 */
    height: 20px; /* 프로그레스 바 높이 */
}

.progress-segment {
    width: 50px; /* 각 세그먼트의 너비 */
    height: 100%;
    border-radius: 10px;
    background-color: #0db4c4; /* 세그먼트 기본 배경색 */
    opacity: 0.3; /* 시작 시 세그먼트 투명도 */
    animation: lightUp 1s infinite; /* 애니메이션 적용 */
}

/* 각 세그먼트의 애니메이션 딜레이 조정 */
.progress-bar .progress-segment:nth-child(1) { animation-delay: 0s; }
.progress-bar .progress-segment:nth-child(2) { animation-delay: 0.2s; }
.progress-bar .progress-segment:nth-child(3) { animation-delay: 0.4s; }
.progress-bar .progress-segment:nth-child(4) { animation-delay: 0.6s; }
.progress-bar .progress-segment:nth-child(5) { animation-delay: 0.8s; }
.progress-bar .progress-segment:nth-child(6) { animation-delay: 1s; }
/* 필요한 만큼 딜레이 추가 */

@keyframes lightUp {
    0%, 100% { opacity: 0.3; } /* 시작과 끝 투명도 */
    50% { opacity: 1; } /* 중간 지점에서 세그먼트 불이 최대로 밝음 */
}

.message-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: 'Noto Sans', sans-serif;
    color: #4A4A4A; /* 고급스러운 다크 그레이 색상 */
    background-color: transparent; /* 부드러운 배경색 */
    padding: 20px;
    border-radius: 10px; /* 둥근 모서리 */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 부드러운 그림자 효과 */
    width: fit-content;
    margin: auto;
}

.emoji {
    font-size: 24px; /* 이모지 크기 */
}

.message {
    font-weight: bold;
    margin: 10px 0; /* 멘트의 상하 여백 */
    font-size: 18px; /* 텍스트 크기 */
    color: #333; /* 텍스트 색상 */
}

.bouncing-dots {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 200px;
    margin: auto;
}

.dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-image: linear-gradient(to right, #d2a32e 0%, #31c1d3 100%);
    animation: bounce 0.5s infinite alternate;
}

.dot:nth-child(2) {
    animation-delay: 0.1s;
}

.dot:nth-child(3) {
    animation-delay: 0.2s;
}
.dot:nth-child(4) {
    animation-delay: 0.3s;
}
.dot:nth-child(5) {
    animation-delay: 0.4s;
}
.dot:nth-child(6) {
    animation-delay: 0.5s;
}

@keyframes bounce {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-20px);
    }
}
</style>
