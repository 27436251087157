<template>
    <div @click="$store.dispatch('setShowMyInfo', {flag: false});$store.dispatch('setShowSupportInfo', {flag: false})">
        <div class="banner" :style="`z-index: 9990;height: ${!isMiddleScreen ? '60px' : '120px'};background-color:black;position:fixed;width:100%;`">
            <template v-if="!inStore">
                <v-row v-if="isMiddleScreen === false" style="display: flex; width: 100%; height: 50px;">
                    <v-col class="d-flex justify-start align-center" cols="4">
                        <img src="https://dorosi.ai/dorosi.png" class="ml-2" style="object-fit: contain; width: 100px;" />
                    </v-col>
                    <v-col class="d-flex justify-center align-center" cols="4">
                        <div class="tab-buttons mx-2">
                            <div class="tab-button" :class="{ active: selectedOption === 0 }" @click="toggleSwitch(0)">Gallery</div>
                            <div class="tab-button" :class="{ active: selectedOption === 1 }" @click="toggleSwitch(1)">My Work</div>
                            <div class="tab-button" :class="{ active: selectedOption === 2 }" @click="toggleSwitch(2)">Archive</div>
                            <div class="highlight" :style="highlightStyle"></div>
                            <div v-if="showProgressBar" class="progress-container">
                                <div class="arrow-up"></div>
                                <div class="progress-content">
                                    <div class="progress-text">이미지 생성중</div>
                                    <div class="progress-bar">
                                        <div class="progress" :style="{ width: progressValue + '%' }"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-col>
                    <v-col class="d-flex justify-end align-center" cols="4">
                        <v-text-field
                            v-if="showSearchbar"
                            class="ml-16 mt-2 px-3 pt-1"
                            v-model="searchKeyword"
                            @input="updateSearchKeyword($event.target.value)"
                            density="compact"
                            :style="{backgroundColor: '#171717', height: '47px', width: isMobile ? '80%' : '50%', maxWidth: '500px', borderRadius: '15px', border: 'solid black 1px'}"
                            label="Search Images."
                            color="white"
                        >
                            <template #prepend>
                                <v-icon @click="showSearchbar = false">mdi-magnify</v-icon>
                            </template>
                        </v-text-field>
                        <v-btn icon @click="showSearchbar=true" v-else>
                            <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                        <transition name="slide-fade">
                            <div v-if="showMyInfo && !isMiddleScreen" class="accountInfo">
                                <v-card :height="370" @click.stop="" class="ml-2" style="background-color:#2C2F37; border: solid antiquewhite 1px; display: flex;justify-content: center;align-items: center;cursor: default;border-radius: 15px;" color="whitesmoke">
                                    <v-container fluid>
                                        <v-row class="my-0 py-0" style="width: 100%;" justify="center">
                                            <v-col class="py-0 my-0" style="width: 100%; display: flex;justify-content: center;"><img class="ml-5" style="background-color:white; border: solid ghostwhite 2px;border-radius:33px; color: black;width:66px;height: 66px; cursor: pointer;" :src="profileImage" @click.stop="pickFile"></v-col>
                                        </v-row>
                                        <v-row style="display: flex; justify-content: center; align-items: center;" class="mt-2 py-0 my-0">
                                            <v-col class="py-0 my-0" style="width: 100%; display: flex;justify-content: center;">
                                                <div style="display: flex; flex-direction: row;justify-content: start;align-items: center;"><v-card-subtitle style="font-size: 20px; font-weight: bold;" class="font-weight-bold mr-0 pr-0">{{userName}}</v-card-subtitle> <v-btn color="transparent" class="ml-1" icon density="compact" @click="changeNickName"><v-icon color="#616675" size="small">mdi-pencil</v-icon></v-btn></div>
                                            </v-col>
                                        </v-row>
                                        <v-row style="display: flex; justify-content: center; align-items: center;" class="py-0 my-0">
                                            <v-col class="py-0 my-0" style="width: 100%; display: flex;justify-content: center;">
                                                <div style="display: flex; flex-direction: row;justify-content: start;align-items: center;"><v-card-subtitle style="font-size: 14px; color: #616675;" class="font-weight-bold mr-0 pr-0">{{userId}}</v-card-subtitle></div>
                                            </v-col>
                                        </v-row>
                                        <v-divider color="black" class="my-2" />
                                        <v-row style="display: flex; justify-content: start; align-items: center;" class="py-0 my-0">
                                            <v-col class="py-0 my-0" style="display: flex;justify-content: start;">
                                                <v-card-subtitle class="font-weight-bold" style="color:#616675">캔버스 <span style="color: white;">{{paidCanvases}}</span></v-card-subtitle>
                                            </v-col>
                                            <v-col class="py-0 my-0" style="display: flex;justify-content: end;">
                                                <v-btn class="my-info-btn">구매하기</v-btn>
                                            </v-col>
                                        </v-row>
                                        <v-divider color="black" class="my-2" />
                                        <v-row style="display: flex; justify-content: start; align-items: center;" class="py-0 my-0">
                                            <v-col class="py-0 my-0" style="display: flex;justify-content: start;">
                                                <v-card-subtitle class="font-weight-bold" style="color:#616675">데일리 캔버스 <span style="color: white;">{{freeCanvases}}</span> </v-card-subtitle>
                                            </v-col>
                                            <v-col class="py-0 my-0" style="display: flex;justify-content: end;">
                                                <v-btn class="my-info-btn">구독하기</v-btn>
                                            </v-col>
                                        </v-row>
                                        <v-divider color="black" class="my-2" />
                                        <v-row style="display: flex; justify-content: start; align-items: center;" class="py-0 my-4">
                                            <v-col class="py-0 my-0" style="display: flex;justify-content: start;">
                                                <v-card-subtitle class="font-weight-bold" style="color: #616675">고객센터 <span style="color: white;"><v-icon color="white">mdi-information</v-icon></span></v-card-subtitle>
                                            </v-col>
                                        </v-row>
                                        <v-divider color="black" class="my-2" />
                                        <v-row style="display: flex; justify-content: center; align-items: center;">
                                            <v-col class="mx-0 px-0" style="display: flex;justify-content: center;">
                                                <v-btn style="width:90%;margin-top: 5px;" class="my-info-btn" @click.stop="logout">로그아웃</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card>
                            </div>
                            <div v-else-if="showMyInfo && isMiddleScreen" class="mobileAccountInfo">
                                <v-card @click.stop="" style="background-color:#2C2F37; border: solid antiquewhite 1px; display: flex;justify-content: center;align-items: center;cursor: default;border-radius: 15px; width: 100%; height: 100%;" color="whitesmoke">
                                    <v-container fluid>
                                        <v-row class="my-0 py-0" style="width: 100%;" justify="center">
                                            <v-col class="py-0 my-0" style="width: 100%; display: flex;justify-content: center;"><img class="ml-5" style="background-color:white; border: solid ghostwhite 2px;border-radius:33px; color: black;width:66px;height: 66px; cursor: pointer;" :src="profileImage" @click.stop="pickFile"></v-col>
                                        </v-row>
                                        <v-row style="display: flex; justify-content: center; align-items: center;" class="mt-2 py-0 my-0">
                                            <v-col class="py-0 my-0" style="width: 100%; display: flex;justify-content: center;">
                                                <div style="display: flex; flex-direction: row;justify-content: start;align-items: center;"><v-card-subtitle style="font-size: 20px; font-weight: bold;" class="font-weight-bold mr-0 pr-0">{{userName}}</v-card-subtitle> <v-btn color="transparent" class="ml-1" icon density="compact" @click="changeNickName"><v-icon color="#616675" size="small">mdi-pencil</v-icon></v-btn></div>
                                            </v-col>
                                        </v-row>
                                        <v-row style="display: flex; justify-content: center; align-items: center;" class="py-0 my-0">
                                            <v-col class="py-0 my-0" style="width: 100%; display: flex;justify-content: center;">
                                                <div style="display: flex; flex-direction: row;justify-content: start;align-items: center;"><v-card-subtitle style="font-size: 14px; color: #616675;" class="font-weight-bold mr-0 pr-0">{{userId}}</v-card-subtitle></div>
                                            </v-col>
                                        </v-row>
                                        <v-divider color="black" class="my-2" />
                                        <v-row style="display: flex; justify-content: start; align-items: center;" class="py-0 my-0">
                                            <v-col class="py-0 my-0" style="display: flex;justify-content: start;">
                                                <v-card-subtitle class="font-weight-bold" style="color:#616675">캔버스 <span style="color: white;">{{paidCanvases}}</span></v-card-subtitle>
                                            </v-col>
                                            <v-col class="py-0 my-0" style="display: flex;justify-content: end;">
                                                <v-btn class="my-info-btn">구매하기</v-btn>
                                            </v-col>
                                        </v-row>
                                        <v-divider color="black" class="my-2" />
                                        <v-row style="display: flex; justify-content: start; align-items: center;" class="py-0 my-0">
                                            <v-col class="py-0 my-0" style="display: flex;justify-content: start;">
                                                <v-card-subtitle class="font-weight-bold" style="color:#616675">데일리 캔버스 <span style="color: white;">{{freeCanvases}}</span> </v-card-subtitle>
                                            </v-col>
                                            <v-col class="py-0 my-0" style="display: flex;justify-content: end;">
                                                <v-btn class="my-info-btn">구독하기</v-btn>
                                            </v-col>
                                        </v-row>
                                        <v-divider color="black" class="my-2" />
                                        <v-row style="display: flex; justify-content: start; align-items: center;" class="py-0 my-4">
                                            <v-col class="py-0 my-0" style="display: flex;justify-content: start;">
                                                <v-card-subtitle class="font-weight-bold" style="color: #616675">고객센터 <span style="color: white;"><v-icon color="white">mdi-information</v-icon></span></v-card-subtitle>
                                            </v-col>
                                        </v-row>
                                        <v-divider color="black" class="my-2" />
                                        <v-row style="display: flex; justify-content: center; align-items: center;">
                                            <v-col class="mx-0 px-0" style="display: flex;justify-content: center;">
                                                <v-btn style="width:90%;margin-top: 5px;" class="my-info-btn" @click.stop="logout">로그아웃</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card>
                            </div>
                        </transition>
                        <img
                            v-if="profileImage && profileImage !== ''"
                            class="mt-2 ml-6"
                            @click.stop="myInfoClick"
                            style="background-color: white; border: solid ghostwhite 2px; border-radius: 25px; color: black; width: 50px; height: 50px; cursor: pointer;"
                            :src="profileImage"
                        />
                        <img v-else
                             class="mt-2 ml-6"
                             @click.stop="myInfoClick"
                             style="background-color: white; border: solid ghostwhite 2px; border-radius: 25px; color: black; width: 50px; height: 50px; cursor: pointer;"
                             :src="'https://dorosi.ai/sell3.webp'"
                        />
                    </v-col>
                </v-row>
                <template v-else-if="isMiddleScreen === true && !isLeftPaneVisible">
                    <v-row style="width: 100%; position: relative;">
                        <v-col class="mt-3 d-flex justify-center" cols="12">
                            <v-text-field
                                v-if="showSearchbar"
                                class="px-1"
                                v-model="searchKeyword"
                                @input="updateSearchKeyword($event.target.value)"
                                density="compact"
                                :style="{backgroundColor: 'transparent', height: '24px', width: isMobile ? '80%' : '50%', maxWidth: '500px', borderRadius: '15px', border: 'solid black 1px'}"
                                label="Search Images."
                                color="white"
                            >
                                <template #prepend>
                                    <v-icon @click="showSearchbar = false">mdi-magnify</v-icon>
                                </template>
                            </v-text-field>
                            <img v-else src="https://dorosi.ai/dorosi.png" class="ml-2" style="object-fit: contain; width: 100px;" />
                        </v-col>
                        <div v-if="!showSearchbar" style="position: absolute; right: 0; display: flex; justify-content: flex-end; align-items: center;">
                            <v-col class="d-flex justify-end align-center">
                                <v-btn icon @click="showSearchbar=true" class="mt-2" style="background-color: transparent">
                                    <v-icon>mdi-magnify</v-icon>
                                </v-btn>
                                <transition name="slide-fade">
                                    <div v-if="showMyInfo && !isMiddleScreen" class="accountInfo" style="position: absolute;">
                                        <v-card :height="370" @click.stop="" class="" style="background-color:#2C2F37; border: solid antiquewhite 1px; display: flex;justify-content: center;align-items: center;cursor: default;border-radius: 15px;" color="whitesmoke">
                                            <v-container fluid>
                                                <v-row class="my-0 py-0" style="width: 100%;" justify="center">
                                                    <v-col class="py-0 my-0" style="width: 100%; display: flex;justify-content: center;"><img class="ml-5" style="background-color:white; border: solid ghostwhite 2px;border-radius:33px; color: black;width:66px;height: 66px; cursor: pointer;" :src="profileImage" @click.stop="pickFile"></v-col>
                                                </v-row>
                                                <v-row style="display: flex; justify-content: center; align-items: center;" class="mt-2 py-0 my-0">
                                                    <v-col class="py-0 my-0" style="width: 100%; display: flex;justify-content: center;">
                                                        <div style="display: flex; flex-direction: row;justify-content: start;align-items: center;"><v-card-subtitle style="font-size: 20px; font-weight: bold;" class="font-weight-bold mr-0 pr-0">{{userName}}</v-card-subtitle> <v-btn color="transparent" class="ml-1" icon density="compact" @click="changeNickName"><v-icon color="#616675" size="small">mdi-pencil</v-icon></v-btn></div>
                                                    </v-col>
                                                </v-row>
                                                <v-row style="display: flex; justify-content: center; align-items: center;" class="py-0 my-0">
                                                    <v-col class="py-0 my-0" style="width: 100%; display: flex;justify-content: center;">
                                                        <div style="display: flex; flex-direction: row;justify-content: start;align-items: center;"><v-card-subtitle style="font-size: 14px; color: #616675;" class="font-weight-bold mr-0 pr-0">{{userId}}</v-card-subtitle></div>
                                                    </v-col>
                                                </v-row>
                                                <v-divider color="black" class="my-2" />
                                                <v-row style="display: flex; justify-content: start; align-items: center;" class="py-0 my-0">
                                                    <v-col class="py-0 my-0" style="display: flex;justify-content: start;">
                                                        <v-card-subtitle class="font-weight-bold" style="color:#616675">캔버스 <span style="color: white;">{{paidCanvases}}</span></v-card-subtitle>
                                                    </v-col>
                                                    <v-col class="py-0 my-0" style="display: flex;justify-content: end;">
                                                        <v-btn class="my-info-btn">구매하기</v-btn>
                                                    </v-col>
                                                </v-row>
                                                <v-divider color="black" class="my-2" />
                                                <v-row style="display: flex; justify-content: start; align-items: center;" class="py-0 my-0">
                                                    <v-col class="py-0 my-0" style="display: flex;justify-content: start;">
                                                        <v-card-subtitle class="font-weight-bold" style="color:#616675">데일리 캔버스 <span style="color: white;">{{freeCanvases}}</span> </v-card-subtitle>
                                                    </v-col>
                                                    <v-col class="py-0 my-0" style="display: flex;justify-content: end;">
                                                        <v-btn class="my-info-btn">구독하기</v-btn>
                                                    </v-col>
                                                </v-row>
                                                <v-divider color="black" class="my-2" />
                                                <v-row style="display: flex; justify-content: start; align-items: center;" class="py-0 my-4">
                                                    <v-col class="py-0 my-0" style="display: flex;justify-content: start;">
                                                        <v-card-subtitle class="font-weight-bold" style="color: #616675">고객센터 <span style="color: white;"><v-icon color="white">mdi-information</v-icon></span></v-card-subtitle>
                                                    </v-col>
                                                </v-row>
                                                <v-divider color="black" class="my-2" />
                                                <v-row style="display: flex; justify-content: center; align-items: center;">
                                                    <v-col class="mx-0 px-0" style="display: flex;justify-content: center;">
                                                        <v-btn style="width:90%;margin-top: 5px;" class="my-info-btn" @click.stop="logout">로그아웃</v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card>
                                    </div>
                                    <div v-else-if="showMyInfo && isMiddleScreen" class="mobileAccountInfo">
                                        <v-card @click.stop="" class="mobile-account-card" color="whitesmoke">
                                            <v-icon class="close-button" @click="closePopup">mdi-close</v-icon>
                                            <v-container fluid>
                                                <v-row class="my-0 py-0" style="width: 100%;" justify="center">
                                                    <v-col class="py-0 my-0" style="width: 100%; display: flex; justify-content: center;">
                                                        <img class="ml-5" :src="profileImage" @click.stop="pickFile">
                                                    </v-col>
                                                </v-row>
                                                <v-row style="display: flex; justify-content: center; align-items: center;" class="mt-2 py-0 my-0">
                                                    <v-col class="py-0 my-0" style="width: 100%; display: flex; justify-content: center;">
                                                        <div style="display: flex; flex-direction: row; justify-content: start; align-items: center;">
                                                            <v-card-subtitle class="font-weight-bold">{{userName}}</v-card-subtitle>
                                                            <v-btn color="transparent" class="ml-1 edit-icon" style="width:50px;height:50px;" icon density="compact" @click="changeNickName">
                                                                <v-icon color="#616675" size="small">mdi-pencil</v-icon>
                                                            </v-btn>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                                <v-row style="display: flex; justify-content: center; align-items: center;" class="py-0 my-3">
                                                    <v-col class="py-0 my-0" style="width: 100%; display: flex; justify-content: center;">
                                                        <v-card-subtitle class="font-weight-bold secondary">{{userId}}</v-card-subtitle>
                                                    </v-col>
                                                </v-row>
                                                <v-divider color="black" class="my-2" />
                                                <v-row style="display: flex; justify-content: space-between; align-items: center;" class="py-0 my-3">
                                                    <v-col class="py-0 my-0" style="display: flex; justify-content: start;">
                                                        <v-card-subtitle class="font-weight-bold secondary">프리미엄 캔버스 <span style="color: white;">{{paidCanvases}}</span></v-card-subtitle>
                                                    </v-col>
                                                    <v-col class="py-0 my-0" style="display: flex; justify-content: end;">
                                                        <v-btn style="width:150px; border-radius: 10px;" class="my-info-btn">구매하기</v-btn>
                                                    </v-col>
                                                </v-row>
                                                <v-divider color="black" class="my-2" />
                                                <v-row style="display: flex; justify-content: space-between; align-items: center;" class="py-0 my-3">
                                                    <v-col class="py-0 my-0" style="display: flex; justify-content: start;">
                                                        <v-card-subtitle class="font-weight-bold secondary">데일리 캔버스 <span style="color: white;">{{freeCanvases}}</span></v-card-subtitle>
                                                    </v-col>
                                                    <v-col class="py-0 my-0" style="display: flex; justify-content: end;">
                                                        <v-btn style="width:150px; border-radius: 10px;" class="my-info-btn">구독하기</v-btn>
                                                    </v-col>
                                                </v-row>
                                                <v-divider color="black" class="my-2" />
                                                <v-row style="display: flex; justify-content: space-between; align-items: center;" class="py-0 my-4">
                                                    <v-col class="py-0 my-0" style="display: flex; justify-content: start;">
                                                        <v-card-subtitle class="font-weight-bold secondary">고객센터 <span style="color: white;"><v-icon color="white">mdi-information</v-icon></span></v-card-subtitle>
                                                    </v-col>
                                                </v-row>
                                                <v-divider color="black" class="my-3" />
                                                <v-row style="display: flex; justify-content: center; align-items: center;">
                                                    <v-col class="mx-0 px-0" style="display: flex; justify-content: center;">
                                                        <v-btn class="my-info-btn" styl e="border-radius:15px;font-size: 20px;" @click.stop="logout">로그아웃</v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card>
                                    </div>
                                </transition>
                                <img
                                    v-if="profileImage && profileImage !== ''"
                                    class="mt-2 ml-1"
                                    @click.stop="myInfoClick"
                                    style="background-color: white; border: solid ghostwhite 2px; border-radius: 50%; color: black; width: 35px; height: 35px; cursor: pointer;"
                                    :src="profileImage"
                                />
                                <img v-else
                                     class="mt-2 ml-1"
                                     @click.stop="myInfoClick"
                                     style="background-color: white; border: solid ghostwhite 2px; border-radius: 50%; color: black; width: 35px; height: 35px; cursor: pointer;"
                                     :src="'https://dorosi.ai/sell3.webp'"
                                />
                            </v-col>
                        </div>
                    </v-row>
                    <v-row>
                        <v-col class="d-flex justify-center align-center" cols="12">
                            <div class="tab-buttons mx-2">
                                <div class="tab-button" :class="{ active: selectedOption === 0 }" @click="toggleSwitch(0)">Gallery</div>
                                <div class="tab-button" :class="{ active: selectedOption === 1 }" @click="toggleSwitch(1)">My Work</div>
                                <div class="tab-button" :class="{ active: selectedOption === 2 }" @click="toggleSwitch(2)">Archive</div>
                                <div class="highlight" :style="highlightStyle"></div>
                                <div v-if="showProgressBar" class="progress-container">
                                    <div class="arrow-up"></div>
                                    <div class="progress-content">
                                        <div class="progress-text">이미지 생성중</div>
                                        <div class="progress-bar">
                                            <div class="progress" :style="{ width: progressValue + '%' }"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </template>
                <input type="file" ref="fileInput" @change="uploadProfileImage" style="display: none" accept=".png, .jpg, .jpeg" />
                <div style="height: 50px;"></div>
            </template>
        </div>
        <social-login-dialog ref="socialLoginDialog" />
        <alert-dialog ref="alert" />
        <confirm-dialog ref="confirm" />
        <change-nick-name-dialog @changed="changedHandle" ref="changeNickName" />
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import SocialLoginDialog from "@/dialogs/SocialLoginDialog.vue";
import AlertDialog from "@/dialogs/AlertDialog.vue";
import ConfirmDialog from "@/dialogs/ConfirmDialog.vue";
import ChangeNickNameDialog from "@/dialogs/ChangeNickNameDialog.vue";

export default {
    name: "TopBanner",
    props: {
        isMyImages: Boolean,
        isLeftPaneVisible: Boolean,
        isMiddleScreen: Boolean,
    },
    watch: {
        inStore() {
            this.searchKeyword = '';
        },
        inCreator() {
            this.searchKeyword = '';
        }
    },
    components: {ConfirmDialog, SocialLoginDialog, AlertDialog, ChangeNickNameDialog},
    computed: {
        highlightStyle() {
            return {
                transform: `translateX(${this.selectedOption * 100}%)`,
            };
        },
        ...mapGetters([
            'isAuthenticated',
            'darkMode',
            'userId',
            'userName',
            'profileImage',
            'thumbnailImage',
            'canvases',
            'freeCanvases',
            'paidCanvases',
            'tabletEnd',
            'tabletUseCnt',
            'showMyInfo',
            'showSupportInfo',
            'isStyleSelected',
            'inStore',
            'inCreator',
            'statusCheck',
            'selectedOption'
        ]),
        ...mapState(['isLoading']),
        locationHref() {
            return window.location.href.split('/').pop();
        },
        showProgressBar() {
            return this.statusCheck && !['COMPLETED', 'FAILED', undefined, null].includes(this.statusCheck.status);
        },
        progressValue() {
            return this.statusCheck && this.statusCheck.progress ? this.statusCheck.progress : 0;
        }
    },
    data() {
        return {
            updateSearchKeywordTimer: null,
            isMobile: false,
            // isMiddleScreen: false,
            isXSmallScreen: false,
            drawer: false,
            isToolbarVisible: false,
            isAnyImageHighlighted: false,
            searchKeyword: '',
            progress: 70,
            showSearchbar: false,
        };
    },
    async mounted() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.onResize);
        this.$store.dispatch('setSelectedOption', {option: (this.locationHref === 'my-work' ? 1 : (this.locationHref === 'archive' ? 2 : 0))});
        this.searchKeyword = '';
        await this.$store.dispatch('setSearchKeyword', {keyword: ''});
        this.onResize();
    },
    unmounted() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        ...mapActions(['toggleDarkMode']),
        onResize() {
            this.isMobile = window.innerWidth < 640;
            // this.isMiddleScreen = window.innerWidth < 1020;
            this.isXSmallScreen = window.innerWidth < 400;
        },
        closePopup() {
            this.$store.dispatch('setShowMyInfo', { flag: false });
        },
        myInfoClick() {
            this.$store.dispatch('setShowSupportInfo', {flag: false});
            this.moveLoginIfRequired(() => {
                this.$store.dispatch('setShowMyInfo', {flag: !this.showMyInfo});
            });
        },
        supportInfoClick() {
            this.$store.dispatch('setShowMyInfo', {flag: false});
            this.$store.dispatch('setShowSupportInfo', {flag: !this.showSupportInfo});
        },
        async logout() {
            this.showMyInfo = false;
            this.$store.dispatch('startLoading');
            setTimeout(async () => {
                await this.$store.dispatch('logout');
                await this.$router.replace({name: 'ImageHome'});
                window.location.reload();
                // this.drawer = false;
                // this.$store.dispatch('stopLoading');
            }, 500)
        },
        toggleSwitch(idx) {
            this.searchKeyword = '';
            this.$store.dispatch('setSelectedOption', {option: idx});
            const myWorkTarget = {name: 'MyHome'};
            const showCaseTarget = {name: 'ImageHome'};
            const archiveTarget = {name: 'Archive'};
            if (this.selectedOption === 1) {
                this.moveLoginIfRequired(() => {
                    this.$router.replace(myWorkTarget);
                });
            } else if (this.selectedOption === 2) {
                this.$router.replace(archiveTarget);
            } else if (this.selectedOption === 0) {
                this.$router.replace(showCaseTarget);
            }
        },
        moveLoginIfRequired(block) {
            if (!this.isAuthenticated) {
                this.$store.dispatch('setIsStyleSelected', {flag: false});
                this.$refs.socialLoginDialog.open();
            } else {
                if (block) block();
            }
        },
        handleScroll() {
            const bannerHeight = 300;
            this.isToolbarVisible = window.scrollY > bannerHeight;
        },
        pickFile() {
            this.$refs.fileInput.click();
        },
        async uploadProfileImage(event) {
            const files = event.target.files;
            if (files.length > 0) {
                const formData = new FormData();
                formData.append('file', files[0]);

                try {
                    await this.$store.dispatch('uploadProfileImage', formData);
                    this.$refs.alert.open('프로필 이미지를 변경했어요.');
                    this.$refs.fileInput.value = '';
                } catch (error) {
                    console.error('업로드 중 오류 발생:', error);
                }
            }
        },
        async moveStore() {
            this.$router.push({name: 'CanvasStore'});
        },
        async changeNickName() {
            this.$refs.changeNickName.open();
        },
        handleSearchKeywordInput($event) {
            this.searchKeyword = $event.target.value;
            this.updateSearchKeyword(this.searchKeyword);
        },
        updateSearchKeyword(keyword) {
            clearTimeout(this.updateSearchKeywordTimer);
            this.updateSearchKeywordTimer = setTimeout(() => {
                this.$store.dispatch('setSearchKeyword', {keyword});
            }, 500);
        },
        changedHandle() {
            this.$store.dispatch('startLoading');
            setTimeout(() => {
                this.$store.dispatch('stopLoading');
            }, 1000);
        },
    },
};
</script>

<style scoped>
.banner {
    position: relative;
    height: 450px;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-size: cover;
    background-position: center;
    padding: 0 20px;
    z-index: 1001;
    flex-direction: column;
}

@media (max-width: 1200px) {
    .banner {
        height: 150px;
        padding: 0 10px;
    }
}

.title {
    font-size: 26px;
    font-weight: bold;
    font-family: Rockwell;
}

.tab-buttons {
    display: flex;
    position: relative;
    background-color: transparent;
    border-radius: 5px;
}

.tab-button {
    flex: 1;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    width: 120px;
    user-select: none;
    font-weight: bold;
    color: darkgray;
    transition: color 0.3s ease;
    z-index: 1;
}

.tab-button.active {
    color: #fff;
}

.highlight {
    position: absolute;
    top: 0;
    bottom: 0;
    color: whitesmoke;
    width: 33.33%;
    user-select: none;
    background-color: #6D49FF;
    border-radius: 25px;
    transition: transform 0.3s ease;
}

.progress-container {
    position: absolute;
    bottom: -60px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #2e2e2e;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.arrow-up {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #2e2e2e;
    position: absolute;
    top: -10px;
}

.progress-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.progress-text {
    color: whitesmoke;
    font-size: 14px;
    margin-bottom: 5px;
}

.progress-bar {
    width: 140px;
    height: 4px;
    color: whitesmoke;
    background-color: #e0e0e0;
    border-radius: 2px;
    overflow: hidden;
}

.progress {
    height: 100%;
    background-color: #6D49FF;
    transition: width 0.3s ease;
}

.slide-fade-enter-active, .slide-fade-leave-active {
    transition: all 0.5s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
    opacity: 0;
    transform: translateX(50px);
}

.accountInfo {
    width: 284px;
    height: 370px;
    position: absolute;
    right: 10px;
    top: 10px;
}

.mobileAccountInfo {
    z-index: 10010;
    width: 100vw;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.mobile-account-card {
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none;
    padding: 20px;
    background-color: #2C2F37;
    border-radius: 0;
    color: whitesmoke;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.mobile-account-card img {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    border: solid ghostwhite 2px;
    background-color: white;
}

.mobile-account-card .v-card-subtitle {
    font-size: 20px;
    font-weight: bold;
    color: whitesmoke;
}

.mobile-account-card .v-card-subtitle.secondary {
    font-size: 20px;
    color: #616675;
}

.mobile-account-card .v-btn {
    width: 100%;
    margin-top: 10px;
    background-color: #3D414C;
    color: whitesmoke;
    font-weight: bold;
}

.mobile-account-card .edit-icon {
    font-size: 24px;
    margin-left: 8px;
}

.close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    font-size: 24px;
    color: whitesmoke;
}

.supportInfo {
    width: 320px;
    height: 200px;
    position: absolute;
    right: 10px;
    top: 10px;
}

.my-info-btn {
    background-color: #3D414C;
    color: whitesmoke;
    font-weight: bold;
}

</style>
