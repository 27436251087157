<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card width="900" height="830" class="px-10">
            <v-card-title class="pl-0 font-weight-bold my-3" style="font-size: 25px;">새로운 이미지 생성하기</v-card-title>
<!--            <v-row align="center" justify="center">-->
<!--                <v-col cols="12" class="d-flex justify-center">-->
<!--                    <v-tabs v-model="tab" background-color="blue">-->
<!--&lt;!&ndash;                        <v-tab>프리셋</v-tab>&ndash;&gt;-->
<!--                        <v-tab>직접 입력</v-tab>-->
<!--                    </v-tabs>-->
<!--                </v-col>-->
<!--            </v-row>-->

<!--            <v-tabs-items v-model="tab">-->
<!--                <v-tab-item v-if="tab===3">-->
                    <!-- 프리셋 입력 영역 -->
<!--                    <div class="tw-py-4">-->
<!--                        <v-container>-->
<!--                            <v-row>-->
<!--                                <v-col>-->
<!--                                    <v-card-title>누가(무엇이) 등장하나요?</v-card-title>-->
<!--                                    <v-radio-group v-model="who" row>-->
<!--                                        <v-radio v-for="item in whoOptions" :key="item.text" :label="item.text" :value="item.value"></v-radio>-->
<!--                                        <v-text-field v-if="who === 'custom'" label="직접 입력" v-model="customWho"></v-text-field>-->
<!--                                    </v-radio-group>-->
<!--                                </v-col>-->
<!--                                <v-col>-->
<!--                                    <v-card-title>시점은 언제인가요?</v-card-title>-->
<!--                                    <v-radio-group v-model="when" row>-->
<!--                                        <v-radio v-for="item in whenOptions" :key="item.text" :label="item.text" :value="item.value"></v-radio>-->
<!--                                        <v-text-field v-if="when === 'custom'" label="직접 입력" v-model="customWhen"></v-text-field>-->
<!--                                    </v-radio-group>-->
<!--                                </v-col>-->
<!--                                <v-col>-->
<!--                                    <v-radio-group v-model="where" row>-->
<!--                                        <v-card-title>장소는 어디인가요?</v-card-title>-->
<!--                                        <v-radio v-for="item in whereOptions" :key="item.text" :label="item.text" :value="item.value"></v-radio>-->
<!--                                        <v-text-field v-if="where === 'custom'" label="직접 입력" v-model="customWhere"></v-text-field>-->
<!--                                    </v-radio-group>-->
<!--                                </v-col>-->
<!--                            </v-row>-->
<!--                            <v-row>-->
<!--                                <v-col>-->
<!--                                    <v-radio-group v-model="what" row>-->
<!--                                        <v-card-title>어떤 일이 일어나나요?</v-card-title>-->
<!--                                        <v-radio v-for="item in whatOptions" :key="item.text" :label="item.text" :value="item.value"></v-radio>-->
<!--                                        <v-text-field v-if="what === 'custom'" label="직접 입력" v-model="customWhat"></v-text-field>-->
<!--                                    </v-radio-group>-->
<!--                                </v-col>-->
<!--                                <v-col>-->
<!--                                    <v-radio-group v-model="why" row>-->
<!--                                        <v-card-title>이유가 무엇인가요?</v-card-title>-->
<!--                                        <v-radio v-for="item in whyOptions" :key="item.text" :label="item.text" :value="item.value"></v-radio>-->
<!--                                        <v-text-field v-if="why === 'custom'" label="직접 입력" v-model="customWhy"></v-text-field>-->
<!--                                    </v-radio-group>-->
<!--                                </v-col>-->
<!--                                <v-col>-->
<!--                                    <v-radio-group v-model="how" row>-->
<!--                                        <v-card-title>어떻게 벌어지나요?</v-card-title>-->
<!--                                        <v-radio v-for="item in howOptions" :key="item.text" :label="item.text" :value="item.value"></v-radio>-->
<!--                                        <v-text-field v-if="how === 'custom'" label="직접 입력" v-model="customHow"></v-text-field>-->
<!--                                    </v-radio-group>-->
<!--                                </v-col>-->
<!--                            </v-row>-->
<!--                        </v-container>-->
<!--                    </div>-->
<!--                </v-tab-item>-->
<!--                <v-tab-item v-if="tab===0">-->
                    <v-card-text class="sample-text">
                        <span style="font-weight: bold; color: orangered">예시 1)</span> 다시 살아 돌아온 아이언맨이 멋지게 타노스에게 한 방 먹여주는 장면.<br/>
                        주변에는 토르와 헐크가 그를 돕고 있다. 타노스는 지쳐 있으며, 시민들은 환호하고 있다.<br/>
                        배경은 뉴욕 시티이며, 사실적은 스타일에 영화속 한 장면을 캡쳐한 듯 하다.
                    </v-card-text>
                    <v-card-text class="mt-3 sample-text" style="background-color: lightcyan;">
                        <span style="font-weight: bold; color: orangered">예시 2)</span> 분홍색 군복을 입은 아르헨티나 갈색 머리 여성 제2차 세계 대전 시대의<br/>
                        군인이 더러운 환경에서 웃으며 총을 쏘고 있다.<br/>
                        그녀 옆에는 분홍색으로 입은 두 명의 친구가 있다.<br/>
                        높은 각도에서 촬영된 영상은 스티븐 스필버그의 연출로,<br/>
                        색감이 강조된, 색감이 따뜻하지 않은, 차가운 이미지이다.
                    </v-card-text>
                    <v-card-text class="mt-3 sample-text" style="background-color: lightgoldenrodyellow">
                        <span style="font-weight: bold; color: orangered">예시 3)</span> 고딕 엑소티카 스타일의 촛불, 엠마 스톤을 닮은 젊은 여성.<br/>
                        환경 초상화, 스튜디오 사진, 난해한 아트 디렉션, 빛나는 팔레트.
                    </v-card-text>
                    <v-row class="mt-4 mb-0 py-0">
                        <v-col cols="12" md="6" class="my-0 py-0">
                            <v-select
                                v-model="ar"
                                :items="arOptions"
                                label="이미지 비율 선택"
                                outlined
                                dense
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6" class="my-0 py-0">
                            <v-checkbox
                                v-model="tile"
                                value="Y"
                                label="타일 스타일 적용"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row class="my-0 py-0">
                        <v-col class="my-0 py-0">
                            <v-text-field
                                v-model="negative"
                                label="기타 포함하지 않을 내용"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-textarea class="mb-0" label="이미지 설명" :hint="hintText" counter maxlength="200" v-model="descriptor"></v-textarea>
<!--                </v-tab-item>-->
<!--            </v-tabs-items>-->
            <v-card-actions class="mt-0">
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="cancel">취소</v-btn>
                <v-btn color="blue darken-1" text :disabled="!descriptor || descriptor.trim() === ''" @click="createImage">생성</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'CreateImageDialog',
    computed: {
        hintText() {
            const { descriptor } = this;
            if(!descriptor || descriptor.trim() === '') return '200자 이하로 작성해주세요. 구체적일수록 좋아요!';
            if(descriptor && descriptor.trim().length < 20) return '너무 짧은 것 같아요.'
            if(descriptor && descriptor.trim().length > 150) return '최고에요!'
            if(descriptor && descriptor.trim().length > 100) return '좋아요.'

            return '나쁘지 않네요.';
        },
    },
    data() {
        return {
            dialog: false,
            tab: null,
            who: '',
            when: '',
            where: '',
            what: '',
            why: '',
            how: '',
            customWho: '',
            customWhen: '',
            customWhere: '',
            customWhat: '',
            customWhy: '',
            customHow: '',
            descriptor: '',
            whoOptions: [
                { text: "한국인 남성 피트니스 모델", value: "한국인 남성 피트니스 모델" },
                { text: "행복한 미국인 가족", value: "행복한 미국인 가족" },
                { text: "20대 영국인 커플", value: "20대 영국인 커플" },
            ],
            whenOptions: [
                { text: "중세시대", value: "" },
                { text: "어느 평온한 주말 오후", value: "" },
                { text: "과거 조선시대", value: "" },
            ],
            whereOptions: [
                { text: "뒤로 호수가 보이는 멋진 공원", value: "" },
                { text: "사람이 많은 거리", value: "" },
                { text: "직접 입력", value: "" },
            ],
            whatOptions: [
                { text: "옵션1", value: "" },
                { text: "옵션2", value: "" },
                { text: "직접 입력", value: "" },
            ],
            whyOptions: [
                { text: "옵션1", value: "" },
                { text: "옵션2", value: "" },
                { text: "직접 입력", value: "" },
            ],
            howOptions: [
                { text: "옵션1", value: "" },
                { text: "옵션2", value: "" },
                { text: "직접 입력", value: "" },
            ],
            ar: '16:9',
            arOptions: ['1:1', '1:2', '3:4', '9:16', '2:1', '4:3', '16:9'],
            tile: null,
            negative: null,
        };
    },
    methods: {
        async createImage() {
            // 이미지 생성 로직 구현
            const { descriptor , ar, negative, tile } = this;
            await this.$store.dispatch('creation', {type: 'CUSTOM', descriptor, ar, negative, tile: tile === 'Y' ? true : false});
            this.dialog = false;
        },
        open() {
            this.dialog = true;
        },
        cancel() {
            this.dialog = false;
        }
    },
};
</script>
<style scoped>
.sample-text{
    background-color: antiquewhite;
    border-radius: 10px;
    font-family: "Nanum Myeongjo";
}
</style>
