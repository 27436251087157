<template>
    <div style="z-index:4000">
        <v-dialog v-model="isVisible" style="z-index:4000" max-width="80%" min-width="800px" class="popup-dialog">
            <v-card class="popup-card" height="700" style="z-index:4000">
                <v-row no-gutters style="width:100%;z-index:4000;">
                    <v-col cols="8" class="image-col py-3" style="z-index:4000;">
                        <img :src="popupData.image_src" class="popup-image" />
                    </v-col>
                    <v-col cols="4" class="content-col" style="justify-content: end;z-index:4000">
                        <div class="popup-content">
                            <div class="popup-header">
                                <v-avatar size="40">
                                    <img :src="popupData.profile_image" alt="Profile Image" />
                                </v-avatar>
                                <div class="popup-info">
                                    <div class="username">{{ popupData.username }}</div>
                                </div>
                            </div>
                            <div class="title">{{ popupData.title }}</div>
                            <div class="like-bookmark mt-2">
                                <v-btn class="like-icon" @click="$emit('like', image)">
                                    <v-icon v-if="image.liked" class="like-icon" color="red">mdi-heart</v-icon>
                                    <v-icon v-else class="like-icon" color="red">mdi-heart-outline</v-icon>
                                    <span>{{ image.likeCount }}</span>
                                </v-btn>
                                <v-btn class="bookmark-icon" @click="$emit('bookmark', image)">
                                    <v-icon v-if="image.bookmarked" class="bookmark-icon">mdi-bookmark</v-icon>
                                    <v-icon v-else class="bookmark-icon">mdi-bookmark-outline</v-icon>
                                    <span>{{ image.bookmarkCount }}</span>
                                </v-btn>
                            </div>
                            <v-divider class="mb-3" />
                            <div class="prompt-header">
                                <span>프롬프트</span>
                                <v-btn depressed icon style="background-color: transparent" @click="copyToClipboard" density="compact" class="copy-btn">
                                    <v-icon color="white" density="compact">mdi-content-copy</v-icon>
                                </v-btn>
                            </div>
                            <div class="prompt-container">
                                <div class="prompt">{{ popupData.prompt }}</div>
                            </div>
                            <div class="popup-actions px-3">
                                <v-btn @click="emitAction('download')" class="download-btn">
                                    <v-icon left>mdi-download</v-icon>
                                </v-btn>
                                <v-btn @click="emitAction('remix')" class="remix-btn ml-2">
                                    Remix
                                </v-btn>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
            <v-btn style="z-index:4000;" icon class="close-button" @click="close">
                <v-icon color="black">mdi-close</v-icon>
            </v-btn>
        </v-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isVisible: false,
            popupData: {
                username: '',
                profile_image: '',
                title: '',
                like_count: 0,
                bookmark_count: 0,
                image_src: '',
                prompt: '',
                parentId: '',
                // image: null,
            }
        };
    },
    props: {
        image: Object,
    },
    methods: {
        open(data) {
            this.popupData = data;
            this.isVisible = true;
        },
        close() {
            this.isVisible = false;
        },
        emitAction(type) {
            this.$emit('action-button', type, this.popupData);
        },
        copyToClipboard() {
            navigator.clipboard.writeText(this.popupData.prompt).then(() => {
                // alert('Prompt copied to clipboard!');
                this.$store.dispatch('showToast', {msg: '클립보드에 복사되었습니다.', duration: 1.5, color: 'red'});
            });
        }
    },
};
</script>

<style scoped>
.popup-dialog {
    background: rgba(0, 0, 0, 0.8);
}
.popup-card {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    box-shadow: none;
    border-radius: 20px !important;
    overflow: hidden;
}
.image-col {
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 700px;
}
.popup-image {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
}
.content-col {
    padding: 0;
}
.popup-content {
    height: 100%;
    padding: 20px;
    background-color: #333;
    border-radius: 0 16px 16px 0;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.popup-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.popup-info {
    flex-grow: 1;
    margin-left: 16px;
}
.username {
    font-weight: bold;
}
.title {
    color: white;
}
.like-bookmark {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.like-icon, .bookmark-icon {
    margin-right: 4px;
    background-color: #444;
    border-radius: 20px;
    padding: 8px;
}
.prompt-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.prompt-container {
    border: 1px solid #555;
    padding: 10px;
    border-radius: 8px;
    background: #2a2a2a;
}
.prompt {
    white-space: pre-wrap;
    word-wrap: break-word;
}
.copy-btn {
    background-color: #444;
    border-radius: 50%;
}
.popup-actions {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 40px);
    margin-top: auto;
    padding-top: 20px;
}
.download-btn {
    background-color: #444;
    max-width: 40px;
    border-radius: 5px;
    width: 10%;
    color: #fff;
}
.remix-btn {
    background-color: #6200ea;
    width: 90%;
    border-radius: 5px;
    color: #fff;
}
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1000;
    background-color: white;
    border-radius: 50%;
}
</style>
