<template>
    <v-container>
        <div style="display: flex; justify-content: space-between">
            <v-card-title style="color:#494D59;margin-left:-20px;" class="my-0 pb-0 pt-1">{{ title }}</v-card-title>
            <div class="custom-switch">
                <span class="custom-label">이미지 링크 입력</span>
                <v-switch
                    style="height:40px;"
                    density="compact"
                    class="py-0 my-0 custom-switch-input custom-switch"
                    v-model="uploadMethod"
                    :readonly="readOnly"
                    false-value="file"
                    color="#6D49FF"
                    value="url"
                ></v-switch>
            </div>
        </div>

        <div class="image-preview-container" v-if="images.length">
<!--            <div style="margin:0!important;" class="image-preview-row">-->
            <div class="image-preview-col" v-for="(image, index) in images" :key="index">
                <img :src="image" class="image-preview" />
                <div v-if="!readOnly" class="image-overlay">
                    <v-icon color="black" style="background-color: white; border-radius: 25%" @click="removeImage(index)" class="delete-icon">mdi-close</v-icon>
                </div>
            </div>
<!--            </>-->
        </div>

        <div v-if="uploadMethod === 'url'">
            <v-text-field
                class="mt-2"
                v-model="urlInput"
                label="이미지 URL 입력"
                @keydown.enter="addImageByUrl"
                placeholder="URL완성 후 Enter키를 입력 하세요."
            ></v-text-field>
        </div>

        <div v-if="uploadMethod === 'file'">
            <div
                class="file-upload-box"
                @click="triggerFileInput"
                @dragover.prevent="onDragOver"
                @dragleave.prevent="onDragLeave"
                @drop.prevent="onDrop"
            >
                <div
                    style="border-radius:7px; background-color:#23252C; display:flex; justify-content:center; align-items:center; width: 50px; height: 40px;"
                >
                    <v-icon color="#3D414C">mdi-plus</v-icon>
                </div>
                <div v-if="description && description !== ''">
                    <div class="mt-2"><span style="color: #494D59">{{description}}</span></div>
                </div>
                <template v-else>
                    <div class="mt-2"><span style="color: #494D59">여기로 이미지를 드래그 하거나</span></div>
                    <div><span style="color: #494D59">파일을 업로드하세요.</span></div>
                </template>
            </div>
            <input
                type="file"
                ref="fileInput"
                multiple
                style="display: none"
                @change="addImageByFile"
            />
        </div>
    </v-container>
</template>

<script>
export default {
    name: 'ImageUploader',
    data() {
        return {
            uploadMethod: 'file',
            urlInput: '',
            dragging: false
        };
    },
    props: {
        readOnly: Boolean,
        title: String,
        images: {
            type: Array,
            required: true
        },
        description: String,
    },
    methods: {
        addImageByUrl() {
            const urls = this.urlInput.split(/[, ]+/).filter(url => url);
            urls.forEach(url => {
                this.$emit('update:images', [...this.images, url]);
            });
            this.urlInput = '';
        },
        addImageByFile(event) {
            const files = event.target.files;
            this.handleFiles(files);
            this.$refs.fileInput.value = null; // Reset file input
        },
        triggerFileInput() {
            this.$refs.fileInput.click();
        },
        async handleFiles(files) {
            const imageUrls = await this.uploadFile(files);
            this.$emit('update:images', [...this.images, ...imageUrls]);
        },
        onDragOver() {
            this.dragging = true;
        },
        onDragLeave() {
            this.dragging = false;
        },
        onDrop(event) {
            this.dragging = false;
            const files = event.dataTransfer.files;
            this.handleFiles(files);
        },
        removeImage(index) {
            const newImages = [...this.images];
            newImages.splice(index, 1);
            this.$emit('update:images', newImages);
        },
        async uploadFile(files) {
            if (files.length > 0) {
                try {
                    const uploadPromises = [];
                    const imagesUploaded = [];

                    for (let i = 0; i < files.length; i++) {
                        const formData = new FormData();
                        formData.append('file', files[i]);

                        const uploadPromise = this.$store.dispatch('uploadImage', formData).then(response => {
                            imagesUploaded.push(response.data);
                        });
                        uploadPromises.push(uploadPromise);
                    }

                    await Promise.all(uploadPromises);
                    return imagesUploaded;
                } catch (error) {
                    console.error('업로드 중 오류 발생:', error);
                }
            }
        }
    }
};
</script>

<style>
.file-upload-box {
    display: flex;
    background-color: black;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    flex-direction: column;
    width: 100%;
    height: 150px;
    border: 0 solid #ccc;
    cursor: pointer;
    margin-top: 10px;
}
.file-upload-box span {
    font-size: 16px;
    color: #777;
}
.image-preview-container {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    width: 100%;
}
.image-preview-row {
    display: flex;
    flex-wrap: nowrap;
}
.image-preview-col {
    position: relative;
    display: inline-block;
    border-radius: 7px;
    width: 60px;
    height: 60px;
    margin: 0 5px;
    overflow: hidden;
}
.image-preview {
    width: 100%;
    height: 100%;
    border-radius: 7px;
    object-fit: cover;
}
.image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    opacity: 0;
    transition: opacity 0.3s;
}
.image-preview-col:hover .image-overlay {
    opacity: 1;
}
.delete-icon {
    color: black;
    cursor: pointer;
    font-size: 24px;
}

.custom-switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.custom-label {
    color: #494D59;
    margin-right: 16px; /* 레이블과 스위치 사이의 간격을 조절합니다 */
}

.custom-switch-input {
    order: 1; /* 스위치의 순서를 조정합니다 */
}
.custom-switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.custom-label {
    margin-right: 16px; /* 레이블과 스위치 사이의 간격을 조절합니다 */
}

.custom-switch-input {
    order: 1; /* 스위치의 순서를 조정합니다 */
}

.custom-switch .v-input--selection-controls__input:checked + .v-input--selection-controls__ripple .v-switch-core {
    background-color: #6D49FF !important;
}

.custom-switch .v-input--selection-controls__input:checked + .v-input--selection-controls__ripple .v-switch-thumb {
    background-color: white !important;
}

.file-upload-box.dragging {
    background-color: #444;
    border: 2px dashed #aaa;
}
</style>
