
<template>
    <v-dialog style="z-index: 9990" v-model="dialog" persistent max-width="342px">
        <v-card class="card-body">
<!--            <v-card-title style="background-color: #0d3a65; font-weight: bold; color: white;" class="text-h5">알림</v-card-title>-->
            <v-container fluid>
                <v-row class="py-0 my-0 mt-7">
                    <v-col style="display: flex;justify-content: center;" class="py-0 my-0">
<!--                        <v-icon class="alert-icon" color="white">mdi-alert-circle</v-icon>-->
                        <img v-if="welcomeFlag" class="alert-icon" src="welcome.png"/>
                        <img v-else class="alert-icon" src="alert-icon.png"/>
                    </v-col>
                </v-row>
                <v-row class="py-0 my-0">
                    <v-col style="display: flex;justify-content: center;" class="py-0 my-0">
                        <v-card-text v-html="message"></v-card-text>
                    </v-col>
                </v-row>
                <template v-if="welcomeFlag">
                    <v-row class="py-0 my-0 mt-2">
                        <v-col style="display: flex;justify-content: center;" class="py-0 my-0">
                            <v-btn height="45" style="text-align: center; font-size: 16px; text-transform: none"
                                   width="95%" color="#FEE500" @click="loginWithKakao"><img width="18px" height="18px" class="mr-1"
                                                                                            src="kakao_icon.png"/>카카오로 시작하기
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row class="py-0 my-0 mt-2">
                        <v-col style="display: flex;justify-content: center;" class="py-0 my-0">
                            <v-btn height="45" style="text-align: center; font-size: 16px; text-transform: none"
                                   width="95%" color="dimgrey" text @click="close">닫기</v-btn>
                        </v-col>
                    </v-row>
                </template>
                <template v-else>
                    <v-row class="py-0 my-0 mt-2">
                        <v-col style="display: flex;justify-content: center;" class="py-0 my-0">
                            <v-btn class="confirm-btn" color="#6D49FF" text @click="close">확인</v-btn>
                        </v-col>
                    </v-row>
                </template>
            </v-container>

        </v-card>
    </v-dialog>
</template>

<script>

export default {
    name: 'AlertDialog',
    data() {
        return {
            message: '',
            dialog: false,
            callback: null,
            welcomeFlag: false,
        };
    },
    computed:{
        oauthBaseUrl() {
            // return "http://localhost:8080/api";
            return "https://dorosi.ai/api";
        }
    },
    methods: {
        open(message, callback, welcomeFlag) {
            this.message = message;
            this.dialog = true;
            this.callback = callback;
            if(welcomeFlag) this.welcomeFlag = true;
        },
        loginWithKakao() {
            window.location.href = `${this.oauthBaseUrl}/oauth2/authorization/kakao`;
        },
        close() {
            this.dialog = false;
            if (this.callback) this.callback();
        }
    }
};
</script>

<style scoped>
.card-body {
    background-color: #2C2F37;
    border-radius: 20px!important;
}

.confirm-btn {
    background-color: #6D49FF;
    width: 100%;
    font-weight: bold;
}
</style>
