<template>
    <div style="justify-content: center;" v-masonry="0" transition-duration="0.3s" item-selector=".item" :style="{width: isFixedBanner ? '98%' : '90%'}">
        <div v-masonry-tile
             v-for="(item, index) in images"
             :key="index"
             @mouseover="highlightImage(item)"
             @mouseleave="unhighlightImage(item)"
             @click="selectImage(item)"
             :style="{cursor: 'pointer', width: isMobile ? '100%' : (isFixedBanner ? '46%' :  (isLeftPaneVisible ? '23%' : '23.5%')), backgroundColor: '#171717', overflow: 'hidden!important', borderRadius: '20px'}"
             :class="{ 'highlighted': item.isHighlighted, 'dimmed': isAnyImageHighlighted && !item.isHighlighted, 'item': true, 'ma-2': !isMobile, 'my-2': isMobile }"
        >
            <div style="border-radius: 20px; overflow: hidden; background-color: #171717; position: relative;">
                <v-card @click.stop="" v-if="promptShowing == item.url" width="90%" height="50%" min-height="150" style="opacity: 0.9; z-index: 2001; position: absolute; right: 0;">
                    <v-textarea
                        style="height: 80%;background-color: dimgrey"
                        density="compact"
                        v-model="item.prompt"
                        clear-icon="mdi-close-circle"
                        readonly
                        class="custom-textarea my-0 py-0 mx-3 mt-3"
                    ></v-textarea>
                </v-card>
                <image-wrapper :src="item.url" :alt="item.title" image-style="background-color: #171717; object-fit: cover; margin-bottom: 55px; max-width: 100%;"/>
                <div class="image-info-top" style="max-height: 250px;">
                    <div class="d-flex justify-start align-center" style="width: 100%;">
                        <template v-if="role === 'ADMIN'">
                            <v-tooltip v-if="currentTab===0" location="top" text="Featured 해제">
                                <template v-slot:activator="{ props }">
                                    <v-btn v-bind="props" density="compact" icon color="yellow" class="mx-1" @click.stop="removeFeatured(item)">
                                        <v-icon color="red">{{ 'mdi-delete' }}</v-icon>
                                    </v-btn>
                                </template>
                            </v-tooltip>
                            <v-tooltip v-if="currentTab===1" location="top" text="DummyLike">
                                <template v-slot:activator="{ props }">
                                    <v-btn v-bind="props" density="compact" icon color="yellow" class="mx-1" @click.stop="dummyLike(item)">
                                        <v-icon color="red">{{ 'mdi-heart' }}</v-icon>
                                    </v-btn>
                                </template>
                            </v-tooltip>
                            <v-tooltip v-if="currentTab===1" location="top" text="Featured">
                                <template v-slot:activator="{ props }">
                                    <v-btn v-bind="props" density="compact" icon color="yellow" class="mx-1" @click.stop="setFeatured(item)">
                                        <v-icon color="red">{{ 'mdi-star' }}</v-icon>
                                    </v-btn>
                                </template>
                            </v-tooltip>
                            <v-tooltip v-if="currentTab===1" location="top" text="Delete">
                                <template v-slot:activator="{ props }">
                                    <v-btn v-bind="props" density="compact" icon color="yellow" class="mx-1" @click.stop="removePost(item)">
                                        <v-icon color="red">{{ 'mdi-delete' }}</v-icon>
                                    </v-btn>
                                </template>
                            </v-tooltip>
                        </template>
                        <v-spacer/>
                        <v-btn color="#6D49FF" @click.stop="copy(item)" style="border-radius: 30px;">REMIX</v-btn>
                    </div>
                </div>
                <div class="image-info" style="max-height: 250px;">
                    <div class="d-flex justify-start align-center" style="width: 100%;">
                        <div class="text-container" style="display:flex; justify-content: center;width:100%;">
                            <v-spacer/>
                            <div>
                                <v-tooltip v-if="currentTab === 1 && item.mine" location="top" text="삭제">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" density="compact" icon color="white" class="mx-1" @click.stop="deleteMyPost(item.id)">
                                            <v-icon color="black">{{ 'mdi-trash-can' }}</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-tooltip location="top" text="다운로드">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" density="compact" icon color="white" class="mx-1" @click.stop="download(item.url)">
                                            <v-icon color="black">{{ 'mdi-download' }}</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-tooltip location="top" text="좋아요">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" density="compact" icon color="white" class="mx-1" @click.stop="toggleLike(item)">
                                            <v-icon color="black">{{ 'mdi-heart' }}</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-tooltip location="top" text="북마크">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" density="compact" icon color="white" class="mx-1" @click.stop="toggleBookmark(item)">
                                            <v-icon color="black">{{ 'mdi-bookmark' }}</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
<!--                                <v-tooltip location="top" :text="'리믹스'">-->
<!--                                    <template v-slot:activator="{ props }">-->
<!--                                        <v-btn v-bind="props" density="compact" class="mx-1" icon color="transparent" @click.stop="copy(item)">-->
<!--                                            <v-icon color="whitesmoke">mdi-creation</v-icon>-->
<!--                                        </v-btn>-->
<!--                                    </template>-->
<!--                                </v-tooltip>-->
                                <v-tooltip location="top" text="Prompt">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" density="compact" icon color="white" class="mx-1" @click.stop="showPrompt(item)">
                                            <v-icon color="black">{{ 'mdi-information' }}</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="author-info" style="z-index: 3000;">
                    <div class="d-flex justify-start align-center" style="width: 100%;">
                        <div class="text-container" style="display:flex; justify-content: center;width:100%;">
                            <div class="ml-2 font-weight-bold text-overflow py-0" :style="{color: 'whitesmoke', maxWidth: isFixedBanner ? '45%' : '70%', alignItems: 'center', display: 'flex'}"><profile-image-wrapper class="mr-2" image-style="background-color:white; border: solid whitesmoke 2px;border-radius:16px; color: black;width:32px;height: 32px;" :src="item.creatorImage" />{{ item.creator }}</div>
                            <v-spacer/>
                            <div class="pt-1">
                                <v-icon color="red">{{ item.liked ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon>
                                {{ item.likeCount }}
                                <v-icon color="purple">{{ item.bookmarked ? 'mdi-bookmark' : 'mdi-bookmark-outline' }}</v-icon>
                                {{ item.bookmarkCount }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
<!--        <div v-if="showPopup" class="overlay" @click="closePopup">-->
<!--            <div class="popup" @click.stop="">-->
<!--            </div>-->
<!--        </div>-->
        <image-detail-dialog ref="imageDetailDialog"/>
        <social-login-dialog ref="socialLoginDialog"/>
        <confirm-dialog ref="confirm"/>
        <image-post-detail-popup :image="selectedImage" @bookmark="toggleBookmark" @like="toggleLike" @action-button="actionButtonHandler" ref="postDetailPopup"/>
    </div>
</template>

<script>
import ProfileImageWrapper from "@/components/common/ProfileImageWrapper.vue";
import ImageWrapper from "@/components/common/ImageWrapper.vue";
import ImageDetailDialog from "@/dialogs/ImageDetailDialog.vue";
import moment from "moment/moment";
import {mapGetters} from "vuex";
import ImagePostDetailPopup from "@/components/image/ImagePostDetailPopup.vue";
import SocialLoginDialog from "@/dialogs/SocialLoginDialog.vue";
import ConfirmDialog from "@/dialogs/ConfirmDialog.vue";

export default {
    name: "MasonryViewer",
    components: {
        ConfirmDialog,
        SocialLoginDialog,
        ImagePostDetailPopup, ImageDetailDialog, ImageWrapper, ProfileImageWrapper},
    props: {
        redrawer: Number,
        id: Number,
        isLeftPaneVisible: Boolean,
        isFixedBanner: Boolean,
        isMobile: Boolean,
        images: Array,
    },
    data() {
        return {
            isAnyImageHighlighted: false,
            promptShowing: null,
            showPopup: false,
            selectedImage: null,
        };
    },
    mounted() {
        if (this.id === this.currentTab && typeof this.$redrawVueMasonry === 'function') this.$redrawVueMasonry();
    },
    watch: {
        redrawer() {
            if (this.id === this.currentTab && typeof this.$redrawVueMasonry === 'function') this.$redrawVueMasonry();
        }
    },
    computed: {
        ...mapGetters([
            'isAuthenticated',
            'userInfo',
            'darkMode',
            'newUser',
            'featured',
            'recent',
            'like',
            'canvases',
            'tabletEnd',
            'role',
            'searchKeyword',
            'profileImage',
            'reload',
            'currentTab',
            'fromCreator',
        ]),
    },
    methods: {
        highlightImage(image) {
            const { isMobile } = this;

            if(!isMobile){
                this.isAnyImageHighlighted = true;
                image.isHighlighted = true;
            }
        },
        unhighlightImage(image) {
            const { isMobile } = this;

            if(!isMobile){
                this.isAnyImageHighlighted = false;
                image.isHighlighted = false;
            }
        },
        selectImage(image) {
            // const { isMobile } = this;

            // if(!isMobile) this.$refs.imageDetailDialog.open(image);
            const popupData = {
                username: image.creator,
                profile_image: image.creatorImage,
                title: image.title,
                like_count: image.likeCount,
                bookmark_count: image.bookmarkCount,
                image_src: image.url,
                prompt: image.prompt,
                parentId: image.parentId,
            };

            this.selectedImage = image;
            this.$refs.postDetailPopup.open(popupData);
            this.showPopup = true;
        },
        toggleLike(image) {
            this.moveLoginIfRequired(async ()=>{
                if(image.liked) {
                    await this.$store.dispatch('likeImage', {id: image.id, unLike: true});
                    if(this.currentTab === 2) {
                        await this.$store.dispatch('clearImages');
                        this.visit = {init: true};
                        this.$store.dispatch('setReload', { cnt : this.reload + 1 } );
                        await this.fetchImages();
                    }
                }else {
                    await this.$store.dispatch('likeImage', {id: image.id});
                }
                if(typeof this.$redrawVueMasonry === 'function') {
                    this.$redrawVueMasonry();
                }
                this.isAnyImageHighlighted = false;
            });
        },
        toggleBookmark(image) {
            this.moveLoginIfRequired(async ()=>{
                if(image.bookmarked) {
                    await this.$store.dispatch('bookmarkImage', {id: image.id, unBookmark: true});
                    if(this.currentTab === 2) {
                        await this.$store.dispatch('clearImages');
                        this.visit = {init: true};
                        this.$store.dispatch('setReload', { cnt : this.reload + 1 } );
                        await this.fetchImages();
                    }
                }else {
                    await this.$store.dispatch('bookmarkImage', {id: image.id});
                }
                if(typeof this.$redrawVueMasonry === 'function') {
                    this.$redrawVueMasonry();
                }
                this.isAnyImageHighlighted = false;
            });
        },
        copy(image) {
            this.moveLoginIfRequired(async ()=>{
                this.moveStoreIfRequired(()=>{
                    this.$store.dispatch('setApplyPrompt', {image});
                    // this.$router.push({name: 'ImageCreator', query: {id: image.parentId, command: 'copy', url: image.url}});
                });
            });
        },
        moveCreation() {
            this.moveLoginIfRequired(async () => {
                this.moveStoreIfRequired(() => {
                    this.$router.push({name: 'ImageCreator'});
                });
            });
        },
        moveLoginIfRequired(block) {
            const { isAuthenticated } = this;
            if(!isAuthenticated) {
                this.$refs.socialLoginDialog.open();
            } else {
                if(block) block();
            }
        },
        moveStoreIfRequired(block) {
            const { canvases } = this;
            if(canvases <= 0 && !this.isDateValidAndFuture(this.tabletEnd)) {
                this.$refs.confirm.open(`캔버스가 부족해요. 캔버스를 구매하러 이동하시겠어요?`, () => {
                    this.$router.push({name: 'CanvasStore'})
                });
            } else {
                if(block) block();
            }
        },
        isDateValidAndFuture(date) {
            if(!date) return false;

            const today = moment().startOf('day'); // 오늘 날짜를 'yyyy-MM-dd' 형식으로 가져옴, 시간은 00:00으로 설정
            const inputDate = moment(date, 'YYYY-MM-DD'); // 입력된 날짜를 moment 객체로 변환

            return inputDate.isSameOrAfter(today); // 입력된 날짜가 오늘 또는 미래 날짜인지 확인
        },
        // onResize() {
        //     if(window.innerWidth < 1390) this.isFixedBanner = true;
        //     else this.isFixedBanner = false;
        //
        //     if(window.innerWidth < 640) this.isMobile = true;
        //     else this.isMobile = false;
        // },
        async download(url) {
            // console.log(url);
            // this.$store.dispatch('showToast', {msg: '안내 메세지입니다. 안녕하세요? hello hellohellohellohello', duration: 1.5, color: 'red'});
            this.$store.dispatch('startLoading');
            const id = url.split('/').pop().replace('.webp', '.png');
            const response = await fetch(url.replace('.webp', '.png'));
            const blob = await response.blob();
            const u = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = u;
            link.setAttribute('download', id); // 다운로드할 파일명 지정
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.isAnyImageHighlighted = false;
            this.$store.dispatch('stopLoading');
        },
        async deleteMyPost(id) {
            this.$refs.confirm.open('삭제할까요?', async ()=>{
                await this.$store.dispatch('deleteMyPost', {id});
                await this.$store.dispatch('clearImages');
                await this.$store.dispatch('fetchImages', {init: true, findType: 'RECENT'});
                this.visit = { init: true };
                if(typeof this.$redrawVueMasonry === 'function') this.$redrawVueMasonry();

                setTimeout(()=>{
                    this.scrollToTop();
                }, 500);
            });
        },
        async fetchImages() {
            const { searchKeyword , currentTab , tabs} = this;
            const findType = (currentTab && tabs) ? tabs[currentTab].value : 'FEATURED'

            this.initFlags[findType] = true;
            await this.$store.dispatch('fetchImages', {findType, init: true, searchKeyword});
            this.visit = {init: true};
        },
        format(d){
            const parts = d.toString().split(",");
            const year = parts[0];
            const month = parts[1].padStart(2, '0'); // 월을 두 자릿수로 만듦
            const day = parts[2].padStart(2, '0'); // 일을 두 자릿수로 만듦

            return `${year}-${month}-${day}`;
        },
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        async removePost(image) {
            this.$refs.confirm.open('삭제할까요?', async ()=>{
                await this.$store.dispatch('deletePost', {id: image.id});
                await this.$store.dispatch('clearImages');
                await this.$store.dispatch('fetchImages', {init: true, findType: 'RECENT'});
                this.visit['init'] = true;
                this.$store.dispatch('setReload', { cnt : this.reload + 1 } );
                if(typeof this.$redrawVueMasonry === 'function') this.$redrawVueMasonry();

                setTimeout(()=>{
                    this.scrollToTop();
                }, 500);
            });
        },
        async removeFeatured(image) {
            this.$refs.confirm.open('Featured를 해제할까요?', async ()=>{
                await this.$store.dispatch('setFeatured', {id: image.id, flag: 'N'});
                await this.$store.dispatch('clearImages');
                await this.$store.dispatch('fetchImages', {init: true, findType: 'FEATURED'});
                this.visit['init'] = true;
                this.$store.dispatch('setReload', { cnt : this.reload + 1 } );
                if(typeof this.$redrawVueMasonry === 'function') this.$redrawVueMasonry();

                setTimeout(()=>{
                    this.scrollToTop();
                }, 500);
            });
        },
        async setFeatured(image) {
            this.$refs.confirm.open('Featured로 설정할까요?', async ()=>{
                await this.$store.dispatch('setFeatured', {id: image.id, flag: 'Y'});
                await this.$store.dispatch('clearImages');
                await this.$store.dispatch('fetchImages', {init: true, findType: 'RECENT'});
                this.visit['init'] = true;
                this.$store.dispatch('setReload', { cnt : this.reload + 1 } );
                if(typeof this.$redrawVueMasonry === 'function') this.$redrawVueMasonry();
            });
        },
        async dummyLike(image) {
            await this.$store.dispatch('dummyLikeImage', {id: image.id});
        },
        remix(image) {
          alert(image);
        },
        loadMore() {
            const {images} = this;
            const lastItem = images[images.length - 1];

            if (lastItem && this.visit[lastItem.id]) return;
            if (!lastItem && this.visit['init']) return;

            if (lastItem) this.visit[lastItem.id] = true;
            else this.visit['init'] = true;

            const {currentTab, tabs, searchKeyword} = this;
            const findType = (currentTab && tabs) ? tabs[currentTab].value : 'FEATURED'
            this.$store.dispatch('fetchImages', {
                init: false,
                offset: lastItem ? lastItem.id : null,
                findType,
                searchKeyword
            });
        },
        showPrompt(image) {
            if(this.promptShowing === image.url) this.promptShowing = null;
            else this.promptShowing = image.url;
        },
        closePopup() {
            this.showPopup = false;
        },
        actionButtonHandler(type, popupData) {
            switch(type) {
                case 'download' : this.download(popupData.image_src); break;
                case 'remix' : this.copy({parentId: popupData.parentId}); this.$refs.postDetailPopup.close(); break;
            }
        },
    }
}
</script>

<style scoped>
.dimmed {
    opacity: 0.5;
}

.image-info {
    position: absolute;
    bottom: 60px;
    left: 0;
    right: 0;
    background-color: transparent;
    color: #ffffff;
    padding: 10px;
    display: none;
    flex-direction: column;
    overflow: hidden;
}

.author-info {
    background-color: #282828;
    z-index: 3000;
    height: 55px;
    color: #ffffff;
    padding: 10px;
    flex-direction: column;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.item:hover .image-info {
    display: block;
}

.image-info-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    color: #ffffff;
    padding: 10px;
    display: none;
    flex-direction: column;
    overflow: hidden;
}

.item:hover .image-info-top {
    display: block;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 4000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup {
    position: relative;
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    z-index: 4001;
}

@media (max-width: 1200px) {
    .image-info-top {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background-color: transparent;
        color: #ffffff;
        padding: 10px;
        display: block;
        flex-direction: column;
        overflow: hidden;
    }
    .image-info {
        position: absolute;
        bottom: 55px;
        left: 0;
        right: 0;
        background-color: transparent;
        color: #ffffff;
        padding: 10px;
        display: block;
        flex-direction: column;
        overflow: hidden;
    }
}

@media (max-width: 640px) {
    .image-info-top {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background-color: transparent;
        color: #ffffff;
        padding: 10px;
        display: block;
        flex-direction: column;
        overflow: hidden;
    }
    .image-info {
        position: absolute;
        bottom: 55px;
        left: 0;
        right: 0;
        background-color: transparent;
        color: #ffffff;
        padding: 10px;
        display: block;
        flex-direction: column;
        overflow: hidden;
    }
}

.text-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
