<script>
export default {
    props:{text:String},
    name: "ProgressingImageBox"
}
</script>
<!--fetchSlideShows-->
<template>
    <div>
        <div class="message-container">
            <div class="progress-bar-container">
                <div class="progress-bar px-5">
                    <div class="progress-segment"></div>
                    <div class="progress-segment"></div>
                    <div class="progress-segment"></div>
                    <div class="progress-segment"></div>
                    <div class="progress-segment"></div>
                    <!-- 필요한 만큼 세그먼트 추가 -->
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.progress-bar-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.progress-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 350px; /* 프로그레스 바 너비 */
    height: 20px; /* 프로그레스 바 높이 */
}

.progress-segment {
    width: 50px; /* 각 세그먼트의 너비 */
    height: 100%;
    border-radius: 10px;
    background-color: #0db4c4; /* 세그먼트 기본 배경색 */
    opacity: 0.3; /* 시작 시 세그먼트 투명도 */
    animation: lightUp 1s infinite; /* 애니메이션 적용 */
}

/* 각 세그먼트의 애니메이션 딜레이 조정 */
.progress-bar .progress-segment:nth-child(1) { animation-delay: 0s; }
.progress-bar .progress-segment:nth-child(2) { animation-delay: 0.2s; }
.progress-bar .progress-segment:nth-child(3) { animation-delay: 0.4s; }
.progress-bar .progress-segment:nth-child(4) { animation-delay: 0.6s; }
.progress-bar .progress-segment:nth-child(5) { animation-delay: 0.8s; }
/* 필요한 만큼 딜레이 추가 */

@keyframes lightUp {
    0%, 100% { opacity: 0.3; } /* 시작과 끝 투명도 */
    50% { opacity: 1; } /* 중간 지점에서 세그먼트 불이 최대로 밝음 */
}

.message-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: 'Noto Sans', sans-serif;
    color: #4A4A4A; /* 고급스러운 다크 그레이 색상 */
    background-color: transparent; /* 부드러운 배경색 */
    padding: 20px;
    border-radius: 10px; /* 둥근 모서리 */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 부드러운 그림자 효과 */
    width: fit-content;
    margin: auto;
}

.emoji {
    font-size: 24px; /* 이모지 크기 */
}

.message {
    font-weight: bold;
    margin: 10px 0; /* 멘트의 상하 여백 */
    font-size: 18px; /* 텍스트 크기 */
    color: #333; /* 텍스트 색상 */
}

</style>
