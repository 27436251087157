import {createApp} from 'vue'
import {createPinia} from 'pinia'
import {loadFonts} from './plugins/webfontloader'
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import mitt from 'mitt'
import {VueMasonryPlugin} from 'vue-masonry';

loadFonts().then(r => console.log(`${r} font loaded.`));
const emitter = mitt()

const app = createApp(App)
    .use(createPinia())
    .use(vuetify)
    .use(store)
    .use(router)
    .use(VueMasonryPlugin);

app.config.globalProperties.emitter = emitter

app.config.globalProperties.$axios = axios;
app.mount('#app');
