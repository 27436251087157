<template>
    <div class="content" @click="$store.dispatch('setShowMyInfo', {flag: false});$store.dispatch('setShowSupportInfo', {flag: false})">
        <div ref="scrollContainer" :style="{height: 'calc(100% - 60px)', overflowY: 'auto', backgroundColor: '#171717'}" @click="$store.dispatch('setShowMyInfo', {flag: false});$store.dispatch('setShowSupportInfo', {flag: false})">
            <template v-if="images && images.length > 0">
                <div :class="{'pl-3': !isMobile, 'pl-10': !isLeftPaneVisible }" v-masonry="0" transition-duration="0.3s" item-selector=".item" style="width:100%;">
                    <div v-masonry-tile
                         v-for="(item, index) in images"
                         :key="index"
                         @click="selectImage(item)"
                         @mouseover="highlightImage(item)"
                         @mouseleave="unhighlightImage(item)"
                         :style="{cursor: 'pointer', width: isMobile ? '40%' : (isFixedBanner ? '45%' : (isLeftPaneVisible ? '155px' : '177px')), overflow: 'hidden!important', borderRadius: '5px'}"
                         :class="{ 'highlighted': item.isHighlighted, 'dimmed': isAnyImageHighlighted && !item.isHighlighted, 'item': true, 'ma-1': true, 'mx-1': true, 'my-1': true }"
                    >
                        <!-- block item markup -->
                        <div style="border: solid #918d8d 1px; border-radius: 5px; overflow: hidden; background-color: #171717;">
                            <image-wrapper v-if="item.url && item.url.length > 0" :src="item.url" image-style="object-fit: cover; width: 100%; max-width: 100%;"/>
                            <div v-else class="processing-container" :style="{ backgroundImage: 'url(processing.webp)', width: '100%' }">
                                <div class="loading-overlay">
                                    <span class="loading-text">이미지 생성중…</span>
                                </div>
                            </div>
<!--                            <div class="image-info pt-0" style="max-height: 45px;">-->
<!--                                <div style="height: 100%;" class="pt-0 my-0 d-flex justify-space-between align-center">-->
<!--                                    <v-col class="my-0 py-0 ml-3" style="display:flex;justify-content: end;">-->
<!--                                        <v-tooltip text="지우기">-->
<!--                                            <template v-slot:activator="{ props }">-->
<!--                                                <v-btn density="compact" color="white" icon v-bind="props" class="ma-2" @click.stop="remove(item)">-->
<!--                                                    <v-icon color="red">mdi-trash-can</v-icon>-->
<!--                                                </v-btn>-->
<!--                                            </template>-->
<!--                                        </v-tooltip>-->
<!--                                    </v-col>-->
<!--                                </div>-->
<!--                            </div>-->
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div style="display: flex; width: 100%; min-width: 330px; justify-content: center;" class="mt-5">
                    <div v-if="reload <= 10" :style="{backgroundColor: 'whitesmoke', display: 'flex', justifyContent: 'center', alignItems: 'center', border: 'dashed 3px lightgrey', borderRadius: '50px', flexDirection: 'column', justifyContent: 'center',alignItems: 'center', width: isFixedBanner ? '80%' : '50%', height: isFixedBanner ? '500px' : '700px'}">
                        <v-card-title style="color: orangered; font-weight: bold;">지금은 보여줄게 없어요 😉</v-card-title>
                        <div>
                            <img :style="`height: ${isMobile ? '250px' : '350px'}; width: ${isMobile ? '250px' : '350px'}; border: solid antiquewhite 10px; border-radius: 100px; object-fit: contain;`" src="character1.webp">
                        </div>
                    </div>
                    <div v-else style="width: 100%;height: 350px; background-color: #171717"/>
                </div>
            </template>
        </div>
        <confirm-dialog ref="confirm"/>
        <image-post-detail-popup :image="selectedImage" @bookmark="toggleBookmark" @like="toggleLike" @action-button="actionButtonHandler" ref="postDetailPopup"/>
        <social-login-dialog ref="socialLoginDialog"/>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import ConfirmDialog from "@/dialogs/ConfirmDialog.vue";
import SocialLoginDialog from "@/dialogs/SocialLoginDialog.vue";
import moment from "moment";
import ImageWrapper from "@/components/common/ImageWrapper.vue";
import ImagePostDetailPopup from "@/components/image/ImagePostDetailPopup.vue";

export default {
    name: 'MyHome',
    components: {ImageWrapper, SocialLoginDialog, ConfirmDialog , ImagePostDetailPopup },
    props: { isLeftPaneVisible: Boolean, isFixedBanner: Boolean, isMobile: Boolean },
    data() {
        return {
            selectedImage: null,
            currentTab: null,
            tabs: ['Featured', 'Hot', 'Recent', 'Like'],
            isAnyImageHighlighted: false,
            visit: {},
            showIconOnly: false,
            showScrollToTop: false,
            showText: true,
            baseUrl: 'https://dorosi.ai/image/',
            scrollContainer: null,
            initFlags: {},
            // focusedImage: null,
            activeIcon: 0,
            rightIcons: ['mdi-shape-plus', 'mdi-heart'],
        };
    },
    watch: {
        isLeftPaneVisible() {
            setTimeout(() => {
                if (typeof this.$redrawVueMasonry === 'function') this.$redrawVueMasonry();
            }, 500);
        },
        statusCheck(v) {
            if (v && v.url && v.url !== '') {
                // this.focusedImage = { src: v.url && v.url !== '' ? v.url : 'processing.webp', prompt: v.prompt };
                this.$store.dispatch('setFocusedImage', {image: { src: v.url && v.url !== '' ? v.url : 'processing.webp', prompt: v.prompt }})
                this.activeIcon = 1;
            } else if (this.activeIcon === 1) {
                // this.focusedImage = this.images[0];
                this.$store.dispatch('setFocusedImage', {image: this.myImages[0].images[0]})
            }
        },
    },
    computed: {
        ...mapGetters([
            'bookmark',
            'recent',
            'canvases',
            'isAuthenticated',
            'reload',
            'fromCreator',
            'anyInProgress',
            'tabletEnd',
            'statusCheck',
            'focusedImage',
        ]),
        images() {
            return this.bookmark;
        },
    },
    async mounted() {
        const { reload } = this;
        await this.$store.dispatch('setReload', { cnt: (reload + 1) || 1 });

        this.visit = { init: true };

        await this.fetchImages();

        //
        // await nextTick();
        this.scrollContainer = this.$refs.scrollContainer;

        if (this.scrollContainer) {
            this.scrollContainer.addEventListener('scroll', this.handleScroll);
        }
        if (typeof this.$redrawVueMasonry === 'function') this.$redrawVueMasonry();
    },
    beforeUnmount() {
        if (this.scrollContainer) {
            this.scrollContainer.removeEventListener('scroll', this.handleScroll);
        }
        if (this.horizontalScrollContainer) {
            this.horizontalScrollContainer.removeEventListener('wheel', this.handleHorizontalScroll);
        }
    },
    methods: {
        setActiveIcon(index) {
            if (this.activeIcon !== index) {
                if (this.focusedImage) {
                    this.activeIcon = index;

                    if (index === 0 && typeof this.$redrawVueMasonry === 'function') this.$redrawVueMasonry();
                }
            }
        },
        highlightImage(image) {
            this.isAnyImageHighlighted = true;
            image.isHighlighted = true;
        },
        unhighlightImage(image) {
            this.isAnyImageHighlighted = false;
            image.isHighlighted = false;
        },
        async fetchImages() {
            const { searchKeyword } = this;
            const findType = 'BOOKMARK';

            this.initFlags[findType] = true;
            await this.$store.dispatch('fetchImages', {findType, init: true, searchKeyword});
            this.visit = {init: true};
        },
        async remove(image) {
            this.$refs.confirm.open('삭제할까요? 삭제하면 되돌릴 수 없어요!', async () => {
                await this.$store.dispatch('deleteImage', { id: image.id });
                await this.$store.dispatch('clearImages');
                await this.$store.dispatch('fetchMyImages', { init: true });
                this.visit = { init: true };
                this.$store.dispatch('setReload', { cnt: this.reload + 1 });
                if (typeof this.$redrawVueMasonry === 'function') this.$redrawVueMasonry();

                setTimeout(() => {
                    this.scrollToTop();
                }, 500);
            });
        },
        handleScroll() {
            const scrollContainer = this.scrollContainer;
            const scrollTop = scrollContainer.scrollTop;
            const scrollHeight = scrollContainer.scrollHeight;
            const clientHeight = scrollContainer.clientHeight;

            const bottomOfWindow = scrollTop + clientHeight >= scrollHeight - 200;

            if (bottomOfWindow) {
                // this.loadMore();
            }

            this.showText = scrollTop <= 50;
            this.showScrollToTop = scrollTop > 200;
        },
        handleHorizontalScroll(event) {
            event.preventDefault();
            this.horizontalScrollContainer.scrollLeft += event.deltaY;
        },
        loadMore() {
            const { myImages } = this;
            const lastGroup = myImages[myImages.length - 1];
            const lastItem = lastGroup.images[lastGroup.images.length - 1];
            if (lastItem && this.visit[lastItem.imageId]) return;
            if (!lastItem && this.visit['init']) return;

            if (lastItem) this.visit[lastItem.imageId] = true;
            else this.visit['init'] = true;

            this.$store.dispatch('fetchMyImages', { init: false, offset: lastItem ? lastItem.imageId : null });
        },
        format(d) {
            const parts = d.toString().split(",");
            const year = parts[0];
            const month = parts[1].padStart(2, '0');
            const day = parts[2].padStart(2, '0');

            return `${year}-${month}-${day}`;
        },
        moveCreation() {
            this.moveLoginIfRequired(async () => {
                this.moveStoreIfRequired(() => {
                    this.$router.push({ name: 'ImageCreator' });
                });
            });
        },
        moveLoginIfRequired(block) {
            const { isAuthenticated } = this;
            if (!isAuthenticated) {
                this.$refs.socialLoginDialog.open();
            } else {
                if (block) block();
            }
        },
        moveStoreIfRequired(block) {
            const { canvases } = this;
            if (canvases <= 0 && !this.isDateValidAndFuture(this.tabletEnd)) {
                this.$refs.confirm.open(`캔버스가 부족해요. 캔버스를 구매하러 이동하시겠어요?`, () => {
                    this.$router.push({ name: 'CanvasStore' })
                });
            } else {
                if (block) block();
            }
        },
        isDateValidAndFuture(date) {
            if (!date) return false;

            const today = moment().startOf('day');
            const inputDate = moment(date, 'YYYY-MM-DD');

            return inputDate.isSameOrAfter(today);
        },
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        getTimeAgo(dateString) {
            const today = new Date();
            const compareDate = new Date(dateString);
            compareDate.setHours(0, 0, 0, 0);
            today.setHours(0, 0, 0, 0);

            const msPerDay = 24 * 60 * 60 * 1000;
            const dayDifference = Math.round((today - compareDate) / msPerDay);

            if (dayDifference === 1) {
                return '어제';
            } else if (dayDifference > 1 && dayDifference <= 6) {
                return `${dayDifference}일 전`;
            } else if (dayDifference >= 7 && dayDifference < 30) {
                return `${Math.floor(dayDifference / 7)}주 전`;
            } else if (dayDifference >= 30 && dayDifference < 365) {
                return `${Math.floor(dayDifference / 30)}달 전`;
            } else if (dayDifference >= 365) {
                return `${Math.floor(dayDifference / 365)}년 전`;
            } else {
                return '오늘';
            }
        },
        downloadImage(image) {
            alert(image);
        },
        remixImage(image) {
            this.$emit('remixImage', image);
        },
        postImage(image) {
            alert(image);
        },
        selectImage(image) {
            // const { isMobile } = this;

            // if(!isMobile) this.$refs.imageDetailDialog.open(image);
            const popupData = {
                username: image.creator,
                profile_image: image.creatorImage,
                title: image.title,
                like_count: image.likeCount,
                bookmark_count: image.bookmarkCount,
                image_src: image.url,
                prompt: image.prompt,
                parentId: image.parentId,
            };

            this.selectedImage = image;
            this.$refs.postDetailPopup.open(popupData);
            this.showPopup = true;
        },
        toggleLike(image) {
            this.moveLoginIfRequired(async ()=>{
                if(image.liked) {
                    await this.$store.dispatch('likeImage', {id: image.id, unLike: true});
                    if(this.currentTab === 2) {
                        await this.$store.dispatch('clearImages');
                        this.visit = {init: true};
                        this.$store.dispatch('setReload', { cnt : this.reload + 1 } );
                        await this.fetchImages();
                    }
                }else {
                    await this.$store.dispatch('likeImage', {id: image.id});
                }
                if(typeof this.$redrawVueMasonry === 'function') {
                    this.$redrawVueMasonry();
                }
                this.isAnyImageHighlighted = false;
            });
        },
        toggleBookmark(image) {
            this.moveLoginIfRequired(async ()=>{
                if(image.bookmarked) {
                    await this.$store.dispatch('bookmarkImage', {id: image.id, unBookmark: true});
                    if(this.currentTab === 2) {
                        await this.$store.dispatch('clearImages');
                        this.visit = {init: true};
                        this.$store.dispatch('setReload', { cnt : this.reload + 1 } );
                        await this.fetchImages();
                    }
                }else {
                    await this.$store.dispatch('bookmarkImage', {id: image.id});
                }
                if(typeof this.$redrawVueMasonry === 'function') {
                    this.$redrawVueMasonry();
                }
                this.isAnyImageHighlighted = false;
            });
        },
        actionButtonHandler(type, popupData) {
            switch(type) {
                case 'download' : this.download(popupData.image_src); break;
                case 'remix' : this.copy({parentId: popupData.parentId}); this.$refs.postDetailPopup.close(); break;
            }
        },
        copy(image) {
            this.moveLoginIfRequired(async ()=>{
                this.moveStoreIfRequired(()=>{
                    this.$store.dispatch('setApplyPrompt', {image});
                    // this.$router.push({name: 'ImageCreator', query: {id: image.parentId, command: 'copy', url: image.url}});
                });
            });
        },
        async download(url) {
            // console.log(url);
            // this.$store.dispatch('showToast', {msg: '안내 메세지입니다. 안녕하세요? hello hellohellohellohello', duration: 1.5, color: 'red'});
            this.$store.dispatch('startLoading');
            const id = url.split('/').pop().replace('.webp', '.png');
            const response = await fetch(url.replace('.webp', '.png'));
            const blob = await response.blob();
            const u = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = u;
            link.setAttribute('download', id); // 다운로드할 파일명 지정
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.isAnyImageHighlighted = false;
            this.$store.dispatch('stopLoading');
        },
    },
};
</script>

<style scoped>
.no-focus {
    outline: none !important;
    pointer-events: none;
    user-select: none!important;
}

.no-drag {
    -webkit-user-drag: none;
    user-drag: none;
}
.dimmed {
    opacity: 0.5;
}

.image-item img {
    width: 100%;
    transition: transform 0.3s ease;
}

.image-info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(30, 34, 58, 0.7);
    color: #343438;
    padding: 10px;
    display: none;
    flex-direction: column;
    overflow: hidden;
}

.item:hover .image-info {
    display: block;
}
@media (max-width: 1200px) {
    .image-info {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        color: #ffffff;
        padding: 10px;
        display: block;
        flex-direction: column;
        overflow: hidden;
    }
}
@media (max-width: 640px) {
    .image-info {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        color: #ffffff;
        padding: 10px;
        display: block;
        flex-direction: column;
        overflow: hidden;
    }
}
.fixed-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 199;
    border: none;
    outline: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    padding: 15px;
    border-radius: 10px;
    font-size: 18px;
}
.processing-container {
    position: relative;
    width: 100%;
    height: 200px;
    background-size: cover;
    background-position: center;
}

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-text {
    color: white;
    font-size: 24px;
    font-weight: bold;
}

.btn-expanded {
    transition: width 0.5s ease;
    width: 130px;
}

.btn-zipped {
    width: 55px;
    overflow: hidden;
    transition: width 0.5s ease;
}
/* 텍스트 페이드 인/아웃 애니메이션 */
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter-from, .fade-leave-to {
    opacity: 0;
}

.tabs-container {
    margin-top: 2rem;
}
.tab-btn {
    margin: 0;
    padding: 0.2rem;
    color: #595b5e;
    background-color: transparent;
    font-weight: bold;
    border: none;
    min-width: unset;
    min-height: unset;
    text-transform: none;
}
.tab-btn:not(.active-icon):hover {
    background-color: transparent !important;
}
.active-icon {
    background-color: #4c3c7c !important;
    color: whitesmoke !important;
    font-weight: bold;
}
.options-bar {
    padding: 16px;
}
.my-textarea {
    background-color: #f9f9f9;
    border-radius: 8px;
}
.image-scroll {
    width: 100%;
    height: 150px;
    overflow-x: auto;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
    transition: margin-left 0.3s ease-in-out;
}
.show-left-area {
    margin-left: 430px; /* 왼쪽에 열려있는 영역만큼 띄움 */
}
.image-wrapper {
    flex: 0 0 auto;
    margin-right: 2px; /* 이미지 간 간격 조정 */
}
.thumbnail {
    width: 100px;
    height: 100px;
    border-radius: 8px;
    cursor: pointer;
}
.thumbnail-focused {
    width: 120px;
    height: 120px;
    border-radius: 8px;
    cursor: pointer;
}
.spacer {
    flex: 0 0 5px; /* 왼쪽 영역 크기만큼 빈 공간 추가 */
}
.slide-enter-active, .slide-leave-active {
    transition: padding-left 0.3s ease-in-out;
}
.content {
    height: 100%;
    overflow-y: auto;
}
.custom-textarea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 200px; /* v-textarea의 높이와 동일하게 설정 */
}
.custom-textarea .v-input__control {
    padding-top: 0;
    padding-bottom: 0;
    line-height: normal;
}
</style>
