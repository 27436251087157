<template>
    <!--    <v-col cols="12" md="4">-->
    <v-card class="mx-0 my-5" height="200" min-width="250" :style="{overflow: 'visible', backgroundColor: (selected ? 'antiquewhite' : '#F7F8FA'), borderRadius: '30px', border: (selected ? 'solid grey 5px' : '')}">
        <img v-if="ticket.best" style="width:100px;height:auto;position: absolute; left:-40px; top:-40px;" src="best.webp" >
        <img v-else-if="ticket.best2" style="width:100px;height:auto;position: absolute; left:-40px; top:-40px;" src="best2.webp" >
        <v-row class="py-0 my-0 px-0 mx-0" style="height: 100%;">
            <v-col :class="{'px-0': isMobile, 'mx-0': isMobile}" cols="7">
                <v-card-title style="color: #131419; font-weight: bold; font-size: 18px;">{{ticket.title}}</v-card-title>
                <v-card-text :style="{fontSize}">
                    <div v-if="ticket.originalPrice" :style="{fontSize}" class="mb-1">
<!--                        <template v-if="isSmallestScreen">-->
<!--                            <span style="color:dimgrey;" class="text-decoration-line-through">{{ ticket.originalPrice.toLocaleString() }}원</span><br/>-->
<!--                            <span style="color:navy; font-weight: bold;">{{ ticket.price.toLocaleString() }}원 {{ticket.discount ? `(${ticket.discount} 할인)` : ''}}</span>-->
<!--                        </template>-->
<!--                        <template v-else>-->
                        <span style="color: #131419; font-weight: bold; font-size: 21px;">캔버스 {{ ticket.quantity}}장</span> <span style="color:#6D49FF; font-weight: bold; font-size: 17px;">+{{ ticket.bonus }}장 추가 지급</span>
<!--                        </template>-->
                    </div>
<!--                    <div v-else class="mb-1"><span style="color:navy; font-weight: bold;">{{ ticket.price.toLocaleString() }}원</span></div>-->
<!--                    <div v-if="ticket.bonus"><span style="color:orangered; font-weight: bold;"><v-icon size="small" color="white" class="mx-1 mb-1" style="background-color: darkorange; border-radius: 50%;">mdi-plus</v-icon>{{ `${ticket.bonus}장 추가 지급` }}</span></div>-->
<!--                    <template v-if="ticket.period">-->
<!--                        <div><span style="color: black; font-weight: bold;"><v-icon size="small" color="white" class="ml-2 mx-1 mb-1" style="background-color: dodgerblue; border-radius: 50%;">mdi-equal</v-icon>{{ '1개월간 무제한 생성'}}</span></div>-->
<!--                        <div class="pl-2"><span style="color: orangered; font-weight: bold;">{{`~${endDate}까지` }}</span></div>-->
<!--                        <div class="pl-2"><span style="color:royalblue; font-weight: bold;"><v-icon size="small" color="white" class="mx-1 mb-1" style="background-color: royalblue; border-radius: 50%;">mdi-plus</v-icon>{{ `캔버스 100장 추가 지급` }}</span></div>-->
<!--                    </template>-->
<!--                    <template v-else>-->
<!--                        <div><span style="color: black; font-weight: bold;"><v-icon size="small" color="white" class="ml-2 mx-1 mb-1" style="background-color: dodgerblue; border-radius: 50%;">mdi-equal</v-icon>{{ '캔버스 총 '}}</span><span class="font-weight-bold" :style="{fontSize: isMobile ? '15px' : '20px', color: 'black'}">{{ ticket.total }}장</span></div>-->
<!--                        <div class="pl-2"><span style="color: royalblue; font-weight: bold;">{{` (이미지 1장 : ${ticket.singlePrice}원)` }}</span></div>-->
<!--                    </template>-->
                </v-card-text>
            </v-col>
            <v-spacer/>
            <v-col style="height: 100%; display: flex; border-radius: 10px; justify-content: center; align-items: center;" cols="5">
                <div style="width: 100%;height: 100%; display: flex; flex-direction: column; object-fit: cover; justify-content: end; align-items: end; border-radius: 10px;">
                    <div v-if="ticket.originalPrice" :style="{fontSize}" class="mb-1">
                        <span style="color: #C2C4CC; font-weight: bold; font-size: 19px;" class="text-decoration-line-through">{{ ticket.originalPrice}}원</span>
                    </div>
                    <div v-if="ticket.originalPrice" :style="{fontSize}" class="mb-1">
                        <span style="color: #6D49FF; font-weight: bold; font-size: 22px;" class="text-decoration-line-through">{{ ticket.discount}}</span> <span style="color:#131419; font-weight: bold; font-size: 22px;">{{ ticket.price }}원</span>
                    </div>
                    <div v-if="ticket.originalPrice" :style="{fontSize}" class="mb-1">
                        <span style="color: #C2C4CC; font-weight: bold; font-size: 17px;">이미지 1장 : {{ ticket.singlePrice}}원</span>
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-card>
    <!--    </v-col>-->
</template>

<script>
export default {
    props: {
        ticket: Object,
        selectedTicket: Object,
        isMobile: Boolean,
        isSmallestScreen: Boolean,
    },
    computed: {
        selected() {
            const {selectedTicket, ticket} = this;
            return ticket && selectedTicket && ticket.id === selectedTicket.id;
        },
        fontSize() {
            const { isMobile , isSmallestScreen } = this;
            if(isSmallestScreen) return '11px';
            return isMobile ? '15px' : '19px';
        },
        endDate() {
            let futureDate = new Date();
            futureDate.setDate(futureDate.getDate() + 31);

            let month = futureDate.getMonth() + 1; // 월은 0부터 시작하므로 +1
            let day = futureDate.getDate(); // 일

            let formattedDate = `${month}월 ${day}일`;
            return formattedDate;
        }
    }
}
</script>
