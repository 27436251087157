<template>
    <div :style="{marginTop: isFixedBanner ? '0px' : '0', backgroundColor: 'white',}" @click="$store.dispatch('setShowMyInfo', {flag: false});$store.dispatch('setShowSupportInfo', {flag: false})">
        <v-container v-if="seperatedUI">
            <!-- 상품 카드 영역 -->
            <div style="display: flex; width: 100%; justify-content: center; align-items: center">
                <v-row justify="center" style="height:100%; align-items:center; max-width: 1600px;">
                    <v-col style="height: 100%; align-items: center;">
                        <h2 class="ml-2 mt-2 my-2" style="color:black;width: 200px;">캔버스 구매</h2>
                        <product-card
                            v-for="ticket in ticketList"
                            :key="ticket.id"
                            :is-mobile="false"
                            :ticket="ticket"
                            :selected-ticket="selectedTicket"
                            @click="openPayment(ticket)"
                        ></product-card>
                    </v-col>
                    <v-col style="display: flex; justify-content: center; align-items: center; overflow: auto;">
                        <toss-payment-view widget-width="600px;" :amount="selectedTicket.price" :product="selectedTicket" style="box-shadow: #0d3a65;"/>
                    </v-col>
                </v-row>
            </div>

            <v-tooltip text="뒤로">
                <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" class="fixed-btn" style="background-color: rgba(31,31,31,0.5)" icon @click="moveToList"><v-icon class="pb-2" color="white">mdi-arrow-left</v-icon></v-btn>
                </template>
            </v-tooltip>
            <!-- FAQ 목록 -->
        </v-container>
        <template v-else>
            <h2 class="ml-6 mt-2 my-2">캔버스 구매</h2>
            <div justify="center" class="px-10">
                <product-card
                    v-for="ticket in ticketList"
                    :key="ticket.id"
                    :is-mobile="isMobile"
                    :is-smallest-screen="isSmallestScreen"
                    :ticket="ticket"
                    :selected-ticket="selectedTicket"
                    @click="openPayment(ticket)"
                ></product-card>
            </div>
            <v-card class="px-5" style="display: flex;justify-content: center; flex-direction: column; align-items: center;">
                <toss-payment-view widget-width="70%;" :product="selectedTicket" :amount="selectedTicket.price" style="box-shadow: #0d3a65;"/>
            </v-card>

            <v-tooltip text="뒤로">
                <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" class="fixed-btn" style="background-color: rgba(31,31,31,0.5)" icon @click="moveToList"><v-icon class="pb-2" color="white">mdi-arrow-left</v-icon></v-btn>
                </template>
            </v-tooltip>
        </template>
        <alert-dialog ref="alert"/>
    </div>
</template>

<script>
import ProductCard from "@/components/store/ProductCard.vue";
import TossPaymentView from "@/components/store/TossPaymentView.vue";
import AlertDialog from "@/dialogs/AlertDialog.vue";
import {mapGetters} from "vuex";
import moment from "moment/moment";

export default {
    name: 'App',
    computed:{
        ...mapGetters([
            'tabletEnd',
        ]),
        seperatedUI() {
            return window.innerWidth >= 1300;
        },
        tabletUsing() {
            const {tabletEnd} = this;
            return this.isDateValidAndFuture(tabletEnd);
        },
        ticketList() {
            const {tickets, tabletUsing} = this;

            return tabletUsing ? tickets.filter((d)=>d.id < 4) : tickets;
        },
    },
    components: {
        ProductCard,
        TossPaymentView,
        AlertDialog,
    },
    async mounted() {
        window.addEventListener('resize', this.onResize);
        this.onResize();

        await this.$store.dispatch('setShowMyInfo', {flag: false});
        await this.$store.dispatch('setInStore', {flag: true});
        const requestData = {
            orderId: this.$route.query.orderId,
            amount: this.$route.query.amount,
            paymentKey: this.$route.query.paymentKey,
            type: this.$route.query.paymentType,
        };

        if (requestData.orderId && requestData.amount && requestData.paymentKey) {
            const response = await this.$store.dispatch('paymentConfirm', requestData);

            if (response && response.result === 'OK') {
                this.$refs.alert.open('구매에 성공했어요.', () => {
                    this.confirmed = true;
                    this.$router.push({name: 'ImageHome'});
                });
            } else if (response && response.result === 'WAITING_FOR_DEPOSIT') {
                this.$refs.alert.open('가상계좌 입금이 완료 되면, 5분 이내에 캔버스가 충전 되요.', () => {
                    this.confirmed = true;
                    this.$router.push({name: 'ImageHome'});
                });
            } else this.$refs.alert.open('구매가 실패했습니다.');
        }
    },
    async beforeUnmount() {
        await this.$store.dispatch('setInStore', {flag: false});
        window.removeEventListener('resize', this.onResize);
    },
    data: () => ({
        tickets: [
            {
                id: 1,
                title: 'Basic',
                quantity: 50,
                originalPrice: 20000,
                price: 15000,
                discount: '25%',
                img: 'sell1.webp',
                bonus: 10,
                singlePrice: 62,
                total: 60,
            },
            {
                id: 2,
                title: 'Standard',
                quantity: 100,
                originalPrice: 40000,
                price: 24000,
                discount: '40%',
                img: 'sell2.webp',
                bonus: 30,
                singlePrice: 45,
                total: 130,
                // best: true,
            },
            {
                id: 3,
                title: 'Pro',
                quantity: 200,
                originalPrice: 80000,
                price: 36000,
                discount: '55%',
                img: 'sell3.webp',
                bonus: 100,
                singlePrice: 30,
                total: 300,
                // best: true,
            },
            // {
            //     id: 4,
            //     title: '자유이용권',
            //     period: '30일',
            //     originalPrice: 120000,
            //     price: 54000,
            //     discount: '55%',
            //     img: 'sell4.webp',
            //     best2: true,
            // },
            // { id: 4, quantity: 110, originalPrice: 55000, price: 22800 , discount: '59%', img: 'model.png' },
        ],
        selectedTicket: {
            id: 3,
            title: '캔버스 200+100장',
            quantity: 100,
            originalPrice: 80000,
            price: 36000,
            discount: '55%',
            img: 'sell3.webp',
            bonus: 100,
            singlePrice: 30,
            total: 300,
            best: true,
        },
        paymentDialog: false,
        isMobile: false,
        isFixedBanner: false,
        isSmallestScreen: false,
    }),
    methods: {
        openPayment(ticket) {
            if (ticket) this.selectedTicket = ticket;
        },
        isDateValidAndFuture(date) {
            if (!date) return false;

            const today = moment().startOf('day'); // 오늘 날짜를 'yyyy-MM-dd' 형식으로 가져옴, 시간은 00:00으로 설정
            const inputDate = moment(date, 'YYYY-MM-DD'); // 입력된 날짜를 moment 객체로 변환

            return inputDate.isSameOrAfter(today); // 입력된 날짜가 오늘 또는 미래 날짜인지 확인
        },
        moveToList() {
            clearTimeout(this.polling);
            this.$store.dispatch('startLoading');
            setTimeout(async () => {
                await this.$store.dispatch('stopLoading');
                this.$store.dispatch('setIsStyleSelected', {flag: false});
                this.$router.push({name: 'ImageHome'})
            }, 300);
        },
        onResize() {
            if (window.innerWidth < 1300) this.isFixedBanner = true;
            else this.isFixedBanner = false;

            if (window.innerWidth < 640) this.isMobile = true;
            else this.isMobile = false;

            if (window.innerWidth < 530) this.isSmallestScreen = true;
            else this.isSmallestScreen = false;
        },
    }
}
</script>
<style scoped>
.price del {
    color: grey;
}

.fixed-btn {
    position: fixed; /* 고정 위치 */
    bottom: 20px; /* 하단에서 20px 떨어진 위치 */
    right: 20px; /* 우측에서 20px 떨어진 위치 */
    z-index: 199; /* z-index 설정 */
    border: none; /* 테두리 제거 */
    outline: none; /* 외곽선 제거 */
    background-color: #007bff; /* 배경 색상 */
    color: white; /* 텍스트 색상 */
    cursor: pointer; /* 마우스 오버시 커서 변경 */
    padding: 15px; /* 패딩 */
    border-radius: 10px; /* 동그란 모양 */
    font-size: 18px; /* 폰트 크기 */
}

.confirm-btn {
    background-color: #6D49FF!important;
    width: 95%;
    font-weight: bold;
}
</style>
