<!-- src/components/GalleryImage.vue -->
<template>
    <div :style="{display: 'flex', justifyContent: 'center', width: '100%'}">
<!--        <v-row align="center" style="width:100%;" justify="center">-->
<!--            <v-col cols="12" class="d-flex justify-center mt-2">-->
<!--                <v-btn width="100" class="ml-5" style="font-weight: bold; font-size: 13px; border: ghostwhite 2px solid; border-radius: 10px;" :color="currentTab === 0 ? 'red' : 'whitesmoke'" @click="tabClick(0)"><v-icon>mdi-star-shooting</v-icon>FEATURED</v-btn>-->
<!--                <v-btn width="100" class="ml-1" style="font-weight: bold; font-size: 13px; border: ghostwhite 2px solid; border-radius: 10px;" :color="currentTab === 1 ? 'red' : 'whitesmoke'" @click="tabClick(1)"><v-icon>mdi-calendar-today</v-icon>RECENT</v-btn>-->
<!--                <v-btn width="100" class="ml-1" style="font-weight: bold; font-size: 13px; border: ghostwhite 2px solid; border-radius: 10px;" :color="currentTab === 2 ? 'red' : 'whitesmoke'" @click="tabClick(2)"><v-icon>mdi-heart</v-icon>LIKE</v-btn>-->
<!--            </v-col>-->
<!--        </v-row>-->
        <div style="display: flex; justify-content: center;" :style="{width: isLeftPaneVisible && !isFixedBanner ? '100%' : '100%'}" v-if="currentTab === 0 && filteredImages && filteredImages.length > 0">
            <masonry-viewer :redrawer="redrawer" :current-tab="currentTab" :is-left-pane-visible="isLeftPaneVisible" :id="0" :is-fixed-banner="isFixedBanner" :is-mobile="isMobile" :images="filteredImages" />
        </div>
        <div style="display: flex; justify-content: center;" :style="{width: isLeftPaneVisible && !isFixedBanner ? '100%' : '100%'}"  v-else-if="currentTab === 1 && filteredImages && filteredImages.length > 0">
            <masonry-viewer :redrawer="redrawer" :current-tab="currentTab" :is-left-pane-visible="isLeftPaneVisible" :id="1" :is-fixed-banner="isFixedBanner" :is-mobile="isMobile" :images="filteredImages" />
        </div>
        <div style="display: flex; justify-content: center;" :style="{width: isLeftPaneVisible && !isFixedBanner ? '100%' : '100%'}" v-else-if="currentTab === 2 && filteredImages && filteredImages.length > 0">
            <masonry-viewer :redrawer="redrawer" :current-tab="currentTab" :is-left-pane-visible="isLeftPaneVisible" :id="2" :is-fixed-banner="isFixedBanner" :is-mobile="isMobile" :images="filteredImages" />
        </div>
        <template v-else>
            <div style="display: flex; width: 100%; min-width: 330px; justify-content: center;" class="mt-5">
                <div :style="{backgroundColor: 'whitesmoke', display: 'flex', justifyContent: 'center', alignItems: 'center', border: 'dashed 3px lightgrey', borderRadius: '50px', flexDirection: 'column', justifyContent: 'center',alignItems: 'center', width: isFixedBanner ? '80%' : '50%', height: isFixedBanner ? '500px' : '700px'}">
                    <v-card-title style="color: orangered; font-weight: bold;">지금은 보여줄게 없어요 😂</v-card-title>
                    <div>
                        <img :style="`height: ${isMobile ? '250px' : '350px'}; width: ${isMobile ? '250px' : '350px'}; border: solid antiquewhite 10px; border-radius: 100px; object-fit: contain;`" src="character1.webp">
                    </div>
                </div>
            </div>
        </template>
        <template v-if="isFixedBanner">
            <v-tooltip text="위로">
                <template v-slot:activator="{ props }">
                    <v-btn
                        v-show="showScrollToTop"
                        icon
                        v-bind="props"
                        color="rgba(0, 0, 255, 0.7)"
                        dark
                        fixed
                        bottom
                        right
                        class="fixed-btn btn-zipped"
                        style="right: 20px; bottom: 20px; width: 55px;"
                        @click="scrollToTop"
                    >
                        <v-icon class="pb-2">mdi-arrow-up</v-icon>
                    </v-btn>
                </template>
            </v-tooltip>
<!--            <v-btn-->
<!--                color="rgba(255,0,0,0.7)"-->
<!--                dark-->
<!--                fixed-->
<!--                bottom-->
<!--                right-->
<!--                height="48"-->
<!--                :icon="!showText"-->
<!--                :class="{'fixed-btn': true, 'btn-expanded': showText, 'btn-zipped': !showText}"-->
<!--                style="bottom: 20px; bottom: 20px; color: white; font-weight: bold;"-->
<!--                @click="moveCreation"-->
<!--            >-->
<!--                <v-icon color="white" class="pb-2">mdi-creation</v-icon>-->
<!--                <transition name="fade">-->
<!--                    <span v-show="showText" class="ml-2 pb-2 font-weight-bold" style="color: white;">Create</span>-->
<!--                </transition>-->
<!--            </v-btn>-->
        </template>
        <image-detail-dialog ref="imageDetailDialog"/>
        <create-image-dialog ref="createImageDialog"/>
        <social-login-dialog ref="socialLoginDialog"/>
        <confirm-dialog ref="confirm"/>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {EventBus} from "@/util/eventBus";
import CreateImageDialog from "@/dialogs/CreateImageDialog.vue";
import SocialLoginDialog from "@/dialogs/SocialLoginDialog.vue";
import ImageDetailDialog from "@/dialogs/ImageDetailDialog.vue";
import ConfirmDialog from "@/dialogs/ConfirmDialog.vue";
import MasonryViewer from "@/components/image/MasonryViewer.vue";
import moment from "moment";

export default {
    name: 'ImageGallery',
    components:{ConfirmDialog, CreateImageDialog, SocialLoginDialog, ImageDetailDialog, MasonryViewer},
    async mounted() {
        // this.onResize();
        const { fromCreator } = this;
        // window.addEventListener('resize', this.onResize);
        // window.addEventListener('scroll', this.handleScroll);
        if(!fromCreator || (this.filteredImages && this.filteredImages.length > 100)) {
            this.$store.dispatch('setFromCreator', {flag: false});
            await this.$store.dispatch('clearImages');
            this.visit = {init: true};
            await this.fetchImages();
            this.redrawer += 1;
        }
        // if(typeof this.$redrawVueMasonry === 'function') this.$redrawVueMasonry();
    },
    beforeUnmount() {
        // window.removeEventListener('scroll', this.handleScroll);
        // window.removeEventListener('resize', this.onResize);
        EventBus.off('signIn');
    },
    props: {isLeftPaneVisible: Boolean, isFixedBanner: Boolean, isMobile: Boolean},
    watch:{
        async searchKeyword(){
            await this.$store.dispatch('clearImages');

            await this.fetchImages();
            if(typeof this.$redrawVueMasonry === 'function') this.$redrawVueMasonry();
            this.scrollToTop();
        },
        isLeftPaneVisible() {
            setTimeout(()=>{
                if(typeof this.$redrawVueMasonry === 'function') this.$redrawVueMasonry();
            }, 500);
        },
        showCreator() {
            if(typeof this.$redrawVueMasonry === 'function') this.$redrawVueMasonry();
        },
    },
    computed: {
        ...mapGetters([
            'isAuthenticated',
            'userInfo',
            'darkMode',
            'newUser',
            'featured',
            'recent',
            'like',
            'canvases',
            'tabletEnd',
            'role',
            'searchKeyword',
            'profileImage',
            'reload',
            'currentTab',
            'fromCreator',
        ]),
        filteredImages() {
            switch(this.currentTab) {
                case 0: return this.featured;
                case 1: return this.recent;
                case 2: return this.like;
            }
            return this.featured;
        },
    },
    data() {
        return {
            isToolbarVisible: false,
            isAnyImageHighlighted: false,
            loading: false,
            selectedImage: null,
            dialog: false,
            addDialog: false,
            registerDialog: false,
            socialLoginDialog: false,
            filterKeyword: null,
            birthdate: '',
            // isMobile: false,
            // isFixedBanner: false,
            prevTab: null,
            newImage: {
                src: '',
                title: '',
                author: '',
            },
            tabs: [
                { text: 'Featured', value: 'FEATURED', icon: 'mdi-home' , idx: 0 },
                { text: 'Recent', value: 'RECENT', icon: 'mdi-calendar', idx: 1 },
                { text: 'Like', value: 'LIKE', icon: 'mdi-heart', idx: 2},
                { text: 'Bookmark', value: 'BOOKMARK', icon: 'mdi-book', idx: 2},
            ],
            valid: false,
            menu: false,
            visit: {},
            showIconOnly: false,
            showScrollToTop: false,
            tabClickHandler: null,
            showText: true,
            initFlags: {},
            redrawer: 0,
        };
    },
    methods: {
        highlightImage(image) {
            const { isMobile } = this;

            if(!isMobile){
                this.isAnyImageHighlighted = true;
                image.isHighlighted = true;
            }
        },
        unhighlightImage(image) {
            const { isMobile } = this;

            if(!isMobile){
                this.isAnyImageHighlighted = false;
                image.isHighlighted = false;
            }
        },
        selectImage(image) {
            const { isMobile } = this;

            if(!isMobile) this.$refs.imageDetailDialog.open(image);
        },
        copy(image) {
            this.moveLoginIfRequired(async ()=>{
                this.moveStoreIfRequired(()=>{
                    this.$router.push({name: 'ImageCreator', query: {id: image.parentId, command: 'copy', url: image.url}});
                });
            });
        },
        moveCreation() {
            this.moveLoginIfRequired(async () => {
                this.moveStoreIfRequired(() => {
                    this.$router.push({name: 'ImageCreator'});
                });
            });
        },
        moveLoginIfRequired(block) {
            const { isAuthenticated } = this;
            if(!isAuthenticated) {
                this.$refs.socialLoginDialog.open();
            } else {
                if(block) block();
            }
        },
        moveStoreIfRequired(block) {
            const { canvases } = this;
            if(canvases <= 0 && !this.isDateValidAndFuture(this.tabletEnd)) {
                this.$refs.confirm.open(`캔버스가 부족해요. 캔버스를 구매하러 이동하시겠어요?`, () => {
                    this.$router.push({name: 'CanvasStore'})
                });
            } else {
                if(block) block();
            }
        },
        isDateValidAndFuture(date) {
            if(!date) return false;

            const today = moment().startOf('day'); // 오늘 날짜를 'yyyy-MM-dd' 형식으로 가져옴, 시간은 00:00으로 설정
            const inputDate = moment(date, 'YYYY-MM-DD'); // 입력된 날짜를 moment 객체로 변환

            return inputDate.isSameOrAfter(today); // 입력된 날짜가 오늘 또는 미래 날짜인지 확인
        },
        // onResize() {
        //     if(window.innerWidth < 1390) this.isFixedBanner = true;
        //     else this.isFixedBanner = false;
        //
        //     if(window.innerWidth < 640) this.isMobile = true;
        //     else this.isMobile = false;
        // },
        async download(url) {
            this.$store.dispatch('startLoading');
            const id = url.split('/').pop().replace('.webp', '.png');
            const response = await fetch(url.replace('.webp', '.png'));
            const blob = await response.blob();
            const u = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = u;
            link.setAttribute('download', id); // 다운로드할 파일명 지정
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.isAnyImageHighlighted = false;
            this.$store.dispatch('stopLoading');
        },
        async deleteMyPost(id) {
            this.$refs.confirm.open('삭제할까요?', async ()=>{
                await this.$store.dispatch('deleteMyPost', {id});
                await this.$store.dispatch('clearImages');
                await this.$store.dispatch('fetchImages', {init: true, findType: 'RECENT'});
                this.visit = { init: true };
                if(typeof this.$redrawVueMasonry === 'function') this.$redrawVueMasonry();

                setTimeout(()=>{
                    this.scrollToTop();
                }, 500);
            });
        },
        async fetchImages() {
            const { searchKeyword , currentTab , tabs} = this;
            const findType = (currentTab && tabs) ? tabs[currentTab].value : 'FEATURED'

            this.initFlags[findType] = true;
            await this.$store.dispatch('fetchImages', {findType, init: true, searchKeyword});
            this.visit = {init: true};
        },
        format(d){
            const parts = d.toString().split(",");
            const year = parts[0];
            const month = parts[1].padStart(2, '0'); // 월을 두 자릿수로 만듦
            const day = parts[2].padStart(2, '0'); // 일을 두 자릿수로 만듦

            return `${year}-${month}-${day}`;
        },
        handleScroll() {
            const scrollContainer = this.scrollContainer;
            const scrollTop = scrollContainer.scrollTop;
            const scrollHeight = scrollContainer.scrollHeight;
            const clientHeight = scrollContainer.clientHeight;


            if(this.$refs.imageGallery) {
                const bottomOfWindow = scrollTop + clientHeight >= scrollHeight-200;
                if (bottomOfWindow) {
                    if(this.$refs.imageGallery) this.$refs.imageGallery.loadMore();
                }
                this.$refs.imageGallery.showText = scrollTop <= 50;
                this.$refs.imageGallery.showScrollToTop = scrollTop > 200;
            }
        },
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        loadMore() {
            const { filteredImages } = this;
            const lastItem = filteredImages[filteredImages.length-1];

            if(lastItem && this.visit[lastItem.id]) return;
            if(!lastItem && this.visit['init']) return;

            if(lastItem) this.visit[lastItem.id] = true;
            else this.visit['init'] = true;

            const { currentTab , tabs , searchKeyword } = this;
            const findType = (currentTab && tabs) ? tabs[currentTab].value : 'FEATURED'
            this.$store.dispatch('fetchImages', {init: false, offset: lastItem ? lastItem.id : null, lastLikeCount: lastItem ? lastItem.likeCount : null, findType, searchKeyword});
        },
        async removePost(image) {
            this.$refs.confirm.open('삭제할까요?', async ()=>{
                await this.$store.dispatch('deletePost', {id: image.id});
                await this.$store.dispatch('clearImages');
                await this.$store.dispatch('fetchImages', {init: true, findType: 'RECENT'});
                this.visit['init'] = true;
                this.$store.dispatch('setReload', { cnt : this.reload + 1 } );
                if(typeof this.$redrawVueMasonry === 'function') this.$redrawVueMasonry();

                setTimeout(()=>{
                    this.scrollToTop();
                }, 500);
            });
        },
        async removeFeatured(image) {
            this.$refs.confirm.open('Featured를 해제할까요?', async ()=>{
                await this.$store.dispatch('setFeatured', {id: image.id, flag: 'N'});
                await this.$store.dispatch('clearImages');
                await this.$store.dispatch('fetchImages', {init: true, findType: 'FEATURED'});
                this.visit['init'] = true;
                this.$store.dispatch('setReload', { cnt : this.reload + 1 } );
                if(typeof this.$redrawVueMasonry === 'function') this.$redrawVueMasonry();

                setTimeout(()=>{
                    this.scrollToTop();
                }, 500);
            });
        },
        async setFeatured(image) {
            this.$refs.confirm.open('Featured로 설정할까요?', async ()=>{
                await this.$store.dispatch('setFeatured', {id: image.id, flag: 'Y'});
                await this.$store.dispatch('clearImages');
                await this.$store.dispatch('fetchImages', {init: true, findType: 'RECENT'});
                this.visit['init'] = true;
                this.$store.dispatch('setReload', { cnt : this.reload + 1 } );
                if(typeof this.$redrawVueMasonry === 'function') this.$redrawVueMasonry();
            });
        },
        async dummyLike(image) {
            await this.$store.dispatch('dummyLikeImage', {id: image.id});
        },
        async tabClick(tab) {
            clearTimeout(this.tabClickHandler);
            await this.$store.dispatch('setCurrentTab', {tab});
            this.tabClickHandler = setTimeout(async ()=>{
                const { initFlags , tabs , searchKeyword } = this;
                const findType = (tab && tabs) ? tabs[tab].value : 'FEATURED'
                if(!initFlags[findType]) {
                    const fetchData = async ()=>{
                        await this.$store.dispatch('fetchImages', {findType, init: true, searchKeyword});
                        this.visit = {init: true};
                        // if(typeof this.$redrawVueMasonry === 'function') {
                        //     this.$redrawVueMasonry();
                        // }
                        this.redrawer += 1;
                        this.prevTab = tab;
                    };

                    if(findType === 'LIKE') {
                        this.$store.dispatch('startLoading');
                        this.moveLoginIfRequired(async ()=>{
                            await fetchData();
                            this.$store.dispatch('stopLoading');
                        });
                    }
                    else await fetchData();
                }
                // const { /*currentTab*/ prevTab,  tabs , searchKeyword } = this;
                // if(prevTab !== tab) {
                //     const fetchData = async ()=>{
                //         const findType = (tab && tabs) ? tabs[tab].value : 'FEATURED'
                //         await this.$store.dispatch('fetchImages', {findType, init: true, searchKeyword});
                //         this.visit = {init: true};
                //         if(typeof this.$redrawVueMasonry === 'function') {
                //             this.$redrawVueMasonry();
                //         }
                //         this.prevTab = tab;
                //     };
                //
                //     localStorage.setItem('currentTab', tab);
                //
                //     if(tabs[tab].value === 'LIKE') {
                //         this.$store.dispatch('startLoading');
                //         this.moveLoginIfRequired(async ()=>{
                //             await fetchData();
                //             this.$store.dispatch('stopLoading');
                //         });
                //     }
                //     else await fetchData();
                // }
                // this.$store.dispatch('setReload', { cnt : this.reload + 1 } );
                this.$store.dispatch('stopLoading');
            }, 200);
        }
    },
};
</script>

<style scoped>
.dimmed {
    opacity: 0.5;
}

.image-info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    color: #ffffff;
    padding: 10px;
    display: none;
    flex-direction: column;
    overflow: hidden;
}

.item:hover .image-info {
    display: block;
}

.image-info-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    color: #ffffff;
    padding: 10px;
    display: none;
    flex-direction: column;
    overflow: hidden;
}

.item:hover .image-info-top {
    display: block;
}
@media (max-width: 1200px) {
    .image-info-top {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        color: #ffffff;
        padding: 10px;
        display: block;
        flex-direction: column;
        overflow: hidden;
    }
    .image-info {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        color: #ffffff;
        padding: 10px;
        display: block;
        flex-direction: column;
        overflow: hidden;
    }
}
@media (max-width: 640px) {
    .image-info-top {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        color: #ffffff;
        padding: 10px;
        display: block;
        flex-direction: column;
        overflow: hidden;
    }
    .image-info {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        color: #ffffff;
        padding: 10px;
        display: block;
        flex-direction: column;
        overflow: hidden;
    }
}

.text-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fixed-btn {
    position: fixed; /* 고정 위치 */
    bottom: 20px; /* 하단에서 20px 떨어진 위치 */
    right: 20px; /* 우측에서 20px 떨어진 위치 */
    z-index: 199; /* z-index 설정 */
    border: none; /* 테두리 제거 */
    outline: none; /* 외곽선 제거 */
    background-color: #007bff; /* 배경 색상 */
    color: white; /* 텍스트 색상 */
    cursor: pointer; /* 마우스 오버시 커서 변경 */
    padding: 15px; /* 패딩 */
    border-radius: 10px; /* 동그란 모양 */
    font-size: 18px; /* 폰트 크기 */
}

.btn-expanded {
    transition: width 0.5s ease;
    width: 130px;
}

.btn-zipped {
    width: 55px;
    overflow: hidden;
    transition: width 0.5s ease;
}
/* 텍스트 페이드 인/아웃 애니메이션 */
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter-from, .fade-leave-to {
    opacity: 0;
}
</style>
