<template>
    <alert-dialog ref="alert"/>
</template>

<script>
import AlertDialog from "@/dialogs/AlertDialog.vue";

export default {
    name: 'SuccessView',
    components: {AlertDialog},
    data() {
        return {
            confirmed: false,
        };
    },
    async mounted() {
        const requestData = {
            orderId: this.$route.query.orderId,
            amount: this.$route.query.amount,
            paymentKey: this.$route.query.paymentKey,
            type: this.$route.query.paymentType,
        };
        const response = await this.$store.dispatch('paymentConfirm', requestData);

        if(response && response.result === 'OK') {
            this.$refs.alert.open('구매에 성공했습니다.', ()=>{
                this.confirmed = true;
                this.$router.replace({name: 'ImageHome'});
            });
        }
        else this.$refs.alert.open('구매가 실패했습니다.');
    }
};
</script>
