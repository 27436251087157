<template>
    <v-dialog v-model="dialog" persistent max-width="500px">
        <v-card style="border-radius: 10px;">
            <v-card-title style="background-color: #0d3a65; font-weight: bold; color: white;" class="text-h5">확인</v-card-title>
            <v-card-text>{{ message }}</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey darken-1" text @click="cancel">취소</v-btn>
                <v-btn color="blue darken-1" text @click="confirmAction">확인</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'ConfirmDialog',
    data() {
        return {
            dialog: false,
            message: '',
            func: null,
            cancelFunc: null,
        };
    },
    methods: {
        async confirmAction() {
            // 확인 버튼 클릭 시 수행할 액션
            await this.func();
            this.dialog = false;
        },
        open(message, callback, cancelCallback){
            this.message = message;
            this.func = callback;
            this.cancelFunc = cancelCallback;
            this.dialog = true;
        },
        async cancel() {
            if(this.cancelFunc && typeof this.cancelFunc === 'function') await this.cancelFunc();

            this.dialog = false;
        }
    }
};
</script>
