<template>
    <v-dialog v-model="dialog" persistent max-width="600px" style="z-index: 9990">
        <v-card style="border-radius: 10px;">
            <v-card-title style="background-color: #0d3a65; font-weight: bold; color: white;" class="text-h5">닉네임 변경하기</v-card-title>
            <v-card-text>
                <v-text-field
                    v-model="nickName"
                    label="변경할 닉네임"
                    class="mb-2"
                    :counter="20"
                    @keyup.enter="changeNickName"
                    maxlength="20"
                    required
                ></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">취소</v-btn>
                <v-btn color="blue darken-1" :disabled="!nickName || nickName === ''" text @click="changeNickName">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: 'ChangeNickNameDialog',
    computed: {
        ...mapGetters([
                'userName',
            ]
        ),
    },
    data() {
        return {
            valid: false,
            dialog: false, // 다이얼로그 표시 여부
            nickName: '', // 제목
            rules: {
                required: value => !!value || '필수 입력입니다.',
                same: value => value !== this.userName || '이전 닉네임과 동일합니다.',
                max20: v => (v || '').length <= 20 || '최대 20자까지 입력 가능합니다.',
                max200: v => (v || '').length <= 200 || '최대 200자까지 입력 가능합니다.'
            }
        };
    },
    methods: {
        async changeNickName() {
            if (this.nickName && this.nickName !== '') {
                const {nickName} = this;
                await this.$store.dispatch('changeNickName', {nickName});
                this.dialog = false; // 다이얼로그 닫기
                this.$emit('changed');
            }
        },
        open() {
            this.nickName = this.userName;
            this.dialog = true;
        },
        close() {
            this.dialog = false;
        }
    }
};
</script>
