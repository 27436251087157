<script>
export default {
    name: "SocialLoginDialog",
    data(){
        return {
            dialog: false,
        }
    },
    computed:{
        oauthBaseUrl() {
            // return "http://localhost:8080/api";
            return "https://dorosi.ai/api";
        }
    },
    methods: {
        open() {
            this.dialog = true;
        },
        close() {
            this.dialog = false;
        },
        loginWithKakao() {
            window.location.href = `${this.oauthBaseUrl}/oauth2/authorization/kakao`;
        },
        loginWithGoogle() {
            window.location.href = `${this.oauthBaseUrl}/oauth2/authorization/google`;
        },
        loginWithApple() {
            window.location.href = `${this.oauthBaseUrl}/oauth2/authorization/apple`;
        },
    }
}
</script>

<template>
    <v-dialog v-model="dialog" max-width="302px" style="z-index: 5001;">
        <v-card height="302" color="#2C2F37" style="overflow:hidden; border-radius: 10px; z-index: 5001;" class="pt-5">
            <div style="display: flex; justify-content: center; align-items: center; width: 100%;" class="pa-1 px-15">
                <v-img
                    style="width: 262px;height: 176px;"
                    src="login_dorosi.png"/>
            </div>
            <div class="mt-1" style="text-align: center;">
                <v-btn height="45" style="text-align: center; font-size: 16px; text-transform: none"
                       width="80%" color="#FEE500" @click="loginWithKakao"><img width="18px" height="18px" class="mr-1"
                                                                               src="kakao_icon.png"/>카카오로 시작하기
                </v-btn>
            </div>
            <div style="display: flex; justify-content: center;">
                <span class="mt-4" style="font-size:11px;">Copyright 2024. dorosi. all rights reserved.</span><br/>
            </div>
        </v-card>
    </v-dialog>
</template>

<style scoped>

</style>
