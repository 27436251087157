<template>
    <div class="box_section mt-10" :style="`min-width: 350px; width: ${widgetWidth}`" >
        <div id="payment-method"></div>
        <div id="agreement"></div>
        <div style="width: 100%; display: flex; justify-content: center">
            <div class="px-5 text-left" style="background-color:#F7F8FA;color: #616675; border-radius: 20px; max-width:1600px; width: 90%; height: 200px; overflow:auto; ">
                <h2 style="font-size: 20px;" class="mb-2">캔버스 및 자유이용권 구매 약관</h2>
                <p style="font-size: 11px;">본 약관은 캔버스 및 자유이용권의 구매 및 사용에 대한 규정을 명시합니다.<br/>캔버스를 구매하시기 전에 본 약관을 주의 깊게 읽어주시기 바랍니다.<br/>캔버스의 구매 및 사용은 본 약관에 동의함을 의미합니다.</p><br/>
                <p style="font-weight:bold;font-size: 11px;">가. 캔버스 사용 범위<br/></p>
                <p style="font-size: 11px;">1) 캔버스는 구매일로부터 이미지 생성 서비스를 1회 사용할 수 있는 권리를 부여합니다.<br/>2) 캔버스 사용 시, 사용자는 4장의 이미지를 동시에 생성할 수 있습니다.<br/>3) 캔버스는 사용 시점에 소멸되며, 이후 재사용할 수 없습니다.</p><br/>
                <p style="font-weight:bold;font-size: 11px;">나. 자유이용권 사용 범위<br/></p>
                <p style="font-size: 11px;">1) 자유이용권은 명시 된 종료일까지 이미지 생성 서비스를 무제한 사용할 수 있는 권리를 부여합니다.<br/>2) 효과는 캔버스를 1장 사용하는 것과 동일합니다.<br/>3) 종료일 익일 자정(00:00)이 되면, 자유이용권은 사용이 종료 됩니다.</p><br/>
                <p style="font-weight:bold;font-size: 11px;">다. 환불 정책</p>
                <p style="font-size: 11px;">1) 한 번 사용된 캔버스는 그 어떠한 경우에도 환불이 불가능합니다.<br/>2) 사용 전 캔버스의 환불을 원하는 경우, 구매일로부터 1년 이내에 환불 요청이 가능합니다.</p><br/>
                <p style="font-weight:bold;font-size: 11px;">라. 프로모션 및 가격 변동</p>
                <p style="font-size: 11px;">1) 캔버스 가격은 프로모션 또는 기타 마케팅 활동에 따라 조정될 수 있습니다.<br/>2) 프로모션은 사전 고지 없이 조기에 종료될 수 있으며, 이에 따른 환불이나 보상은 제공되지 않습니다.</p><br/>
                <p style="font-weight:bold;font-size: 11px;">마. 계정 이용 정지 및 서비스 이용 제한</p>
                <p style="font-size: 11px;">1) 사용자가 서비스를 부정 이용하거나 본 약관을 위반하는 경우, 서비스 제공자는 사용자의 계정을 임시 또는 영구적으로 정지시킬 수 있습니다.<br/>2) 계정이 정지된 사용자는 캔버스를 포함한 모든 서비스의 이용이 제한되며, 이로 인해 발생한 손실에 대해 환불이나 보상을 요구할 수 없습니다.</p><br/>
            </div>
        </div>
        <div style="display: flex; justify-content: center; align-items: center; width: 100%;">
<!--            <v-btn @click="requestPayment" class="my-5" width="90%" height="70" color="primary" style="font-size:25px;font-weight: bold;"><v-icon>mdi-check</v-icon>구매하기</v-btn>-->
            <v-col style="display: flex;justify-content: center;" class="py-0 my-3">
                <v-btn width="90%" height="70" style="font-size:20px;" class="confirm-btn" color="#6D49FF" text @click="requestPayment">구매하기</v-btn>
            </v-col>
        </div>
        <alert-dialog ref="alert"/>
    </div>
</template>

<script>
import { loadPaymentWidget, ANONYMOUS } from "@tosspayments/payment-widget-sdk";
import { nanoid } from "nanoid";
import AlertDialog from "@/dialogs/AlertDialog.vue";

export default {
    props: {amount: Number, product: Object, widgetWidth: Number,},
    components:{AlertDialog},
    data() {
        return {
            paymentWidget: null,
            paymentMethodWidget: null,
            clientKey: "live_gck_ZLKGPx4M3MRZd9bMaG6wVBaWypv1",
            customerKey: nanoid(),
        };
    },
    watch:{
        amount(v) {
            this.updateAmount(v);
        }
    },
    methods: {
        async requestPayment() {
            try {
                if (this.paymentWidget) {
                    const { product } = this;

                    this.paymentWidget.requestPayment({
                        orderId: nanoid(),
                        orderName: product.title,
                        successUrl: `${window.location.origin}/store`,
                        failUrl: `${window.location.origin}/store`,
                    });
                }
            } catch (error) {
                console.error(error);
            }
        },
        async updateAmount() {
            if(this.paymentMethodWidget) this.paymentMethodWidget.updateAmount(this.amount);
        },
    },
    async mounted() {
        this.paymentWidget = await loadPaymentWidget(this.clientKey, ANONYMOUS);
        this.paymentMethodWidget = this.paymentWidget.renderPaymentMethods("#payment-method", {value: this.amount}, {variantKey: "DEFAULT"});
        this.paymentWidget.renderAgreement("#agreement", {variantKey: "AGREEMENT"});
    },
};
</script>
<style scoped>
.confirm-btn {
    background-color: #6D49FF!important;
    width: 100%;
    border-radius: 10px;
}
</style>
