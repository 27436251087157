<script>
export default {
    name: "ProfileImageWrapper",
    props: {
        src: String,
        alt: String,
        imageStyle: String,
    },
    data() {
        return {
            imageLoaded: false
        };
    },
    methods:{
        loaded() {
            this.imageLoaded = true
        }
    }

}
</script>

<template>
    <div style="display: flex; align-items: center;">
        <img v-show="src && src !== '' && imageLoaded" @load="loaded" :style="imageStyle" :alt="alt" :src="src">
        <v-icon v-show="!src || src === '' || !imageLoaded" size="xsmall" style="background-color:white;border-radius:10px; color: black;width:20px;height: 20px;">mdi-human-greeting-variant</v-icon>
    </div>
</template>

<style scoped>
</style>
