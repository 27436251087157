<template>
    <div class="content" @click="$store.dispatch('setShowMyInfo', {flag: false});$store.dispatch('setShowSupportInfo', {flag: false})">
        <!--        <top-banner/>-->
<!--        <v-container class="pa-0 ma-0" style="width: 100%">-->
        <v-row style="position:fixed;" :style="{width: '100%', paddingLeft: isMobile ? '5px!important' : '80px!important'}" class="tabs-container py-0 ma-0">
            <v-col justify="start">
                <v-btn
                    v-for="(tab, index) in tabs"
                    :key="index"
                    :class="{'active-tab': activeTab === index}"
                    @click="setActiveTab(tab)"
                    class="tab-btn mx-1"
                    text
                    elevation="0"
                >
                    {{ tab.text }}
                </v-btn>
            </v-col>
<!--            <v-col cols="2" style="display:flex;justify-content: end;">-->
<!--                <v-btn-->
<!--                    v-for="(icon, index) in rightIcons"-->
<!--                    :key="index"-->
<!--                    :class="{'active-icon': activeIcon === index}"-->
<!--                    @click="setActiveIcon(index)"-->
<!--                    class="tab-btn mx-1"-->
<!--                    text-->
<!--                    style="width:30px;height: 30px;"-->
<!--                    elevation="0"-->
<!--                >-->
<!--                    <v-icon>{{ icon }}</v-icon>-->
<!--                </v-btn>-->
<!--            </v-col>-->
        </v-row>
        <!--        </v-container>-->
        <div ref="scrollContainer" style="height: calc(100% - 120px); margin-top: 60px; overflow-y: auto;">
            <image-gallery ref="imageGallery" :is-fixed-banner="isFixedBanner" :is-mobile="isMobile" :is-left-pane-visible="isLeftPaneVisible" style="height:100%; background-color: #171717"/>
        </div>
        <register-dialog @registerCompleted="registerCompleted" ref="registerDialog"/>
        <alert-dialog ref="alert"/>
        <confirm-dialog ref="confirm"/>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {EventBus} from "@/util/eventBus";
import ImageGallery from "@/components/image/ImageGallery.vue";
import RegisterDialog from "@/dialogs/RegisterDialog.vue";
import AlertDialog from "@/dialogs/AlertDialog.vue";
import ConfirmDialog from "@/dialogs/ConfirmDialog.vue";

export default {
    components: {ConfirmDialog, AlertDialog, ImageGallery, RegisterDialog},
    name: 'ImageHome',
    props: {isLeftPaneVisible: Boolean, isFixedBanner: Boolean, isMiddleScreen: Boolean, isMobile: Boolean},
    async mounted() {
        const { reload } = this;
        if(reload > 10) {
            this.$store.dispatch('setReload', {cnt: 0});
            window.location.reload();
        } else this.$store.dispatch('setReload', {cnt: (reload+1) || 1});
        EventBus.on('signIn', () => {
            this.socialLoginDialog = true;
        })
        const {newUser, userInfo} = this;
        if (newUser) {
            // this.$refs.alert.open(`지금 회원 가입 하시면 무료 캔버스 ${this.promotion}장을 드려요! 😆`, () => {
                this.$refs.registerDialog.open(userInfo);
            // });
        }
        await this.setActiveTab({idx:0});
        this.scrollContainer = this.$refs.scrollContainer;
        if (this.scrollContainer) {
            this.scrollContainer.addEventListener('scroll', this.handleScroll);
        }

    },
    unmounted() {
        EventBus.off('signIn');
        if (this.scrollContainer) {
            this.scrollContainer.removeEventListener('scroll', this.handleScroll);
        }
    },
    computed: {
        ...mapGetters([
            'isAuthenticated',
            'userInfo',
            'darkMode',
            'newUser',
            'canvases',
            'reload',
        ]),
    },
    data() {
        return {
            scrollContainer: null,
            promotion: 5,
            tabs: [{text:'Featured', idx: 0},{text:'Recent', idx: 1},{text:'Like', idx: 2}/*,{text:'Bookmark', idx: 3}*/],
            rightIcons: ['mdi-shape-plus', 'mdi-heart'],
            activeTab: 0,
            activeIcon: 0,
        };
    },
    methods: {
        setActiveTab(tab) {
            this.$refs.imageGallery.tabClick(tab.idx);
            this.activeTab = tab.idx;
        },
        setActiveIcon(index) {
            this.activeIcon = index;
        },
        registerCompleted() {
            const {userInfo, canvases} = this;
            const hiddenMessage = (canvases && canvases * 1 > this.promotion) ? '추천인 입력으로 특별 혜택이 지급 됐어요! 😆😆😆' : '';
            this.$refs.alert.open(`${userInfo.nickname}님 반가워요! ${hiddenMessage}`);
        },
        handleScroll() {
            const scrollContainer = this.scrollContainer;
            const scrollTop = scrollContainer.scrollTop;
            const scrollHeight = scrollContainer.scrollHeight;
            const clientHeight = scrollContainer.clientHeight;

            if (scrollTop + clientHeight >= scrollHeight - 200) {
                if(this.$refs.imageGallery) this.$refs.imageGallery.loadMore();
            }

            if(this.$refs.imageGallery) {
                const bottomOfWindow = this.$refs.scrollContainer.scrollTop + this.$refs.scrollContainer.clientHeight >= this.$refs.scrollContainer.scrollHeight-200;

                if (bottomOfWindow) {
                    this.$refs.imageGallery.loadMore();
                }

                const scrollTop = this.$refs.scrollContainer.scrollTop;
                this.$refs.imageGallery.showText = scrollTop <= 50;
                this.$refs.imageGallery.showScrollToTop = scrollTop > 200;
            }
        },
    },
};
</script>

<style scoped>
.tabs-container {
    margin-top: 2rem;
    background-color: #171717;
}
.tab-btn {
    margin: 0;
    padding: 0.2rem;
    color: #595b5e;
    background-color: transparent;
    font-weight: bold;
    border: none;
    min-width: unset;
    min-height: unset;
    text-transform: none;
}
.tab-btn:not(.active-tab):hover {
    background-color: transparent !important;
}
.active-tab {
    //background-color: #2d3748 !important;
    color: #edf2f7 !important;
    font-weight: bold;
}
.active-icon {
    background-color: #4c3c7c !important;
    color: whitesmoke !important;
    font-weight: bold;
}
body {
    background-color: #1a202c;
    color: #cbd5e0;
    font-family: 'Arial', sans-serif;
}
</style>
