<template>
    <div class="oauth-callback">
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: 'KakaoLoginPostAction',
    computed: {
        ...mapGetters([
            'newUser',
            'userInfo',
            'darkMode'
        ])
    },
    mounted() {
        this.handleOAuthCallback();
    },
    methods: {
        async handleOAuthCallback() {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const authorization_code = urlParams.get('code');

            if (authorization_code) {
                await this.$store.dispatch('oauthLogin', authorization_code);
                const { newUser, userInfo } = this;
                this.$router.replace({name: 'ImageHome', params: {newUser, userInfo}});
            } else {
                this.$router.replace({name: 'ImageHome'});
            }
        },
    }
}
</script>

<style>
/* 필요한 스타일 */
</style>
