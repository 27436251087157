import {createRouter, createWebHistory} from 'vue-router';
import ImageHome from '../views/ImageHome.vue';
import ImageCreator from "@/views/ImageCreator.vue";
import CanvasStore from "@/views/CanvasStore.vue";
import KakaoLoginPostAction from "@/views/oauth/KakaoLoginPostAction.vue";
import SuccessView from "@/views/payment/SuccessView.vue";
import FailView from "@/views/payment/FailView.vue";
import MyHome from "@/views/MyHome.vue";
import store from '../store';
import Archive from "@/views/Archive.vue";

const routes = [
    { path: '/', name: 'ImageHome', component: ImageHome },
    { path: '/my-work', name: 'MyHome', component: MyHome, meta: { requiresAuth: true } },
    { path: '/archive', name: 'Archive', component: Archive, meta: { requiresAuth: true } },
    { path: '/edit', name: 'ImageCreator', component: ImageCreator, meta: { requiresAuth: true } },
    { path: '/store', name: 'CanvasStore', component: CanvasStore},
    { path: '/oauth2/login/kakao', name: 'KakaoLoginPostAction', component: KakaoLoginPostAction },
    { path: '/payment/success', name: 'SuccessView', component: SuccessView, meta: { requiresAuth: true }  },
    { path: '/payment/fail', name: 'FailView', component: FailView, meta: { requiresAuth: true }  },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

const meta = {
    title: 'Dorosi - Where your imagination becomes reality.',
    metaTags: [
        {
            property: 'og:type',
            content: 'website'
        },
        {
            property: 'og:url',
            content: 'https://dorosi.ai'
        },
        {
            property: 'og:title',
            content: 'Dorosi'
        },
        {
            property: 'og:description',
            content: 'Where your imagination becomes reality.'
        },
        {
            property: 'og:image',
            content: 'https://dorosi.ai/image/1a7d54ec-dc39-460f-bb05-c8c42d40d34a.webp'
        },
    ]
}

router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth) && !store.getters.isAuthenticated) {
        document.title = meta.title;

        // 메타 태그 설정
        meta.metaTags?.forEach(tag => {
            const element = document.createElement('meta');
            Object.keys(tag).forEach(key => {
                element.setAttribute(key, tag[key]);
            });
            document.getElementsByTagName('head')[0].appendChild(element);
        });

        next('/'); // 갱신 실패, 홈으로 리다이렉트
    } else {
        next();
    }
});

router.beforeEach((to, from, next) => {
    const routeExists = routes.some(route => route.path === to.path);
    if (!routeExists) {
        next('/');
    } else {
        next();
    }
});

export default router;

