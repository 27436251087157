import { reactive, readonly } from 'vue';

const state = reactive(new Map());

export const EventBus = {
    emit(event, data) {
        state.set(event, data);
    },
    on(event, callback) {
        const wrappedCallback = () => {
            callback(state.get(event));
        };
        state.set(event, wrappedCallback);
    },
    off(event) {
        state.delete(event);
    },
    getState() {
        return readonly(state);
    }
};
