<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card style="border-radius: 10px;">
            <v-card-title class="mt-2" style="width:100%;color:#8A8C94;font-weight: bold;">
                <!--                <v-icon>mdi-card-account-details</v-icon>-->
                <span class="ml-1">갤러리에 등록하기</span></v-card-title>
            <v-card-text>
                <v-text-field
                    v-model="title"
                    label="제목을 입력해주세요."
                    class="mb-2"
                    :counter="30"
                    @keyup.enter="submitPost"
                    maxlength="30"
                    required
                    style="border-radius: 20px!important;"
                ></v-text-field>
                <v-text-field
                    v-model="creator"
                    v-if="role === 'ADMIN'"
                    label="크리에이터"
                    class="mb-2"
                    :counter="30"
                    maxlength="30"
                    required
                    style="border-radius: 20px!important;"
                ></v-text-field>
<!--                <div style="height:15px;" class="py-0 my-2"><span style="font-size: 15px;color:whitesmoke;">● 갤러리에 게시하게 되면, 모든 사람에게 공개됩니다.</span>-->
<!--                </div>-->
<!--                <div style="height:15px;" class="py-0 my-2"><span style="font-size: 15px;color:whitesmoke;">● 공개된 이미지에 대한 저작권은 보호되지 않습니다.</span>-->
<!--                </div>-->
<!--                <div style="height:15px;" class="py-0 my-2"><span style="font-size: 15px;color:whitesmoke;">● 함께 제작된 이미지(4장)도 공개되니 주의하세요.</span>-->
<!--                </div>-->
            </v-card-text>
<!--            <v-card-actions>-->
<!--                <v-btn color="blue darken-1" text @click="dialog = false">취소</v-btn>-->
<!--                <v-btn color="blue darken-1" :disabled="!title || title === ''" text @click="submitPost">저장</v-btn>-->
<!--            </v-card-actions>-->
            <v-row class="py-0 px-3 my-0 mb-2">
                <v-col style="display: flex;justify-content: center;" class="py-0 my-0 px-2">
                    <v-btn class="cancel-btn" @click="close">취소</v-btn>
                </v-col>
                <v-col style="display: flex;justify-content: center;" class="py-0 my-0 px-2">
                    <v-btn class="confirm-btn" color="#6D49FF" @click="submitPost">게시하기</v-btn>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: 'PostImageDialog',
    computed: {
        ...mapGetters([
                'userName',
                'role',
                'profileImage',
            ]
        ),
    },
    data() {
        return {
            valid: false,
            dialog: false, // 다이얼로그 표시 여부
            title: '', // 제목
            desc: '', // 설명
            parentId: '',
            creator: '',
            url: '',
            rules: {
                required: value => !!value || '필수 입력입니다.',
                max20: v => (v || '').length <= 20 || '최대 20자까지 입력 가능합니다.',
                max200: v => (v || '').length <= 200 || '최대 200자까지 입력 가능합니다.'
            }
        };
    },
    methods: {
        async submitPost() {
            if (this.title && this.creator && this.title !== '' && this.creator !== '') { // 폼 유효성 검증
                const {title, desc, parentId, url, creator, profileImage} = this;
                await this.$store.dispatch('postImage', {title, desc, parentId, url, creator, creatorImage: profileImage});
                this.dialog = false; // 다이얼로그 닫기
                this.$emit('posted');
            }
        },
        open(parentId, url) {
            this.parentId = parentId;
            this.url = url;
            this.title = '';
            this.desc = '';
            this.creator = this.userName;
            this.dialog = true;
        },
        close() {
            this.dialog = false;
        }
    }
};
</script>
<style scoped>

.cancel-btn {
    background-color: dimgrey;
    width: 100%;
    font-weight: bold;
}
.confirm-btn {
    background-color: #6D49FF;
    width: 100%;
    font-weight: bold;
}

</style>
