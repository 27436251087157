<template>
    <v-container fluid :style="{backgroundColor: '#171717', marginTop: isFixedBanner ? '0' : '0'}" >
        <v-row>
            <v-col cols="12" md="9" :style="{height: 'auto',minHeight: isMobile ? '300px' : (isFixedBanner ? '420px' : '100vh')}" class="d-flex flex-column align-items-center px-0 mt-0">
                <v-row class="fixed-height-image-container pa-2 px-5 mx-0">
                    <template v-if="mode==='edit'">
                        <v-img v-if="imageSrc && imageSrc !== ''" :src="imageSrc" :style="{height: 'auto', maxWidth: '800px', maxHeight: isMobile ? '300px' : '95vh'}"></v-img>
                        <image-slider :processing="processing" :upscaling="upscaling" :is-mobile="isMobile" @applyPrompt="applyPrompt" v-else/>
                    </template>
                    <template v-else>
                        <v-img v-if="imageSrc && imageSrc !== ''" :src="imageSrc" :style="{height: 'auto', maxWidth: '800px', maxHeight: isMobile ? '300px' : '95vh'}"></v-img>
                        <!--                        <upscaled-images-container :image-src="imageSrc" :is-mobile="isFixedBanner" :upscaled="upscaled" :parent-id="imageId"/>-->
                    </template>
                </v-row>
            </v-col>
            <v-col cols="12" md="3" class="options-bar" style="background-color: white;">
                <div style="display: flex; justify-content: start">
                    <v-card-title class="my-0 pb-0 pt-1">이미지 설명</v-card-title>
                    <v-switch density="compact" class="py-0 my-0" :readonly="processing || mode === 'view'" :label="options.lang === 'ko' ? '한글' : '영문'" v-model="options.lang" false-value="ko" value="en"></v-switch>
                </div>
                <v-textarea
                    style="height: 300px;"
                    v-model="prompt"
                    :placeholder="mode === 'view' ? '' : (options.lang === 'ko' ? '무엇을 그릴까요? 자세할수록 좋아요' : 'What should we draw? The more details, the better.')"
                    density="compact"
                    :clearable="!(processing || mode === 'view')"
                    clear-icon="mdi-close-circle"
                    rows="3"
                    :readonly="processing || mode === 'view'"
                    class="prompt-input my-textarea my-0 py-0 px-3"
                ></v-textarea>
                <div style="display: flex; justify-content: start">
                    <v-card-title class="my-0 py-0 px-5">포함시키지 않을 항목</v-card-title>
                    <span style="color:grey;" class="ml-0 pl-0 my-0 py-0 pt-1">{{options.lang === 'ko' ? '&lt;한글&gt;' : '&lt;영문&gt;'}}</span>
                </div>
                <v-textarea
                    style="height: 300px;"
                    v-model="options.negative"
                    :placeholder="mode === 'view' ? '' : (options.lang === 'ko' ? '무엇을 그릴까요? 자세할수록 좋아요' : 'What should we draw? The more details, the better.')"
                    density="compact"
                    :clearable="!(processing || mode === 'view')"
                    clear-icon="mdi-close-circle"
                    rows="3"
                    :readonly="processing || mode === 'view'"
                    class="prompt-input my-textarea my-0 py-0 px-3"
                ></v-textarea>
            </v-col>
            <!-- 옵션을 표시하는 컬럼 -->
<!--            <v-col cols="12" md="3" class="options-bar" style="background-color: white;">-->
<!--                <template v-if="options.blending">-->
<!--                    <div style="display: flex; justify-content: start">-->
<!--                        <v-card-title class="my-0 pb-0 pt-1">이미지 비율</v-card-title>-->
<!--                    </div>-->
<!--                    <div class="py-0 my-0 chip-container" ref="chipContainer">-->
<!--                        <v-col style="width:75px;height: 75px;" v-for="(ratio, index) in blendingRatios" @click="selectRatio(ratio.text)" :key="index" class="chip" >-->
<!--                            <v-img :src="ratio.img" :style="{height:'100%',width:'100%',cursor:'pointer', border: selectedRatio == ratio.text ? 'solid orangered 5px' : '', borderRadius: '5px'}" contain></v-img>-->
<!--                        </v-col>-->
<!--                    </div>-->
<!--                </template>-->
<!--                <template v-else>-->
<!--                    <div style="display: flex; justify-content: start">-->
<!--                        <v-card-title class="my-0 pb-0 pt-1">이미지 비율</v-card-title>-->
<!--                        <v-switch style="height:40px;" density="compact" class="py-0 my-0" :readonly="processing || mode === 'view'" :label="options.arType === 'custom' ? '직접입력' : '프리셋'" v-model="options.arType" false-value="preset" value="custom"></v-switch>-->
<!--                    </div>-->
<!--                    <v-text-field label="'16:9'와 같은 형태로 입력하세요" class="mt-2 mb-0 py-0 px-5" density="compact" v-if="options.arType === 'custom'" v-model="customAr"/>-->
<!--                    <div v-else class="py-0 my-0 chip-container" ref="chipContainer">-->
<!--                        <v-col style="width:75px;height: 75px;" v-for="(ratio, index) in ratios" @click="selectRatio(ratio.text)" :key="index" class="chip" >-->
<!--                            <v-img :src="ratio.img" :style="{height:'100%',width:'100%',cursor:'pointer', border: selectedRatio == ratio.text ? 'solid orangered 5px' : '', borderRadius: '5px'}" contain></v-img>-->
<!--                        </v-col>-->
<!--                    </div>-->
<!--                </template>-->
<!--                <v-card-title class="my-0 py-0 font-weight-bold" style="color: red;">블렌딩</v-card-title>-->
<!--                <v-checkbox-->
<!--                    v-model="options.blending"-->
<!--                    label="이미지 2장 합치기"-->
<!--                    persistent-hint-->
<!--                    density="compact"-->
<!--                    :value="true"-->
<!--                    :false-value="false"-->
<!--                    :readonly="processing || mode === 'view'"-->
<!--                    class="my-0 py-0 mt-1"-->
<!--                ></v-checkbox>-->
<!--&lt;!&ndash;                <v-card-title class="my-0 py-0">연관성</v-card-title>&ndash;&gt;-->
<!--&lt;!&ndash;                <v-checkbox&ndash;&gt;-->
<!--&lt;!&ndash;                    v-model="options.chaos"&ndash;&gt;-->
<!--&lt;!&ndash;                    label="비슷한 이미지로 4장 만들래요."&ndash;&gt;-->
<!--&lt;!&ndash;                    persistent-hint&ndash;&gt;-->
<!--&lt;!&ndash;                    density="compact"&ndash;&gt;-->
<!--&lt;!&ndash;                    :value="0"&ndash;&gt;-->
<!--&lt;!&ndash;                    :false-value="100"&ndash;&gt;-->
<!--&lt;!&ndash;                    :readonly="processing || mode === 'view'"&ndash;&gt;-->
<!--&lt;!&ndash;                    class="my-0 py-0 mt-1"&ndash;&gt;-->
<!--&lt;!&ndash;                ></v-checkbox>&ndash;&gt;-->
<!--                <template v-if="options.blending">-->
<!--                    <v-card-title>이미지 2장 선택하기-->
<!--                        <v-tooltip v-if="mode==='edit' && !processing" text="파일 선택">-->
<!--                            <template v-slot:activator="{ props }">-->
<!--                                <v-btn v-bind="props" class="py-0 my-0" density="compact" color="red" icon @click="pickFile('blending')"><v-icon size="small" color="white">mdi-image-plus</v-icon></v-btn>-->
<!--                            </template>-->
<!--                        </v-tooltip>-->
<!--                    </v-card-title>-->
<!--                    <input type="file" ref="fileInput" @change="uploadFile" style="display: none" accept=".png, .jpg, .jpeg" />-->
<!--                    &lt;!&ndash; 이미지 미리보기 &ndash;&gt;-->
<!--                    <v-card-text class="mt-0 pt-0" style="color: darkred"> - 이미지 2장을 업로드하세요.</v-card-text>-->
<!--                    <div v-if="mode === 'edit' || (detailImage && detailImage.promptImage && detailImage.promptImage !== '')" style="display: flex; flex-wrap: wrap; justify-content: flex-start; align-items: center;">-->
<!--                        <v-col style="position:relative; min-width: 100px; min-height: 100px;" v-for="(image, idx) in uploadedImages" cols="4" :key="idx">-->
<!--                            <v-tooltip v-if="mode==='edit' && !processing" text="삭제">-->
<!--                                <template v-slot:activator="{ props }">-->
<!--                                    <v-btn v-bind="props" color="#171717" style="z-index: 199; position: absolute; top: 0; right: 0;" @click="removeImage(image)" icon density="compact"><v-icon color="white" size="xsmall">mdi-close</v-icon></v-btn>-->
<!--                                </template>-->
<!--                            </v-tooltip>-->
<!--                            <v-img :src="image" style="border: solid #696969 3px; border-radius: 10px; width: 100%; height: auto;" contain></v-img>-->
<!--                        </v-col>-->
<!--                    </div>-->
<!--                </template>-->
<!--                <template v-else>-->
<!--                    <v-card-title-->
<!--                        class="my-0 py-0">타일 디자인</v-card-title>-->
<!--                    <v-checkbox-->
<!--                        v-model="options.tile"-->
<!--                        label="타일 디자인을 생성할래요."-->
<!--                        density="compact"-->
<!--                        persistent-hint-->
<!--                        :value="true"-->
<!--                        :false-value="false"-->
<!--                        :readonly="processing || mode === 'view'"-->
<!--                        class="my-0 py-0 mt-1"-->
<!--                    ></v-checkbox>-->
<!--                    <v-card-title>이미지 프롬프트-->
<!--                        <v-tooltip v-if="mode==='edit' && !processing" text="파일 선택">-->
<!--                            <template v-slot:activator="{ props }">-->
<!--                                <v-btn v-bind="props" class="py-0 my-0" density="compact" color="primary" icon @click="pickFile('prompt')"><v-icon size="small" color="white">mdi-image-plus</v-icon></v-btn>-->
<!--                            </template>-->
<!--                        </v-tooltip>-->
<!--                    </v-card-title>-->
<!--                    <input type="file" ref="fileInput" @change="uploadFile" style="display: none" accept=".png, .jpg, .jpeg" />-->
<!--                    &lt;!&ndash; 이미지 미리보기 &ndash;&gt;-->
<!--                    <v-card-text class="mt-0 pt-0" style="color: dodgerblue"> - 기존 이미지를 이용해서 만들고 싶을 경우 업로드 하세요.</v-card-text>-->
<!--                    <div v-if="mode === 'edit' || (detailImage && detailImage.promptImage && detailImage.promptImage !== '')" style="display: flex; flex-wrap: wrap; justify-content: flex-start; align-items: center;">-->
<!--                        <v-col style="position:relative; min-width: 100px; min-height: 100px;" v-for="(image, idx) in uploadedImages" cols="4" :key="idx">-->
<!--                            <v-tooltip v-if="mode==='edit' && !processing" text="삭제">-->
<!--                                <template v-slot:activator="{ props }">-->
<!--                                    <v-btn v-bind="props" color="#171717" style="z-index: 199; position: absolute; top: 0; right: 0;" @click="removeImage(image)" icon density="compact"><v-icon color="white" size="xsmall">mdi-close</v-icon></v-btn>-->
<!--                                </template>-->
<!--                            </v-tooltip>-->
<!--                            <v-img :src="image" style="border: solid #696969 3px; border-radius: 10px; width: 100%; height: auto;" contain></v-img>-->
<!--                        </v-col>-->
<!--                    </div>-->
<!--                    <v-card-title>캐릭터 레퍼런스-->
<!--                        <v-tooltip v-if="mode==='edit' && !processing" text="파일 선택">-->
<!--                            <template v-slot:activator="{ props }">-->
<!--                                <v-btn v-bind="props" class="py-0 my-0" density="compact" color="black" icon @click="pickFile('cref')"><v-icon size="small" color="white">mdi-image-plus</v-icon></v-btn>-->
<!--                            </template>-->
<!--                        </v-tooltip>-->
<!--                    </v-card-title>-->
<!--                    <v-card-text class="mt-0 pt-0" style="color: dodgerblue"> - 캐릭터 레퍼런스를 사용하려면 업로드 하세요.</v-card-text>-->
<!--                    <div v-if="mode === 'edit' || (detailImage && detailImage.cref && detailImage.cref !== '')" style="display: flex; flex-wrap: wrap; justify-content: flex-start; align-items: center;">-->
<!--                        <v-col style="position:relative; min-width: 100px; min-height: 100px;" v-for="(image, idx) in uploadedCrefImages" cols="4" :key="idx">-->
<!--                            <v-tooltip v-if="mode==='edit' && !processing" text="삭제">-->
<!--                                <template v-slot:activator="{ props }">-->
<!--                                    <v-btn v-bind="props" color="#171717" style="z-index: 199; position: absolute; top: 0; right: 0;" @click="removeCrefImage(image)" icon density="compact"><v-icon color="white" size="xsmall">mdi-close</v-icon></v-btn>-->
<!--                                </template>-->
<!--                            </v-tooltip>-->
<!--                            <v-img :src="image" style="border: solid #696969 3px; border-radius: 10px; width: 100%; height: auto;" contain></v-img>-->
<!--                        </v-col>-->
<!--                    </div>-->
<!--                    <v-card-title>스타일 레퍼런스-->
<!--                        <v-tooltip v-if="mode==='edit' && !processing" text="파일 선택">-->
<!--                            <template v-slot:activator="{ props }">-->
<!--                                <v-btn v-bind="props" class="py-0 my-0" density="compact" color="red" icon @click="pickFile('sref')"><v-icon size="small" color="white">mdi-image-plus</v-icon></v-btn>-->
<!--                            </template>-->
<!--                        </v-tooltip>-->
<!--                    </v-card-title>-->
<!--                    <v-card-text class="mt-0 pt-0" style="color: dodgerblue"> - 스타일 레퍼런스를 사용하려면 업로드 하세요.</v-card-text>-->
<!--                    <div v-if="mode === 'edit' || (detailImage && detailImage.sref && detailImage.sref !== '')" style="display: flex; flex-wrap: wrap; justify-content: flex-start; align-items: center;">-->
<!--                        <v-col style="position:relative; min-width: 100px; min-height: 100px;" v-for="(image, idx) in uploadedSrefImages" cols="4" :key="idx">-->
<!--                            <v-tooltip v-if="mode==='edit' && !processing" text="삭제">-->
<!--                                <template v-slot:activator="{ props }">-->
<!--                                    <v-btn v-bind="props" color="#171717" style="z-index: 199; position: absolute; top: 0; right: 0;" @click="removeSrefImage(image)" icon density="compact"><v-icon color="white" size="xsmall">mdi-close</v-icon></v-btn>-->
<!--                                </template>-->
<!--                            </v-tooltip>-->
<!--                            <v-img :src="image" style="border: solid #696969 3px; border-radius: 10px; width: 100%; height: auto;" contain></v-img>-->
<!--                        </v-col>-->
<!--                    </div>-->
<!--                    <div style="display: flex; justify-content: start">-->
<!--                        <v-card-title class="my-0 pb-0 pt-1">이미지 설명</v-card-title>-->
<!--                        <v-switch density="compact" class="py-0 my-0" :readonly="processing || mode === 'view'" :label="options.lang === 'ko' ? '한글' : '영문'" v-model="options.lang" false-value="ko" value="en"></v-switch>-->
<!--                    </div>-->
<!--                    <v-textarea-->
<!--                        v-model="prompt"-->
<!--                        :placeholder="mode === 'view' ? '' : (options.lang === 'ko' ? '무엇을 그릴까요? 자세할수록 좋아요' : 'What should we draw? The more details, the better.')"-->
<!--                        density="compact"-->
<!--                        :clearable="!(processing || mode === 'view')"-->
<!--                        clear-icon="mdi-close-circle"-->
<!--                        rows="3"-->
<!--                        :readonly="processing || mode === 'view'"-->
<!--                        class="prompt-input my-textarea my-0 py-0 px-3"-->
<!--                    ></v-textarea>-->
<!--                    <div style="display: flex; justify-content: start">-->
<!--                        <v-card-title class="my-0 py-0 px-5">포함시키지 않을 항목</v-card-title>-->
<!--                        <span style="color:grey;" class="ml-0 pl-0 my-0 py-0 pt-1">{{options.lang === 'ko' ? '&lt;한글&gt;' : '&lt;영문&gt;'}}</span>-->
<!--                    </div>-->
<!--                    <v-text-field-->
<!--                        v-model="options.negative"-->
<!--                        :clearable="!(processing || mode === 'view')"-->
<!--                        :placeholder="mode === 'view' && options.negative ? '' : (options.lang === 'ko' ? '이미지에 포함하지 않을 항목을 입력해요' : 'Enter the items that you do not want to include in the image.')"-->
<!--                        class="py-0 my-0 px-5"-->
<!--                        :readonly="processing || mode === 'view'"-->
<!--                    ></v-text-field>-->
<!--                </template>-->
<!--                <v-btn v-if="mode === 'edit'" @click="creation" :disabled="processing || mode === 'view'" width="100%" height="50" style="font-size: 24px;" color="orange" class="font-weight-bold mb-2">-->
<!--                    <span v-if="processing" class="font-weight-bold"><v-icon size="small" class="mb-1 ma-1" color="black">mdi-creation</v-icon>이미지 생성중</span>-->
<!--                    <span v-else class="breathing-text font-weight-bold" style="font-size: 30px; color: white"><v-icon size="small" class="mb-1 ma-1" color="white">mdi-creation</v-icon>{{creationText}}</span>-->
<!--                </v-btn>-->
<!--                <v-btn v-else-if="mode === 'view'" @click="enableCreation" width="100%" height="50" style="font-size: 24px;" color="pink" class="font-weight-bold mb-2" append-icon="mdi-image-edit">다시 생성하기</v-btn>-->
<!--                <div style="height:15px;" class="py-0 my-0"><span style="font-size: 11px;color:dimgrey;">※ 생성 된 이미지는 기본적으로 본인만 볼 수 있습니다.</span></div>-->
<!--                <div style="height:15px;" class="py-0 my-0"><span style="font-size: 11px;color:dimgrey;">※ 주토피아에서 생성한 이미지에 대한 저작권은 자유입니다.</span></div>-->
<!--                <div style="height:15px;" class="py-0 my-0"><span style="font-size: 11px;color:dimgrey;">※ 이미지 사용에 대한 모든 책임은 본인에게 있습니다.</span></div>-->
<!--            </v-col>-->
<!--        </v-row>-->
<!--        <v-tooltip text="목록">-->
<!--            <template v-slot:activator="{ props }">-->
<!--                <v-btn v-bind="props" class="fixed-btn" style="background-color: rgba(31,31,31,0.7)" icon @click="moveToList"><v-icon class="pb-2" color="white">mdi-arrow-left</v-icon></v-btn>-->
<!--            </template>-->
<!--        </v-tooltip>-->
        </v-row>
        <alert-dialog ref="alert"/>
        <confirm-dialog ref="confirm"/>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
// import UpscaledImagesContainer from "@/components/image/UpscaledImagesContainer.vue";
import AlertDialog from "@/dialogs/AlertDialog.vue";
import ImageSlider from "@/components/image/ImageSlider.vue";
import ConfirmDialog from "@/dialogs/ConfirmDialog.vue";
import moment from "moment";

export default {
    components: {ConfirmDialog, /*UpscaledImagesContainer*/ AlertDialog, ImageSlider},
    data() {
        return {
            imageUrl: '', // 선택된 파일 이름
            uploadedImages: [],
            uploadedCrefImages: [],
            uploadedSrefImages: [],
            mode: 'edit',
            baseUrl: 'https://dorosi.ai/image/',
            // baseUrl: 'https://cl.imagineapi.dev/assets/',
            imageSrc: null,
            prompt: '',
            polling: null,
            imageSize: { width: 1920, height: 1080 }, // 이미지 크기 초기값
            processing: false,
            prevSize: {},
            imageId: null,
            containerWidth: null,
            selectedItem: null,
            hoverIndex: null,
            progressMessages: [
                "캔버스 준비 완료! 작업 시작.",
                "기본 스케치 완성.",
                "색상 입히는 중.",
                "세부 디테일 추가 중.",
                "마무리 층 칠하는 중.",
                "빛과 그림자 조정 중.",
                "조금만 더 기다려 주세요...",
                "곧 보여드릴께요! 영차 영차...",
            ],
            customAr: '16:9',
            options: {
                chaos: 0,
                lang: 'ko',
                blending: false,
                resolution: {
                    width: 16,
                    height: 9,
                },
                polling: null,
                ar: null,
                resolutions: [
                    { text: '16:9', width: '', height: '' },
                    { text: '9:16', width: '', height: '' },
                    { text: '1:1', width: '', height: '' },
                    { text: '4:3', width: '', height: '' },
                    { text: '3:4', width: '', height: '' },
                ],
                arType: 'preset',
                descriptor: '',
                tile: false,
                negative: '',
            },
            selectedRatio: null,
            ratios: [
                { text: '16:9', ratio: 16 / 9 , img: '169.webp'},
                { text: '6:4', ratio: 6 / 4 , img: '64.webp'},
                { text: '1:1', ratio: 1 / 1 , img: '11.webp'},
                { text: '4:6', ratio: 4 / 6 , img: '46.webp'},
                { text: '9:16', ratio: 9 / 16 , img: '916.webp'},
            ],
            blendingRatios: [
                { text: '3:2', ratio: 6 / 4 , img: '64.webp'},
                { text: '1:1', ratio: 1 / 1 , img: '11.webp'},
                { text: '2:3', ratio: 4 / 6 , img: '46.webp'},
            ],
            upscaled: [],
            copying: false,
            imageStatus: [],
            imageStatusCheckCnt: 0,
            uploadType: null,
            upscaling: false,
        };
    },
    computed: {
        ...mapGetters(['statusCheck', 'detailImage', 'reload', 'role', 'tabletEnd']),
        isMobile() {
            return this.containerWidth < 640;
        },
        isFixedBanner() {
            return this.containerWidth < 960;
        },
        creationText() {
            const {processing, options} = this;
            const { blending } = options;
            if (processing) return '이미지 생성중 ...';
            if (blending) return '이미지 합치기';
            return '이미지 생성';
        },
    },
    async beforeUnmount() {
        window.removeEventListener('resize', this.updateContainerWidth);
        await this.$store.dispatch('setInCreator', {flag: false});
        clearTimeout(this.polling);
        this.scrollToTop();
    },
    methods: {
        scrollToTop(behavior) {
            window.scrollTo({top: 0, behavior});
        },
        scrollToBottom() {
            window.scrollTo({top: document.body.scrollHeight, left: 0, behavior: 'smooth'});
        },
        pickFile(type) {
            if (this.uploadedImages.length >= 10) {
                this.$refs.alert.open('이미지 업로드는 10장까지만 가능해요.');
                return;
            }
            if (type === 'blending' && this.uploadedImages.length >= 2) {
                this.$refs.alert.open('이미지 2장이 이미 존재합니다.');
                return;
            }
            this.uploadType = type;
            this.$refs.fileInput.click(); // 파일 입력을 위한 숨겨진 input 클릭
        },
        applyPrompt(image) {
            this.setImagePrompt(image, true);
            if (this.isFixedBanner) this.scrollToBottom();
        },
        async uploadFile(event) {
            const files = event.target.files;
            if (files.length > 0) {
                const formData = new FormData();
                formData.append('file', files[0]);

                try {
                    const response = await this.$store.dispatch('uploadImage', formData);
                    const { uploadType } = this;
                    switch(uploadType) {
                        case 'cref': this.uploadedCrefImages.push(response.data); break;
                        case 'sref': this.uploadedSrefImages.push(response.data); break;
                        case 'prompt','blending' : this.uploadedImages.push(response.data); break;
                    }
                    this.$refs.fileInput.value = '';
                } catch (error) {
                    console.error('업로드 중 오류 발생:', error);
                }
            }
        },
        removeImage(url) {
            this.uploadedImages = this.uploadedImages.filter((img) => img !== url);
        },
        removeCrefImage(url) {
            this.uploadedCrefImages = this.uploadedCrefImages.filter((img) => img !== url);
        },
        removeSrefImage(url) {
            this.uploadedSrefImages = this.uploadedSrefImages.filter((img) => img !== url);
        },
        selectRatio(ratio) {
            if (this.mode === 'view' || this.processing) return;
            if (this.selectedRatio === ratio) this.selectedRatio = null;
            else this.selectedRatio = ratio;
        },
        updateContainerWidth() {
            this.containerWidth = window.innerWidth;
        },
        pollStatusCheckImage(id) {
            this.polling = setTimeout(async () => {
                await this.$store.dispatch('statusCheckImage', {id}); // 액션 호출

                if (this.statusCheck.status === 'failed') {
                    this.$refs.alert.open('이미지 생성에 실패했어요.<br/>혹시 이미지 생성 규정에 위배된 것이 아닌지 확인해주세요.<br/>사용한 캔버스는 돌려드렸어요.<br/>불편을 드려서 죄송해요!😂', async () => {
                        await this.$store.dispatch('reStoreCanvas');
                        await this.$store.dispatch('deleteImage', {id});
                        this.$router.replace({name: 'MyHome'});
                    });
                    clearTimeout(this.polling);
                } else if (this.statusCheck.status === 'reject') {
                    this.$refs.alert.open('이미지 생성 규정에 맞지 않아요.<br/>성적인 주제나, 폭력, 잔인한 주제 등은 불가해요.<br/>사용한 캔버스는 돌려드렸어요.<br/>반복적으로 시도하면 이미지 생성이 정지될 수 있어요.', async () => {
                        await this.$store.dispatch('reStoreCanvas');
                        await this.$store.dispatch('deleteImage', {id});
                        this.$router.replace({name: 'MyHome'});
                    });
                    clearTimeout(this.polling);
                } else if (this.statusCheck.status !== 'COMPLETED' && (!this.statusCheck.upscaled || this.statusCheck.upscaled.length === 0 || this.statusCheck.upscaled[0] === '')) {
                    // if (this.statusCheck.url) this.imageSrc = `https://dorosi.ai/assets/${this.statusCheck.url.split('/').pop()}`;
                    if (this.statusCheck.url && this.imageSrc !== this.statusCheck.url) this.imageSrc = this.statusCheck.url;
                    this.pollStatusCheckImage(id);
                } else if(this.statusCheck.status === 'COMPLETED' && this.statusCheck.upscaled.length < 4 || this.statusCheck.upscaled.filter((d)=>d==='').length > 0) {
                    if (this.statusCheck.url && this.imageSrc !== this.statusCheck.url) this.imageSrc = this.statusCheck.url;
                    this.upscaling = true;
                    this.statusCheck.upscaled.filter((d)=>d!=='').forEach((d)=>{
                        if(this.imageStatus.filter((v)=>v === `${this.baseUrl}${d}.webp`).length === 0) {
                            this.checkImageStatus(`${this.baseUrl}${d}.webp`);
                        }
                    });
                    this.pollStatusCheckImage(id);
                } else {
                    this.$store.dispatch('startLoading');
                    this.imageStatus = [];
                    this.imageStatusCheckCnt = 0;
                    clearTimeout(this.polling);
                    this.statusCheck.upscaled.forEach((d)=>{
                        if(this.imageStatus.filter((v)=>v === `${this.baseUrl}${d}.webp`).length === 0) {
                            this.checkImageStatus(`${this.baseUrl}${d}.webp`);
                        }
                    });
                    setTimeout(()=>this.checkAllImageLoaded(id), 1000);
                }
            }, 2000);
        },
        async checkAllImageLoaded(id) {
            await this.$store.dispatch('fetchDetailImage', {id});
            if(this.imageStatus.length >= 4 || this.imageStatusCheckCnt >= 12) {
                this.upscaled = [
                    {url: `${this.baseUrl}${this.statusCheck.upscaled[0]}.webp`, retryCount: 0},
                    {url: `${this.baseUrl}${this.statusCheck.upscaled[1]}.webp`, retryCount: 0},
                    {url: `${this.baseUrl}${this.statusCheck.upscaled[2]}.webp`, retryCount: 0},
                    {url: `${this.baseUrl}${this.statusCheck.upscaled[3]}.webp`, retryCount: 0},
                ]
                await this.$store.dispatch('fetchDetailImage', {id});
                this.mode = 'view';
                this.processing = false;
                this.$router.replace({query: {...this.$route.query, id}});
                this.$store.dispatch('setAnyInProgress', {flag: false});
                this.$store.dispatch('stopLoading');
            } else {
                this.imageStatusCheckCnt += 1;
                setTimeout(()=>this.checkAllImageLoaded(id), 1000);
            }
        },
        setImagePrompt(image, ignoreUrl) {
            if (!ignoreUrl) this.imageSrc = image.url;
            if (['16:9', '6:4', '1:1', '4:6', '9:16'].includes(image.ar)) {
                this.options.arType = 'preset';
                this.selectedRatio = image.ar;
            } else {
                this.options.arType = 'custom';
                this.customAr = image.ar;
            }
            this.prompt = image.promptKr;
            this.options.negative = image.negative;

            if (image.promptImage) this.uploadedImages = image.promptImage.split(' ').map((img)=>`${this.baseUrl}${img}`);
            else this.uploadedImages = [];

            if (image.cref) this.uploadedCrefImages = image.cref.split(' ').map((img)=>`${this.baseUrl}${img}`);
            else this.uploadedCrefImages = [];

            if (image.sref) this.uploadedSrefImages = image.sref.split(' ').map((img)=>`${this.baseUrl}${img}`);
            else this.uploadedSrefImages = [];

            this.options.tile = image.tile;
            this.options.lang = image.lang;
            this.options.chaos = image.chaos;
            this.options.blending = image.blending;
            this.imageId = image.id;
        },
        enableCreation() {
            this.moveStoreIfRequired(() => {
                this.mode = 'edit';
                this.upscaling = false;
                this.$router.replace({query: {}});
                clearTimeout(this.polling);
                this.setImagePrompt(this.detailImage, false);
                this.scrollToTop('smooth');
            });
        },
        validateInput() {
            const {selectedRatio, prompt, uploadedImages, options} = this;
            const { blending } = options;
            if (options.arType === 'custom') {
                const {customAr} = this;
                const pattern = /^(1?[0-9]{1,2}|200):(1?[0-9]{1,2}|200)$/;
                const arValid = pattern.test(customAr);
                if (!arValid) return '비율이 올바르지 않아요.<br/>비율은 1:1 , 16:9 등과 같아야 해요.<br/>1~200 사이의 숫자만 사용 가능해요!🤣';
            } else {
                if (!selectedRatio || selectedRatio === '') return '비율을 선택하세요.<br/>선택한 비율대로 이미지가 생성 됩니다!🤣';
            }
            if (!blending && (!prompt || prompt === '') && (!uploadedImages || uploadedImages.length < 2)) return '이미지를 설명해주세요.<br/>원하시는 그림이 있나요? 뭐든지 상상해봐요!🥰';
            if(blending && uploadedImages.length < 2) return '블렌딩은 이미지 2장 이상 필요합니다.';
            if(blending && uploadedImages.length > 5) return '블렌딩은 이미지 5장까지 사용 가능합니다.';

            return null;
        },
        async creation() {
            if(this.role !== 'ADMIN') {
                const creationStatus = await this.$store.dispatch('creationStatus');
                if (creationStatus.data !== 'READY') {
                    this.$refs.alert.open('이미 생성 중인 이미지가 있습니다.');
                    return;
                }
            }

            const validate = this.validateInput();
            if (validate) {
                this.$refs.alert.open(validate);
                return;
            }
            const {tile, negative, chaos, lang, arType, blending} = this.options;
            const {selectedRatio, customAr, uploadedImages, uploadedCrefImages, uploadedSrefImages} = this;
            const payload = {
                type: 'CUSTOM',
                descriptor: this.prompt,
                ar: arType === 'custom' ? customAr : selectedRatio,
                negative,
                lang,
                tile,
                chaos,
                blending,
                cref: uploadedCrefImages,
                sref: uploadedSrefImages,
                images: uploadedImages
            };

            this.processing = true;
            this.imageSrc = null;
            const result = await this.$store.dispatch('creation', payload);

            if (result.id === -1) {
                if (result.msg === 'banned') {
                    this.$refs.alert.open(`현재 이용 정지 상태로, 이미지를 생성할 수 없어요. 문제라고 생각되면 지원 센터로 문의해 주세요!`, () => {
                        this.$router.replace({name: 'MyHome'});
                    });
                } else if (result.msg === 'no-canvas') {
                    this.$refs.confirm.open(`캔버스가 부족해요. 캔버스를 구매하러 이동하시겠어요?`, () => {
                        this.$router.replace({name: 'CanvasStore'});
                    }, () => {
                        this.$router.replace({name: 'MyHome'});
                    });
                } else {
                    this.$refs.alert.open(`오류가 발생했어요. 잠시 후 다시 시도해주세요.!`, () => {
                        this.$router.replace({name: 'MyHome'});
                    });
                }
                return;
            }

            this.imageId = result.id;
            this.pollStatusCheckImage(result.id);
            this.$store.dispatch('setAnyInProgress', {flag: true});
            this.scrollToTop('smooth');
        },
        moveToList() {
            clearTimeout(this.polling);
            this.$store.dispatch('startLoading');
            setTimeout(async () => {
                await this.$store.dispatch('stopLoading');
                await this.$store.dispatch('setFromCreator', {flag: true});
                this.$router.replace({name: this.copying ? 'ImageHome' : 'MyHome'})
            }, 100);
        },
        moveStoreIfRequired(block) {
            const { canvases } = this;
            if(canvases <= 0 && !this.isDateValidAndFuture(this.tabletEnd)) {
                this.$refs.confirm.open(`캔버스가 부족해요. 캔버스를 구매하러 이동하시겠어요?`, () => {
                    this.$router.push({name: 'CanvasStore'})
                });
            } else {
                if(block) block();
            }
        },
        isDateValidAndFuture(date) {
            if(!date) return false;

            const today = moment().startOf('day'); // 오늘 날짜를 'yyyy-MM-dd' 형식으로 가져옴, 시간은 00:00으로 설정
            const inputDate = moment(date, 'YYYY-MM-DD'); // 입력된 날짜를 moment 객체로 변환

            return inputDate.isSameOrAfter(today); // 입력된 날짜가 오늘 또는 미래 날짜인지 확인
        },
        checkImageStatus(url) {
            fetch(url)
                .then((response) => {
                    if (response.status !== 200) {
                        setTimeout(this.checkImageStatus, 1000);
                    } else {
                        this.imageStatus.push(url);
                    }
                })
                .catch((error) => {
                    console.error('이미지 로드에 실패했습니다:', error);
                    setTimeout(this.checkImageStatus, 1000);
                });
        },
    },
    async mounted() {
        await this.$store.dispatch('setInCreator', {flag: true});
        this.updateContainerWidth();
        window.addEventListener('resize', this.updateContainerWidth);
        // options.value.creating = true; // 필요에 따라 사용
        const query = this.$route.query;
        if (query.id) {
            await this.$store.dispatch('fetchDetailImage', {id: query.id});
            const detail = this.detailImage;
            this.imageId = query.id;
            if (detail.status === 'COMPLETED') {
                this.detailImage = detail;
                this.setImagePrompt(detail, false);
                this.upscaled = [
                    {url: `${this.baseUrl}${detail.upscaled[0]}.webp`, retryCount: 0},
                    {url: `${this.baseUrl}${detail.upscaled[1]}.webp`, retryCount: 0},
                    {url: `${this.baseUrl}${detail.upscaled[2]}.webp`, retryCount: 0},
                    {url: `${this.baseUrl}${detail.upscaled[3]}.webp`, retryCount: 0},
                ];
                this.mode = 'view';
                if (query.command === 'copy') {
                    const url = query.url;
                    if (url) this.imageSrc = url;
                    this.copying = true;
                    this.mode = 'edit';
                    this.upscaling = false;
                    this.$router.replace({query: {}});
                }
            } else if (['pending', 'in-progress', 'ready'].includes(detail.status)) {
                this.mode = 'edit';
                this.upscaling = false;
                this.detailImage = detail;

                if(detail.url && detail.url !== '') this.imageSrc = `https://dorosi.ai/assets/${detail.url.split('/').pop()}`;
                else this.imageSrc = null;

                this.setImagePrompt(detail, true);
                this.processing = true;
                this.pollStatusCheckImage(detail.id);
            } else if (['failed', 'reject'].includes(detail.status)) {
                this.$refs.alert.open('이미지 생성에 실패했어요.<br/>혹시 이미지 생성 규정에 위배된 것이 아닌지 확인해주세요.<br/>사용한 캔버스는 돌려드렸어요.<br/>불편을 드려서 죄송해요!😂', async () => {
                    await this.$store.dispatch('deleteImage', {id: detail.id});
                    this.$router.replace({name: 'MyHome'});
                });
            }
        }
        this.scrollToTop('smooth');
    }
};
</script>
<style scoped>
.fixed-height-image-container {
    width: 100%; /* 컨테이너의 너비는 부모 요소에 맞춤 */
    height: auto;
    display: flex;
    justify-content: center;
    background-color: #1c1b1b;
    align-items: center;
    border-radius: 10px;
    overflow: hidden; /* 컨테이너 밖으로 나가는 이미지 숨김 */
}

.prompt-input {
    width: 100%;
}

.chip-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.chip {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 130px; /* 정사각형의 고정 높이 */
}

.fixed-btn {
    position: fixed; /* 고정 위치 */
    bottom: 20px; /* 하단에서 20px 떨어진 위치 */
    right: 20px; /* 우측에서 20px 떨어진 위치 */
    z-index: 199; /* z-index 설정 */
    border: none; /* 테두리 제거 */
    outline: none; /* 외곽선 제거 */
    background-color: #007bff; /* 배경 색상 */
    color: white; /* 텍스트 색상 */
    cursor: pointer; /* 마우스 오버시 커서 변경 */
    padding: 15px; /* 패딩 */
    border-radius: 10px; /* 동그란 모양 */
    font-size: 18px; /* 폰트 크기 */
}

.breathing-text {
    font-size: 30px;
    color: #ffffff;
    text-align: center;
    animation: breathAnimation 1s ease-out infinite;
}

@keyframes breathAnimation {
    0%, 100% {
        text-shadow: none;
    }
    50% {
        text-shadow: 0 0 30px rgba(0, 0, 0, 0.44);
    }
}
</style>
