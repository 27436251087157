<template>
    <div class="content" @click="$store.dispatch('setShowMyInfo', {flag: false});$store.dispatch('setShowSupportInfo', {flag: false})">
        <template v-if="activeIcon === 1">
            <v-col style="display:flex;justify-content: end;">
<!--                <v-btn-->
<!--                    v-for="(icon, index) in rightIcons"-->
<!--                    :key="index"-->
<!--                    :class="{'active-icon': activeIcon === index}"-->
<!--                    @click="setActiveIcon(index)"-->
<!--                    class="tab-btn mx-1"-->
<!--                    text-->
<!--                    style="width:30px;height: 30px;"-->
<!--                    elevation="0"-->
<!--                >-->
<!--                    <v-icon>{{ icon }}</v-icon>-->
<!--                </v-btn>-->
                <img :src="`right_${activeIcon}.png`" style="cursor:pointer;" @click="setActiveIcon(activeIcon === 1 ? 0 : 1)"/>
            </v-col>
            <v-container fluid>
                <!-- 상단 영역 -->
                <v-row no-gutters>
                    <!-- 좌측 영역 -->
                    <v-col cols="9" style="user-select:none; text-align: center;" class="px-10">
                        <img
                            :src="focusedImage.url"
                            tabindex="-1"
                            style="user-drag: none; user-select: none!important; border-radius: 50px; max-width: 100%; max-height: 650px; object-fit: cover;"
                        />
                    </v-col>
                    <!-- 우측 영역 -->
                    <v-col cols="3" class="options-bar" style="height: 70vh; background-color: #171717;border-radius: 25px;">
                        <div class="popup-content">
                            <div class="mb-5" style="display: flex; width: 100%;">
                                <v-btn class="mx-2" style="width:50px;" @click="upscaleImage(focusedImage, 1)">U1</v-btn>
                                <v-btn class="mx-2" style="width:50px;" @click="upscaleImage(focusedImage, 2)">U2</v-btn>
                                <v-btn class="mx-2" style="width:50px;" @click="upscaleImage(focusedImage, 3)">U3</v-btn>
                                <v-btn class="mx-2" style="width:50px;" @click="upscaleImage(focusedImage, 4)">U4</v-btn>
                            </div>
                            <div class="prompt-header">
                                <span>프롬프트</span>
                                <v-btn depressed icon style="background-color: transparent" @click="copyToClipboard" density="compact" class="copy-btn">
                                    <v-icon color="white" density="compact">mdi-content-copy</v-icon>
                                </v-btn>
                            </div>
                            <div class="prompt-container">
                                <div class="prompt">{{ focusedImage.prompt }}</div>
                            </div>
                            <div class="popup-actions px-3">
                                <v-btn @click="downloadImage(focusedImage.url)" class="download-btn">
                                    <v-icon left>mdi-download</v-icon>
                                </v-btn>
                                <v-btn @click="remixImage(focusedImage)" class="remix-btn ml-2">
                                    Remix
                                </v-btn>
                                <v-btn @click="postDialogOpen(focusedImage.url, focusedImage.imageId)" class="post-btn ml-2">
                                    Post
                                </v-btn>
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <!-- 하단 영역 -->
                <transition name="slide">
                    <div
                        :class="{'image-scroll': true}"
                        :style="{marginLeft: isLeftPaneVisible ? '430px' : '30px', left: (isMobile && isLeftPaneVisible) ? '780px' : '0'}"
                        ref="horizontalScrollContainer"
                    >
                        <v-col cols="auto" class="spacer" v-if="isLeftPaneVisible"></v-col>
                        <template v-for="(imageGroup,i) in images" :key="i">
<!--                            <v-col cols="auto" class="image-wrapper">-->
<!--                                <span style="color:whitesmoke">{{ imageGroup.date }}</span>-->
<!--                            </v-col>-->
                            <v-col cols="auto" style="user-select: none;" class="image-wrapper" v-for="(image, idx) in imageGroup.images" :key="idx">
                                <span v-if="idx===0" :style="{fontWeight: image.url === focusedImage.url ? 'bold' : '', top: image.url === focusedImage.url ? '-10px' : '0'}" style="position: absolute; color:whitesmoke">{{ imageGroup.date }}</span>
                                <v-img
                                    :src="image.url"
                                    :style="{tabIndex: '-1', border: image.url === focusedImage.url ? 'lightgrey solid 2px' : ''}"
                                    @click="focusImage(image)"
                                    :class="image.url === focusedImage.url ? 'thumbnail-focused' : 'thumbnail'"
                                />
                            </v-col>
                        </template>
                    </div>
                </transition>
            </v-container>
        </template>
        <template v-else>
            <div ref="scrollContainer" :style="{height: 'calc(100% - 60px)', overflowY: 'auto', backgroundColor: '#171717'}" @click="$store.dispatch('setShowMyInfo', {flag: false});$store.dispatch('setShowSupportInfo', {flag: false})">
                <v-col style="display:flex;justify-content: end;">
<!--                    <v-btn-->
<!--                        v-for="(icon, index) in rightIcons"-->
<!--                        :key="index"-->
<!--                        :class="{'active-icon': activeIcon === index}"-->
<!--                        @click="setActiveIcon(index)"-->
<!--                        class="tab-btn mx-1"-->
<!--                        text-->
<!--                        style="width:30px;height: 30px;"-->
<!--                        elevation="0"-->
<!--                    >-->
<!--                        <v-icon>{{ icon }}</v-icon>-->
<!--                    </v-btn>-->
                    <img :src="`right_${activeIcon}.png`" style="cursor:pointer;" @click="setActiveIcon(activeIcon === 1 ? 0 : 1)"/>
                </v-col>
                <template v-if="images && images.length > 0">
                    <div style="display: flex; justify-content: center;" v-for="(image, i) in images" :key="i" class="mt-2">
                        <my-image-group :is-left-pane-visible="isLeftPaneVisible" :images="image" @focusImage="focusImage" :is-mobile="isMobile" :is-fixed-banner="isFixedBanner"/>
                    </div>
                </template>
                <template v-else>
                    <div style="display: flex; width: 100%; min-width: 330px; justify-content: center;" class="mt-5">
                        <div v-if="reload <= 10" :style="{backgroundColor: 'whitesmoke', display: 'flex', justifyContent: 'center', alignItems: 'center', border: 'dashed 3px lightgrey', borderRadius: '50px', flexDirection: 'column', justifyContent: 'center',alignItems: 'center', width: isFixedBanner ? '80%' : '50%', height: isFixedBanner ? '500px' : '700px'}">
                            <v-card-title style="color: orangered; font-weight: bold;">지금은 보여줄게 없어요 😉</v-card-title>
                            <div>
                                <img :style="`height: ${isMobile ? '250px' : '350px'}; width: ${isMobile ? '250px' : '350px'}; border: solid antiquewhite 10px; border-radius: 100px; object-fit: contain;`" src="character1.webp">
                            </div>
                        </div>
                        <div v-else style="width: 100%;height: 350px; background-color: #171717"/>
                    </div>
                </template>
            </div>
        </template>
        <post-image-dialog @posted="postedHandle" ref="postDialog"/>
        <confirm-dialog ref="confirm"/>
        <social-login-dialog ref="socialLoginDialog"/>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { nextTick } from 'vue';
import ConfirmDialog from "@/dialogs/ConfirmDialog.vue";
import SocialLoginDialog from "@/dialogs/SocialLoginDialog.vue";
import moment from "moment";
import MyImageGroup from "@/components/image/MyImageGroup.vue";
import PostImageDialog from "@/dialogs/PostImageDialog.vue";

export default {
    name: 'MyHome',
    components: {PostImageDialog, SocialLoginDialog, ConfirmDialog, MyImageGroup },
    props: { isLeftPaneVisible: Boolean, isFixedBanner: Boolean, isMobile: Boolean },
    data() {
        return {
            selectedImage: null,
            currentTab: null,
            tabs: ['Featured', 'Hot', 'Recent', 'Like'],
            isAnyImageHighlighted: false,
            visit: {},
            showIconOnly: false,
            showScrollToTop: false,
            showText: true,
            baseUrl: 'https://dorosi.ai/image/',
            scrollContainer: null,
            // focusedImage: null,
            activeIcon: 0,
            rightIcons: ['mdi-shape-plus', 'mdi-heart'],
        };
    },
    watch: {
        isLeftPaneVisible() {
            setTimeout(() => {
                if (typeof this.$redrawVueMasonry === 'function') this.$redrawVueMasonry();
            }, 500);
        },
        async statusCheck(v) {
            await nextTick();
            // TODO
            if (v && v.url && v.url !== '') {
                // this.focusedImage = { src: v.url && v.url !== '' ? v.url : 'processing.webp', prompt: v.prompt };
                this.$store.dispatch('setFocusedImage', {image: { src: v.url && v.url !== '' ? v.url : 'processing.webp', prompt: v.prompt }})
                this.activeIcon = 1;
            } else if(this.anyInProgress){
                // this.focusedImage = this.images[0];
                // alert(JSON.stringify(this.myImages[0].images[0]));
                this.activeIcon = 1;
                this.$store.dispatch('setFocusedImage', {image: this.myImages[0].images[0]})
            }
        },
    },
    computed: {
        ...mapGetters([
            'myImages',
            'canvases',
            'isAuthenticated',
            'reload',
            'fromCreator',
            'anyInProgress',
            'tabletEnd',
            'statusCheck',
            'focusedImage',
        ]),
        images() {
            return this.myImages;
        },
    },
    async mounted() {
        const { reload, fromCreator , focusedImage } = this;
        await this.$store.dispatch('setReload', { cnt: (reload + (fromCreator ? 10 : 1)) || 1 });

        if(focusedImage && focusedImage.cmd === 'to-be-first') {
            await this.$store.dispatch('setFocusedImage', {image: this.myImages[0].images[0]});
            this.activeIcon = 1
        } else {
            await this.$store.dispatch('clearImages');
            await this.$store.dispatch('fetchMyImages', { init: true });
        }

        await this.$store.dispatch('setFromCreator', { flag: false });

        const { myImages } = this;
        if (myImages && myImages.filter((d) => !['completed', 'failed', 'rejected'].includes(d.status)).length === 0)
            this.$store.dispatch('setAnyInProgress', { flag: false });

        this.visit = { init: true };

        if (typeof this.$redrawVueMasonry === 'function') this.$redrawVueMasonry();

        await nextTick();
        this.scrollContainer = this.$refs.scrollContainer;
        this.horizontalScrollContainer = this.$refs.horizontalScrollContainer;

        if (this.scrollContainer) {
            this.scrollContainer.addEventListener('scroll', this.handleScroll);
        }
        if (this.horizontalScrollContainer) {
            this.horizontalScrollContainer.addEventListener('wheel', this.handleHorizontalScroll);
        }
        this.$store.dispatch('setIsStyleSelected', { flag: true });
    },
    beforeUnmount() {
        if (this.scrollContainer) {
            this.scrollContainer.removeEventListener('scroll', this.handleScroll);
        }
        if (this.horizontalScrollContainer) {
            this.horizontalScrollContainer.removeEventListener('wheel', this.handleHorizontalScroll);
        }
    },
    methods: {
        setActiveIcon(index) {
            if (this.activeIcon !== index) {
                if (this.focusedImage) {
                    this.activeIcon = index;

                    if (index === 0 && typeof this.$redrawVueMasonry === 'function') this.$redrawVueMasonry();
                }
            }
        },
        async upscaleImage(image, idx) {
            const payload = {
                imageId: image.imageId,
                upscaleIndex: idx,
            }
            const ret = await this.$store.dispatch('upscale', payload)
            console.log(`ret : ${ret}`)
        },
        focusImage(image) {
            this.activeIcon = 1;
            this.$store.dispatch('setFocusedImage', {image})
        },
        highlightImage(image) {
            this.isAnyImageHighlighted = true;
            image.isHighlighted = true;
        },
        unhighlightImage(image) {
            this.isAnyImageHighlighted = false;
            image.isHighlighted = false;
        },
        disableRemove(image) {
            return ['pending', 'ready', 'in-progress'].includes(image.status);
        },
        async selectImage(image) {
            this.$router.push({ name: 'ImageCreator', query: { id: image.id } });
        },
        async remove(image) {
            this.$refs.confirm.open('삭제할까요? 삭제하면 되돌릴 수 없어요!', async () => {
                await this.$store.dispatch('deleteImage', { id: image.id });
                await this.$store.dispatch('clearImages');
                await this.$store.dispatch('fetchMyImages', { init: true });
                this.visit = { init: true };
                this.$store.dispatch('setReload', { cnt: this.reload + 1 });
                if (typeof this.$redrawVueMasonry === 'function') this.$redrawVueMasonry();

                setTimeout(() => {
                    this.scrollToTop();
                }, 500);
            });
        },
        handleScroll() {
            const scrollContainer = this.scrollContainer;
            const scrollTop = scrollContainer.scrollTop;
            const scrollHeight = scrollContainer.scrollHeight;
            const clientHeight = scrollContainer.clientHeight;

            const bottomOfWindow = scrollTop + clientHeight >= scrollHeight - 200;

            if (bottomOfWindow) this.loadMore();

            this.showText = scrollTop <= 50;
            this.showScrollToTop = scrollTop > 200;
        },
        handleHorizontalScroll(event) {
            event.preventDefault();
            this.horizontalScrollContainer.scrollLeft += event.deltaY;
        },
        loadMore() {
            const { myImages } = this;
            const lastGroup = myImages[myImages.length - 1];
            const lastItem = lastGroup.images[lastGroup.images.length - 1];
            if (lastItem && this.visit[lastItem.imageId]) return;
            if (!lastItem && this.visit['init']) return;

            if (lastItem) this.visit[lastItem.imageId] = true;
            else this.visit['init'] = true;

            this.$store.dispatch('fetchMyImages', { init: false, offset: lastItem ? lastItem.imageId : null });
        },
        format(d) {
            const parts = d.toString().split(",");
            const year = parts[0];
            const month = parts[1].padStart(2, '0');
            const day = parts[2].padStart(2, '0');

            return `${year}-${month}-${day}`;
        },
        moveCreation() {
            this.moveLoginIfRequired(async () => {
                this.moveStoreIfRequired(() => {
                    this.$router.push({ name: 'ImageCreator' });
                });
            });
        },
        moveLoginIfRequired(block) {
            const { isAuthenticated } = this;
            if (!isAuthenticated) {
                this.$refs.socialLoginDialog.open();
            } else {
                if (block) block();
            }
        },
        moveStoreIfRequired(block) {
            const { canvases } = this;
            if (canvases <= 0 && !this.isDateValidAndFuture(this.tabletEnd)) {
                this.$refs.confirm.open(`캔버스가 부족해요. 캔버스를 구매하러 이동하시겠어요?`, () => {
                    this.$router.push({ name: 'CanvasStore' })
                });
            } else {
                if (block) block();
            }
        },
        isDateValidAndFuture(date) {
            if (!date) return false;

            const today = moment().startOf('day');
            const inputDate = moment(date, 'YYYY-MM-DD');

            return inputDate.isSameOrAfter(today);
        },
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        getTimeAgo(dateString) {
            const today = new Date();
            const compareDate = new Date(dateString);
            compareDate.setHours(0, 0, 0, 0);
            today.setHours(0, 0, 0, 0);

            const msPerDay = 24 * 60 * 60 * 1000;
            const dayDifference = Math.round((today - compareDate) / msPerDay);

            if (dayDifference === 1) {
                return '어제';
            } else if (dayDifference > 1 && dayDifference <= 6) {
                return `${dayDifference}일 전`;
            } else if (dayDifference >= 7 && dayDifference < 30) {
                return `${Math.floor(dayDifference / 7)}주 전`;
            } else if (dayDifference >= 30 && dayDifference < 365) {
                return `${Math.floor(dayDifference / 30)}달 전`;
            } else if (dayDifference >= 365) {
                return `${Math.floor(dayDifference / 365)}년 전`;
            } else {
                return '오늘';
            }
        },
        async downloadImage(url) {
            this.$store.dispatch('startLoading');
            const id = url.split('/').pop().replace('.webp', '.png');
            const response = await fetch(url.replace('.webp', '.png'));
            const blob = await response.blob();
            const u = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = u;
            link.setAttribute('download', id); // 다운로드할 파일명 지정
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.isAnyImageHighlighted = false;
            this.$store.dispatch('stopLoading');
        },
        postDialogOpen(url, parentId) {
            this.$refs.postDialog.open(parentId, url);
        },
        remixImage(image) {
            this.moveStoreIfRequired(()=>{
                this.$store.dispatch('setApplyPrompt', {image: {parentId: image.imageId}});
                // this.$router.push({name: 'ImageCreator', query: {id: image.parentId, command: 'copy', url: image.url}});
            });
        },
        postImage(image) {
            alert(image);
        },
        copyToClipboard() {
            navigator.clipboard.writeText(this.focusedImage.prompt).then(() => {
                // alert('Prompt copied to clipboard!');
                this.$store.dispatch('showToast', {msg: '클립보드에 복사되었습니다.', duration: 1.5, color: 'red'});
            });
        },
        postedHandle() {
            this.$store.dispatch('startLoading');
            setTimeout(() => {
                this.$store.dispatch('stopLoading');
            }, 1000);
        },
    },
};
</script>

<style scoped>
.no-focus {
    outline: none !important;
    pointer-events: none;
    user-select: none!important;
}

.no-drag {
    -webkit-user-drag: none;
    user-drag: none;
}
.dimmed {
    opacity: 0.5;
}

.image-item img {
    width: 100%;
    transition: transform 0.3s ease;
}

.image-info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(30, 34, 58, 0.7);
    color: #343438;
    padding: 10px;
    display: none;
    flex-direction: column;
    overflow: hidden;
}

.item:hover .image-info {
    display: block;
}
@media (max-width: 1200px) {
    .image-info {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        color: #ffffff;
        padding: 10px;
        display: block;
        flex-direction: column;
        overflow: hidden;
    }
}
@media (max-width: 640px) {
    .image-info {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        color: #ffffff;
        padding: 10px;
        display: block;
        flex-direction: column;
        overflow: hidden;
    }
}
.fixed-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 199;
    border: none;
    outline: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    padding: 15px;
    border-radius: 10px;
    font-size: 18px;
}
.processing-container {
    position: relative;
    width: 100%;
    height: 200px;
    background-size: cover;
    background-position: center;
}

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-text {
    color: white;
    font-size: 24px;
    font-weight: bold;
}

.btn-expanded {
    transition: width 0.5s ease;
    width: 130px;
}

.btn-zipped {
    width: 55px;
    overflow: hidden;
    transition: width 0.5s ease;
}
/* 텍스트 페이드 인/아웃 애니메이션 */
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter-from, .fade-leave-to {
    opacity: 0;
}

.tabs-container {
    margin-top: 2rem;
}
.tab-btn {
    margin: 0;
    padding: 0.2rem;
    color: #595b5e;
    background-color: transparent;
    font-weight: bold;
    border: none;
    min-width: unset;
    min-height: unset;
    text-transform: none;
}
.tab-btn:not(.active-icon):hover {
    background-color: transparent !important;
}
.active-icon {
    background-color: #4c3c7c !important;
    color: whitesmoke !important;
    font-weight: bold;
}
.options-bar {
    padding: 16px;
}
.my-textarea {
    background-color: #f9f9f9;
    border-radius: 8px;
}
.image-scroll {
    width: 100%;
    height: 150px;
    overflow-x: auto;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
    transition: margin-left 0.3s ease-in-out;
}
.show-left-area {
    margin-left: 430px; /* 왼쪽에 열려있는 영역만큼 띄움 */
}
.image-wrapper {
    flex: 0 0 auto;
    margin-right: 2px; /* 이미지 간 간격 조정 */
}
.thumbnail {
    width: 100px;
    height: 100px;
    border-radius: 8px;
    cursor: pointer;
}
.thumbnail-focused {
    width: 120px;
    height: 120px;
    border-radius: 8px;
    cursor: pointer;
}
.spacer {
    flex: 0 0 5px; /* 왼쪽 영역 크기만큼 빈 공간 추가 */
}
.slide-enter-active, .slide-leave-active {
    transition: padding-left 0.3s ease-in-out;
}
.content {
    height: 100%;
    overflow-y: auto;
}
.custom-textarea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 200px; /* v-textarea의 높이와 동일하게 설정 */
}
.custom-textarea .v-input__control {
    padding-top: 0;
    padding-bottom: 0;
    line-height: normal;
}
.popup-content {
    height: 100%;
    padding: 20px;
    background-color: #333;
    border-radius: 0 16px 16px 0;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.prompt-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.prompt-container {
    border: 1px solid #555;
    padding: 10px;
    border-radius: 8px;
    background: #2a2a2a;
}
.prompt {
    white-space: pre-wrap;
    word-wrap: break-word;
}
.copy-btn {
    background-color: #444;
    border-radius: 50%;
}
.popup-actions {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 40px);
    margin-top: auto;
    padding-top: 20px;
}
.download-btn {
    background-color: #444;
    max-width: 40px;
    border-radius: 5px;
    width: 10%;
    color: #fff;
}
.remix-btn {
    background-color: #444;
    width: 42%;
    border-radius: 5px;
    color: #fff;
}
.post-btn {
    background-color: #444;
    width: 42%;
    border-radius: 5px;
    color: #fff;
}
</style>
