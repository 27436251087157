<script>
import ImageWrapper from "@/components/common/ImageWrapper.vue";

export default {
    name: "MyImageGroup",
    props: {
        images: Object,
        isMobile: Boolean,
        isFixedBanner: Boolean,
        isLeftPaneVisible: Boolean,
    },
    watch:{
        isLeftPaneVisible(flag) {
            setTimeout(()=>{
                this.dateMarginLeft = !flag;
            }, 100);
        }
    },
    data(){
        return {
            isAnyImageHighlighted: false,
            dateMarginLeft: false,
        }
    },
    methods: {
        highlightImage(image) {
            this.isAnyImageHighlighted = true;
            image.isHighlighted = true;
        },
        unhighlightImage(image) {
            this.isAnyImageHighlighted = false;
            image.isHighlighted = false;
        },
        disableRemove(image){
            return ['pending','ready','in-progress'].includes(image.status);
        },
        async selectImage(image) {
            this.$emit('focusImage', image);
            // this.$router.push({name: 'ImageCreator', query: {id: image.id}});
        },
        async remove(image) {
            this.$refs.confirm.open('삭제할까요? 삭제하면 되돌릴 수 없어요!', async ()=>{
                await this.$store.dispatch('deleteImage', {id: image.id});
                await this.$store.dispatch('clearImages');
                await this.$store.dispatch('fetchMyImages', {init: true});
                this.visit = { init: true };
                this.$store.dispatch('setReload', {cnt: this.reload+1});
                if(typeof this.$redrawVueMasonry === 'function') this.$redrawVueMasonry();

                setTimeout(()=>{
                    this.scrollToTop();
                }, 500);
            });
        },
    },
    components: {ImageWrapper}
}
</script>

<template>
    <div style="width: 100%;">
        <v-card-title :class="{'ml-4' : dateMarginLeft}" style="font-weight: bold"><v-icon class="mx-2">mdi-calendar</v-icon>{{images.date}}</v-card-title>
        <div :class="{'pl-3': !isMobile, 'pl-10': !isLeftPaneVisible }" v-masonry="0" transition-duration="0.3s" item-selector=".item" style="width:100%;">
            <div v-masonry-tile
                 v-for="(item, index) in images.images"
                 :key="index"
                 @mouseover="highlightImage(item)"
                 @mouseleave="unhighlightImage(item)"
                 @click="selectImage(item)"
                 :style="{cursor: 'pointer', width: isMobile ? '40%' : (isFixedBanner ? '45%' : (isLeftPaneVisible ? '155px' : '177px')), overflow: 'hidden!important', borderRadius: '5px'}"
                 :class="{ 'highlighted': item.isHighlighted, 'dimmed': isAnyImageHighlighted && !item.isHighlighted, 'item': true, 'ma-1': true, 'mx-1': true, 'my-1': true }"
            >
                <!-- block item markup -->
                <div style="border: solid #918d8d 1px; border-radius: 5px; overflow: hidden; background-color: #171717;">
                    <image-wrapper v-if="item.url && item.url.length > 0" :src="item.url" image-style="object-fit: cover; width: 100%; max-width: 100%;"/>
                    <div v-else class="processing-container" :style="{ backgroundImage: 'url(processing.webp)', width: '100%' }">
                        <div class="loading-overlay">
                            <span class="loading-text">이미지 생성중…</span>
                        </div>
                    </div>
                    <div class="image-info pt-0" style="max-height: 45px;">
                        <div style="height: 100%;" class="pt-0 my-0 d-flex justify-space-between align-center">
                            <!--                            <v-col cols="8" class="my-0 py-0">-->
                            <!--                                <v-card-text class="ml-0 pl-0 font-weight-bold" style="width:100px;font-size: 12px;color:whitesmoke;" >{{ item.date }}</v-card-text>-->
                            <!--                            </v-col>-->
                            <v-col class="my-0 py-0 ml-3" style="display:flex;justify-content: end;">
                                <v-tooltip v-if="!disableRemove(item)" text="지우기">
                                    <template v-slot:activator="{ props }">
                                        <v-btn density="compact" color="white" icon v-bind="props" class="ma-2" @click.stop="remove(item)">
                                            <v-icon color="red">mdi-trash-can</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                            </v-col>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
