<template>
    <div class="mx-0 px-0" fluid :style="{alignItems: 'center', backgroundColor: '#171717', marginTop: isFixedBanner ? '0' : '0'}">
        <v-row style="display: flex; height: 100%; justify-content: center; align-items: center;" class="mx-6 my-4">
            <v-col cols="12" style="background-color: #171717;">
                <template v-if="options.blending">
                    <div style="display: flex; justify-content: start">
                        <v-card-title class="my-0 pb-0 pt-1">이미지 비율</v-card-title>
                    </div>
                    <div class="py-0 my-0 chip-container" ref="chipContainer">
                        <v-col style="width:100px;height: 100px;" v-for="(ratio, index) in blendingRatios" @click="selectRatio(ratio.text)" :key="index" class="chip">
                            <v-img :src="`${ratio.img}${selectedRatio == ratio.text ? 'on' : 'off'}.png`" :style="{height:'100%',width:'100%',cursor:'pointer', border: '', borderRadius: '5px'}" contain></v-img>
                        </v-col>
                    </div>
                </template>
                <template v-else>
                    <div style="display: flex; justify-content: space-between">
                        <v-card-title style="color:#494D59;margin-left:-5px;" class="my-0 pb-0 pt-1">이미지 비율</v-card-title>
                        <div class="custom-switch mr-4">
                            <span class="custom-label">{{options.arType === 'custom' ? '직접입력' : '프리셋'}}</span>
                            <v-switch
                                style="height:40px;"
                                density="compact"
                                class="py-0 my-0 custom-switch-input custom-switch"
                                v-model="options.arType"
                                :readonly="processing || mode === 'view'"
                                false-value="preset"
                                color="#6D49FF"
                                value="custom"
                            ></v-switch>
                        </div>
                    </div>
                    <v-text-field label="'16:9'와 같은 형태로 입력하세요" class="mt-2 mb-0 py-0 px-5" density="compact" v-if="options.arType === 'custom'" v-model="customAr"/>
                    <div v-else class="py-0 my-0 chip-container" ref="chipContainer">
                        <v-col style="width:75px;height: 75px;" v-for="(ratio, index) in ratios" @click="selectRatio(ratio.text)" :key="index" class="chip">
                            <v-img :src="`${ratio.img}${selectedRatio == ratio.text ? 'on' : 'off'}.png`" :style="{height:'100%',width:'100%',cursor:'pointer', border: '', borderRadius: '5px'}" contain></v-img>
                        </v-col>
                    </div>
                </template>
                <v-card-title style="color:#494D59;margin-left:-5px;" class="my-0 pb-0 pt-1">블렌딩</v-card-title>
                <div class="custom-checkbox my-1 px-3" style="height:50px;">
                    <v-checkbox
                        v-model="options.blending"
                        :label="blendingLabel"
                        persistent-hint
                        color="#6D49FF"
                        style="height: 100%;background-color: transparent;"
                        density="compact"
                        :value="true"
                        :false-value="false"
                        :readonly="processing || mode === 'view'"
                        class="my-0 py-0 custom-checkbox-input"
                    ></v-checkbox>
                </div>
                <template v-if="options.blending">
                    <image-uploader :description="blendingLabel" v-model:images="uploadedImages" style="margin-top: -20px;" title="이미지 업로드" :read-only="processing || mode === 'view'"/>
                </template>
                <template v-else>
                    <div v-if="mode === 'edit' || (detailImage && detailImage.promptImage && detailImage.promptImage !== '')" style="display: flex; flex-wrap: wrap; justify-content: flex-start; align-items: center;">
                        <v-col style="position:relative; min-width: 100px; min-height: 100px;" v-for="(image, idx) in uploadedImages" cols="4" :key="idx">
                            <v-tooltip v-if="mode==='edit' && !processing" text="삭제">
                                <template v-slot:activator="{ props }">
                                    <v-btn v-bind="props" color="#171717" style="z-index: 199; position: absolute; top: 0; right: 0;" @click="removeImage(image)" icon density="compact"><v-icon color="white" size="xsmall">mdi-close</v-icon></v-btn>
                                </template>
                            </v-tooltip>
                            <v-img :src="image" style="border: solid #696969 3px; border-radius: 10px; width: 100%; height: auto;" contain></v-img>
                        </v-col>
                    </div>
                    <image-uploader v-model:images="uploadedCrefImages" style="margin-top: -20px;" title="캐릭터 레퍼런스" :read-only="processing || mode === 'view'"/>
                    <image-uploader v-model:images="uploadedSrefImages" style="margin-top: -20px;" title="스타일 레퍼런스" :read-only="processing || mode === 'view'"/>
                    <div style="display: flex; justify-content: space-between">
                        <v-card-title style="color:#494D59;margin-left:-5px;" class="my-0 pb-0 pt-1">이미지 설명</v-card-title>
                        <div class="custom-switch mr-4">
                            <span class="custom-label">{{options.lang === 'ko' ? '한글' : 'Eng'}}</span>
                            <v-switch
                                style="height:40px;"
                                density="compact"
                                class="py-0 my-0 custom-switch-input custom-switch"
                                v-model="options.lang"
                                :readonly="processing || mode === 'view'"
                                false-value="ko"
                                color="#6D49FF"
                                value="en"
                            ></v-switch>
                        </div>
                    </div>
                    <v-textarea
                        v-model="prompt"
                        density="compact"
                        :clearable="!(processing || mode === 'view')"
                        clear-icon="mdi-close-circle"
                        rows="4"
                        style="border-radius:7px;background-color: black; margin-top:-20px;"
                        :readonly="processing || mode === 'view'"
                        class="prompt-input my-textarea my-0 py-0 pt-2 px-3"
                    ></v-textarea>
                    <div style="display: flex; justify-content: space-between;" class="mt-2">
                        <v-card-title style="color:#494D59;margin-left:-5px;" class="my-0 pb-0 pt-1">포함시키지 않을 항목</v-card-title>
                        <div class="custom-switch">
                            <span class="custom-label" :style="{color: options.lang === 'ko' ? '' : '#6D49FF'}">{{options.lang === 'ko' ? '한글' : 'Eng'}}</span>
                        </div>
                    </div>
                    <v-textarea
                        v-model="options.negative"
                        density="compact"
                        :clearable="!(processing || mode === 'view')"
                        clear-icon="mdi-close-circle"
                        rows="4"
                        style="border-radius:7px;background-color: black;"
                        :readonly="processing || mode === 'view'"
                        class="prompt-input my-textarea my-0 mt-2 py-0 pt-2 px-3"
                    ></v-textarea>
                    <v-card-title style="color:#494D59;margin-left:-5px;" class="my-0 pb-0 mt-2 pt-1">타일 디자인</v-card-title>
                    <div class="custom-checkbox my-1 px-3 mb-3" style="height:50px;">
                        <v-checkbox
                            v-model="options.tile"
                            label="타일 디자인을 생성합니다."
                            persistent-hint
                            color="#6D49FF"
                            style="height: 100%;background-color: transparent;"
                            density="compact"
                            :value="true"
                            :false-value="false"
                            :readonly="processing || mode === 'view'"
                            class="my-0 py-0 custom-checkbox-input"
                        ></v-checkbox>
                    </div>
                </template>
                <v-btn v-if="mode === 'edit'" @click="creation" :disabled="processing || mode === 'view'" width="100%" height="50" style="font-size: 20px;" color="#6D49FF" class="font-weight-bold mb-2">
                    <span v-if="processing" class="font-weight-bold">이미지 생성중</span>
                    <span v-else class="breathing-text font-weight-bold" style="font-size: 20px; color: white">{{creationText}}</span>
                </v-btn>
                <v-btn v-else-if="mode === 'view'" @click="enableCreation" width="100%" height="50" style="font-size: 24px;" color="#6D49FF" class="font-weight-bold mb-2" append-icon="mdi-image-edit">다시 생성하기</v-btn>
            </v-col>
        </v-row>
        <alert-dialog ref="alert"/>
        <confirm-dialog ref="confirm"/>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AlertDialog from "@/dialogs/AlertDialog.vue";
import ConfirmDialog from "@/dialogs/ConfirmDialog.vue";
import ImageUploader from "@/components/common/ImageUploader.vue";
import moment from "moment";
import { nextTick } from "vue";

export default {
    components: { ConfirmDialog, AlertDialog, ImageUploader },
    data() {
        return {
            imageUrl: '', // 선택된 파일 이름
            uploadedImages: [],
            uploadedCrefImages: [],
            uploadedSrefImages: [],
            mode: 'edit',
            baseUrl: 'https://dorosi.ai/image/',
            imageSrc: null,
            prompt: '',
            polling: null,
            imageSize: { width: 1920, height: 1080 }, // 이미지 크기 초기값
            processing: false,
            prevSize: {},
            imageId: null,
            containerWidth: null,
            selectedItem: null,
            hoverIndex: null,
            progressMessages: [
                "캔버스 준비 완료! 작업 시작.",
                "기본 스케치 완성.",
                "색상 입히는 중.",
                "세부 디테일 추가 중.",
                "마무리 층 칠하는 중.",
                "빛과 그림자 조정 중.",
                "조금만 더 기다려 주세요...",
                "곧 보여드릴께요! 영차 영차...",
            ],
            customAr: '16:9',
            options: {
                chaos: 0,
                lang: 'ko',
                blending: false,
                resolution: {
                    width: 16,
                    height: 9,
                },
                polling: null,
                ar: null,
                resolutions: [
                    { text: '16:9', width: '', height: '' },
                    { text: '9:16', width: '', height: '' },
                    { text: '1:1', width: '', height: '' },
                    { text: '4:3', width: '', height: '' },
                    { text: '3:4', width: '', height: '' },
                ],
                arType: 'preset',
                descriptor: '',
                tile: false,
                negative: '',
            },
            selectedRatio: null,
            ratios: [
                { text: '16:9', ratio: 16 / 9, img: '16_9' },
                { text: '3:2', ratio: 3 / 2, img: '3_2' },
                { text: '1:1', ratio: 1 / 1, img: '1_1' },
                { text: '2:3', ratio: 2 / 3, img: '2_3' },
                { text: '9:16', ratio: 9 / 16, img: '9_16' },
            ],
            blendingRatios: [
                { text: '3:2', ratio: 3 / 2, img: '3_2' },
                { text: '1:1', ratio: 1 / 1, img: '1_1' },
                { text: '2:3', ratio: 2 / 3, img: '2_3' },
            ],
            upscaled: [],
            copying: false,
            imageStatus: [],
            imageStatusCheckCnt: 0,
            uploadType: null,
            upscaling: false,
        };
    },
    computed: {
        ...mapGetters(['statusCheck', 'applyPrompt', 'detailImage', 'myImages', 'isLeftPaneVisible', 'reload', 'role', 'tabletEnd']),
        isMobile() {
            return this.containerWidth < 640;
        },
        isFixedBanner() {
            return this.containerWidth < 960;
        },
        creationText() {
            const { processing, options } = this;
            const { blending } = options;
            if (processing) return '이미지 생성중 ...';
            if (blending) return '이미지 합치기';
            return '이미지 생성';
        },
        locationHref() {
            return window.location.href.split('/').pop();
        },
        blendingLabel() {
            return this.options.blending ? '2~4장의 이미지를 합칠 수 있습니다.' : '2~4장의 이미지를 합칠 수 있습니다.';
        },
    },
    async beforeUnmount() {
        window.removeEventListener('resize', this.updateContainerWidth);
        await this.$store.dispatch('setInCreator', { flag: false });
        clearTimeout(this.polling);
        this.scrollToTop();
    },
    watch: {
        applyPrompt(v) {
            if (v) {
                this.setPrompt(v);
                this.$store.dispatch('setApplyPrompt', { image: null });
            }
        }
    },
    methods: {
        scrollToTop(behavior) {
            window.scrollTo({ top: 0, behavior });
        },
        scrollToBottom() {
            window.scrollTo({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
        },
        pickFile(type) {
            if (this.uploadedImages.length >= 10) {
                this.$refs.alert.open('이미지 업로드는 10장까지만 가능해요.');
                return;
            }
            if (type === 'blending' && this.uploadedImages.length >= 2) {
                this.$refs.alert.open('이미지 2장이 이미 존재합니다.');
                return;
            }
            this.uploadType = type;
            this.$refs.fileInput.click(); // 파일 입력을 위한 숨겨진 input 클릭
        },
        setPrompt(image, ignoreUrl) {
            this.setImagePrompt(image, ignoreUrl);
            if (this.isFixedBanner) this.scrollToBottom();
        },
        async uploadFile(files) {
            if (files.length > 0) {
                const formData = new FormData();
                formData.append('file', files[0]);

                try {
                    const response = await this.$store.dispatch('uploadImage', formData);
                    const { uploadType } = this;
                    switch (uploadType) {
                        case 'cref': this.uploadedCrefImages.push(response.data); break;
                        case 'sref': this.uploadedSrefImages.push(response.data); break;
                        case 'prompt': case 'blending': this.uploadedImages.push(response.data); break;
                    }
                    this.$refs.fileInput.value = '';
                } catch (error) {
                    console.error('업로드 중 오류 발생:', error);
                }
            }
        },
        async uploadFiles(event) {
            const files = event.target.files;
            if (files.length > 0) {
                const formData = new FormData();
                Array.from(files).forEach(file => formData.append('files', file));
                try {
                    const response = await this.$store.dispatch('uploadImages', formData);
                    const uploadTypeHandlers = {
                        cref: (data) => this.uploadedCrefImages.push(data),
                        sref: (data) => this.uploadedSrefImages.push(data),
                        prompt: (data) => this.uploadedImages.push(data),
                        blending: (data) => this.uploadedImages.push(data),
                    };

                    response.forEach(({ data }) => {
                        const handler = uploadTypeHandlers[this.uploadType];
                        if (handler) handler(data);
                    });

                    this.$refs.fileInput.value = '';
                } catch (error) {
                    console.error('업로드 중 오류 발생:', error);
                }
            }
        },
        removeImage(url) {
            this.uploadedImages = this.uploadedImages.filter((img) => img !== url);
        },
        removeCrefImage(url) {
            this.uploadedCrefImages = this.uploadedCrefImages.filter((img) => img !== url);
        },
        removeSrefImage(url) {
            this.uploadedSrefImages = this.uploadedSrefImages.filter((img) => img !== url);
        },
        selectRatio(ratio) {
            if (this.mode === 'view' || this.processing) return;
            if (this.selectedRatio === ratio) this.selectedRatio = null;
            else this.selectedRatio = ratio;
        },
        updateContainerWidth() {
            this.containerWidth = window.innerWidth;
        },
        pollStatusCheckImage(id) {
            this.polling = setTimeout(async () => {
                await this.$store.dispatch('statusCheckImage', { id });

                if (this.statusCheck.status === 'FAILED') {
                    this.$refs.alert.open('이미지 생성에 실패했어요.<br/>혹시 이미지 생성 규정에 위배된 것이 아닌지 확인해주세요.<br/>사용한 캔버스는 돌려드렸어요.<br/>불편을 드려서 죄송해요!😂', async () => {
                        await this.$store.dispatch('reStoreCanvas');
                        await this.$store.dispatch('deleteImage', { id });
                        this.$router.replace({ name: 'MyHome' });
                    });
                    clearTimeout(this.polling);
                } else if (this.statusCheck.status === 'REJECT') {
                    this.$refs.alert.open('이미지 생성 규정에 맞지 않아요.<br/>성적인 주제나, 폭력, 잔인한 주제 등은 불가해요.<br/>사용한 캔버스는 돌려드렸어요.<br/>반복적으로 시도하면 이미지 생성이 정지될 수 있어요.', async () => {
                        await this.$store.dispatch('reStoreCanvas');
                        await this.$store.dispatch('deleteImage', { id });
                        this.$router.replace({ name: 'MyHome' });
                    });
                    clearTimeout(this.polling);
                } else if (this.statusCheck.status !== 'COMPLETED') {
                    if (this.statusCheck.url && this.imageSrc !== this.statusCheck.url) this.imageSrc = this.statusCheck.url;
                    this.pollStatusCheckImage(id);
                } else if (this.statusCheck.status === 'COMPLETED') {
                    if (this.statusCheck.url && this.imageSrc !== this.statusCheck.url) this.imageSrc = this.statusCheck.url;

                    this.$store.dispatch('startLoading');
                    this.imageStatus = [];
                    this.imageStatusCheckCnt = 0;

                    clearTimeout(this.polling);
                    // this.upscaled = [
                    //     { url: `${this.baseUrl}${this.statusCheck.upscaled[0]}.webp`, retryCount: 0 },
                    //     { url: `${this.baseUrl}${this.statusCheck.upscaled[1]}.webp`, retryCount: 0 },
                    //     { url: `${this.baseUrl}${this.statusCheck.upscaled[2]}.webp`, retryCount: 0 },
                    //     { url: `${this.baseUrl}${this.statusCheck.upscaled[3]}.webp`, retryCount: 0 }
                    // ];
                    // this.checkImageStatus(`${this.baseUrl}${this.statusCheck.upscaled[0]}.webp`, 0);
                    // this.checkImageStatus(`${this.baseUrl}${this.statusCheck.upscaled[1]}.webp`, 0);
                    // this.checkImageStatus(`${this.baseUrl}${this.statusCheck.upscaled[2]}.webp`, 0);
                    // this.checkImageStatus(`${this.baseUrl}${this.statusCheck.upscaled[3]}.webp`, 0);
                    // setTimeout(() => async () => {
                    await this.$store.dispatch('fetchDetailImage', { id });
                    this.mode = 'view';
                    this.processing = false;
                    await this.$store.dispatch('setAnyInProgress', { flag: false });
                    await this.$store.dispatch('clearStatusCheck');
                    await this.setImagePrompt(null, null, true);
                    this.mode = 'edit';
                    await this.$store.dispatch('stopLoading');
                    if (this.locationHref !== 'mine') {
                        await this.$store.dispatch('fetchMyImages', { init: true });
                        await this.$store.dispatch('setFocusedImage', { image: { cmd: 'to-be-first' } });
                        await this.$store.dispatch('setSelectedOption', { option: 1 });
                        await nextTick();
                        await this.$router.replace({ name: 'MyHome' });
                    }
                    // }, 1000);
                }
            }, 2000);
        },
        async checkAllImageLoaded(id) {
            if (this.imageStatus.length >= 4 || this.imageStatusCheckCnt >= 12) {
                await this.$store.dispatch('fetchDetailImage', { id });
                this.mode = 'view';
                this.processing = false;
                await this.$store.dispatch('setAnyInProgress', { flag: false });
                await this.$store.dispatch('clearStatusCheck');

                await this.setImagePrompt(null, null, true);
                this.mode = 'edit';
                await this.$store.dispatch('stopLoading');
                if (this.locationHref !== 'mine') {
                    await this.$store.dispatch('fetchMyImages', { init: true });
                    await this.$store.dispatch('setFocusedImage', { image: { cmd: 'to-be-first' } });
                    await this.$store.dispatch('setSelectedOption', { option: 1 });
                    await nextTick();
                    await this.$router.replace({ name: 'MyHome' });
                }
            } else {
                this.imageStatusCheckCnt += 1;
                setTimeout(() => this.checkAllImageLoaded(id), 1000);
            }
        },
        async setImagePrompt(image, ignoreUrl, reset) {
            if (reset) {
                this.imageSrc = null;
                this.options.arType = 'preset';
                this.selectedRatio = null;
                this.prompt = '';
                this.options.negative = '';
                this.uploadedImages = [];
                this.uploadedCrefImages = [];
                this.uploadedSrefImages = [];
                this.options.tile = false;
                this.options.lang = 'ko';
                this.options.chaos = 0;
                this.options.blending = false;
                this.imageId = null;
            } else {
                await this.$store.dispatch('startLoading');
                await this.$store.dispatch('fetchDetailImage', { id: image.parentId });

                const { detailImage } = this;

                if (!ignoreUrl) this.imageSrc = detailImage.url;
                if (['16:9', '3:2', '1:1', '2:3', '9:16'].includes(detailImage.ar)) {
                    this.options.arType = 'preset';
                    this.selectedRatio = detailImage.ar;
                } else {
                    this.options.arType = 'custom';
                    this.customAr = detailImage.ar;
                }
                this.prompt = detailImage.promptKr;
                this.options.negative = detailImage.negative;

                if (detailImage.promptImage) this.uploadedImages = detailImage.promptImage.split(' ').map((img) => `${this.baseUrl}${img}`);
                else this.uploadedImages = [];

                if (detailImage.cref) this.uploadedCrefImages = detailImage.cref.split(' ').map((img) => `${this.baseUrl}${img}`);
                else this.uploadedCrefImages = [];

                if (detailImage.sref) this.uploadedSrefImages = detailImage.sref.split(' ').map((img) => `${this.baseUrl}${img}`);
                else this.uploadedSrefImages = [];

                this.options.tile = detailImage.tile;
                this.options.lang = detailImage.lang;
                this.options.chaos = detailImage.chaos;
                this.options.blending = detailImage.blending;
                this.imageId = detailImage.id;

                this.$store.dispatch('setIsLeftPaneVisible', { flag: true });
                await this.$store.dispatch('stopLoading');
            }
        },
        enableCreation() {
            this.moveStoreIfRequired(() => {
                this.mode = 'edit';
                this.upscaling = false;
                this.$router.replace({ query: {} });
                clearTimeout(this.polling);
                this.setImagePrompt(this.detailImage, false);
                this.scrollToTop('smooth');
            });
        },
        validateInput() {
            const { selectedRatio, prompt, uploadedImages, options } = this;
            const { blending } = options;
            if (options.arType === 'custom') {
                const { customAr } = this;
                const pattern = /^(1?[0-9]{1,2}|200):(1?[0-9]{1,2}|200)$/;
                const arValid = pattern.test(customAr);
                if (!arValid) return '비율이 올바르지 않아요.<br/>비율은 1:1 , 16:9 등과 같아야 해요.<br/>1~200 사이의 숫자만 사용 가능해요!🤣';
            } else {
                if (!selectedRatio || selectedRatio === '') return '비율을 선택하세요.<br/>선택한 비율대로 이미지가 생성 됩니다!🤣';
            }
            if (!blending && (!prompt || prompt === '') && (!uploadedImages || uploadedImages.length < 2)) return '이미지를 설명해주세요.<br/>원하시는 그림이 있나요? 뭐든지 상상해봐요!🥰';
            if (blending && uploadedImages.length < 2) return '블렌딩은 이미지 2장 이상 필요합니다.';
            if (blending && uploadedImages.length > 5) return '블렌딩은 이미지 5장까지 사용 가능합니다.';

            return null;
        },
        async creation() {
            if (this.role !== 'ADMIN') {
                const creationStatus = await this.$store.dispatch('creationStatus');
                if (creationStatus.data !== 'READY') {
                    this.$refs.alert.open('이미 생성 중인 이미지가 있습니다.');
                    return;
                }
            }

            const validate = this.validateInput();
            if (validate) {
                this.$refs.alert.open(validate);
                return;
            }
            const { tile, negative, chaos, lang, arType, blending } = this.options;
            const { selectedRatio, customAr, uploadedImages, uploadedCrefImages, uploadedSrefImages } = this;
            const payload = {
                type: 'CUSTOM',
                descriptor: this.prompt,
                ar: arType === 'custom' ? customAr : selectedRatio,
                negative,
                lang,
                tile,
                chaos,
                blending,
                cref: uploadedCrefImages,
                sref: uploadedSrefImages,
                images: uploadedImages
            };

            this.processing = true;
            this.imageSrc = null;
            const result = await this.$store.dispatch('creation', payload);

            if (result.id === -1) {
                if (result.msg === 'banned') {
                    this.$refs.alert.open('현재 이용 정지 상태로, 이미지를 생성할 수 없어요. 문제라고 생각되면 지원 센터로 문의해 주세요!', () => {
                        this.$router.replace({ name: 'MyHome' });
                    });
                } else if (result.msg === 'no-canvas') {
                    this.$refs.confirm.open('캔버스가 부족해요. 캔버스를 구매하러 이동하시겠어요?', () => {
                        this.$router.replace({ name: 'CanvasStore' });
                    }, () => {
                        this.$router.replace({ name: 'MyHome' });
                    });
                } else {
                    this.$refs.alert.open('오류가 발생했어요. 잠시 후 다시 시도해주세요.!', () => {
                        this.$router.replace({ name: 'MyHome' });
                    });
                }
                return;
            }

            this.imageId = result.id;
            this.pollStatusCheckImage(result.id);
            this.$store.dispatch('setIsLeftPaneVisible', { flag: false });
            this.$store.dispatch('setAnyInProgress', { flag: true });
            this.scrollToTop('smooth');
        },
        moveToList() {
            clearTimeout(this.polling);
            this.$store.dispatch('startLoading');
            setTimeout(async () => {
                await this.$store.dispatch('stopLoading');
                await this.$store.dispatch('setFromCreator', { flag: true });
                this.$router.replace({ name: this.copying ? 'ImageHome' : 'MyHome' });
            }, 100);
        },
        moveStoreIfRequired(block) {
            const { canvases } = this;
            if (canvases <= 0 && !this.isDateValidAndFuture(this.tabletEnd)) {
                this.$refs.confirm.open('캔버스가 부족해요. 캔버스를 구매하러 이동하시겠어요?', () => {
                    this.$router.push({ name: 'CanvasStore' });
                });
            } else {
                if (block) block();
            }
        },
        isDateValidAndFuture(date) {
            if (!date) return false;

            const today = moment().startOf('day');
            const inputDate = moment(date, 'YYYY-MM-DD');

            return inputDate.isSameOrAfter(today);
        },
        checkImageStatus(url, cnt) {
            fetch(url)
                .then((response) => {
                    if (response.status !== 200) {
                        if (cnt < 5) setTimeout(() => this.checkImageStatus(url, cnt + 1), 1000);
                        else this.imageStatus.push(url);
                    } else {
                        this.imageStatus.push(url);
                    }
                })
                .catch((error) => {
                    console.error('이미지 로드에 실패했습니다:', error);
                    if (cnt < 5) setTimeout(() => this.checkImageStatus(url, cnt + 1), 1000);
                    else this.imageStatus.push(url);
                });
        },
    },
    async mounted() {
        this.updateContainerWidth();
        window.addEventListener('resize', this.updateContainerWidth);
    }
};
</script>

<style scoped>
.fixed-height-image-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    background-color: #1c1b1b;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
}

.prompt-input {
    width: 100%;
}

.chip-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.chip {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 130px;
}

.fixed-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 199;
    border: none;
    outline: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    padding: 15px;
    border-radius: 10px;
    font-size: 18px;
}

.breathing-text {
    font-size: 30px;
    color: #ffffff;
    text-align: center;
    animation: breathAnimation 1s ease-out infinite;
}

@keyframes breathAnimation {
    0%, 100% {
        text-shadow: none;
    }
    50% {
        text-shadow: 0 0 30px rgba(0, 0, 0, 0.44);
    }
}

.custom-checkbox {
    background-color: black;
    border-radius: 8px;
    padding: 5px;
    display: flex;
    align-items: center;
}

.custom-checkbox-input {
    background-color: #333;
    border-radius: 8px;
}

.custom-checkbox .v-input--selection-controls__input:checked + .v-input--selection-controls__ripple .v-checkbox {
    background-color: #6D49FF !important;
}

.custom-checkbox .v-input--selection-controls__input:checked + .v-input--selection-controls__ripple .v-checkbox__input {
    background-color: white !important;
}

.custom-checkbox .v-label {
    color: white;
}
</style>
