<script>
import _ from 'lodash';
export default {
    name: "SocialLoginDialog",
    data(){
        return {
            dialog: false,
            image: {},
        }
    },
    methods: {
        open(selectedImage) {
            this.image = _.cloneDeep(selectedImage);
            this.dialog = true;
        },
        close() {
            this.dialog = false;
        },
    }
}
</script>

<template>
    <v-dialog v-model="dialog" max-width="800">
        <v-card @click="dialog=false">
            <v-card-text class="overflow-auto" style="max-height: 1200px;">
                <div class="image-container" style="object-fit: contain">
                    <v-img :src="image ? image.url : ''"></v-img>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<style scoped>

</style>
